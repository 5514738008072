import 'isomorphic-fetch'
var moment = require('moment');
import _products from './products'
import _warehouses from './warehousedata'
import _pricelists from './pricelists'

const host = 'https://stock.martinsen.co.uk';

//const host = 'http://localhost:8080';
export const serverURL = host + "/";


export function getSession() {
  const token_expiry_string = localStorage.getItem('auth-token_expiry');
  const token_id = localStorage.getItem('auth-token_id');
  if(token_expiry_string){
    const token_expiry = JSON.parse(token_expiry_string);
    return isTokenValid(token_id, token_expiry);
  }else{
    return false;
  }
}

export function getSessionHeader() {
  const token_id = localStorage.getItem('auth-token_id');
  return ('Basic ' + btoa(token_id));
}


export function isTokenValid(token_id, token_expiry){
  console.log( 'tok: ' + token_id + ':' + token_expiry)
  if( token_id !== null && token_id !== undefined && token_id !== 'undefined'){
    var a = moment(token_expiry);
    if( a.isValid()){
      if( moment().isAfter(a)){
        console.log( 'session  expired');
        return false;
      }else{
        console.log( 'session  current');
        return true;
      }
    }
    console.log( 'moment stored not valid');
    return false;
  }
  console.log( 'session not exsisting');

  return false;

}

export function validate(token) {
    return isTokenValid(token.token_id, token.token_expiry);
}

function status(response) {
  if (response.status >= 200 && response.status < 300) {
    console.log( 'response ok:' + response.status + ' ' +  response.statusText + JSON.stringify(response));

    return Promise.resolve(response)
  } else {
    console.log( 'response error:' + response.status + ' ' +  response.statusText);
    return Promise.reject(new Error(response.statusText))
  }
}

export function createPriceList(priceList) {
  console.log('pricelist create');
  return fetch(serverURL + `pricelists`, {
  	method: 'POST',
  	mode: 'cors',
//  	redirect: 'follow',
  	headers: new Headers({
      'Authorization' : getSessionHeader(),
  		'Content-Type': 'application/json',
      'accept': 'application/json',
  	}),
    body: JSON.stringify(priceList)
  }).then(status);
  }

export function postPriceList(arg, priceListId, arg2, arg3) {
  console.log('pricelist post');
  return fetch(serverURL + `pricelists/${arg}/${priceListId}/${arg2}/${arg3}`, {
  	method: 'POST',
  	mode: 'cors',
//  	redirect: 'follow',
  	headers: new Headers({
      'Authorization' : getSessionHeader(),
  		'Content-Type': 'application/json',
      'accept': 'application/json',
  	}),
  }).then(status);
  }

export function postInventory(arg, body) {
  console.log('inventory post: ' +  arg + ' ' + JSON.stringify(body));
  return fetch(serverURL + `inventory/${arg}`, {
  	method: 'POST',
  	mode: 'cors',
//  	redirect: 'follow',
  	headers: new Headers({
      'Authorization' : getSessionHeader(),
  		'Content-Type': 'application/json',
      'accept': 'application/json',
  	}),
    body: JSON.stringify(body)
  }).then(status);
  }

  export function loginFetch(creds) {
    var base64Str =  btoa(creds.name + ':' + creds.password);
    return fetch(host + '/user', {
    	method: 'GET',
  // 	  mode: 'Basic',
  //  	redirect: 'follow',
    	headers:{
        'Authorization': 'Basic ' + base64Str,
  //      'Content-Type': 'application/json',
  //      'accept': 'application/json',
    	},
    }).then(status);
    }


  export function getCustomersApi(arg, method) {
      var request = new Request(serverURL + `contact/${arg}`, {
    	method: method,
      mode: 'cors',
  //  	redirect: 'follow',
    	headers: new Headers({
        'Authorization' : getSessionHeader(),
    		'Content-Type': 'application/json',
        'accept': 'application/json'
    	})
    });

    if( method === 'POST'){
      console.log('doing contact post')
      return fetch(request)
      .then(status);
    }else{
      console.log('doing contact get')

      return fetch(request)
      .then(status)
      .then(response => response.json());
    }

  }

  export function customersApiWithBody(arg, method, body) {

      var request = new Request(serverURL + `contact/${arg}`, {
    	method: method,
      mode: 'cors',
  //  	redirect: 'follow',
    	headers: new Headers({
        'Authorization' : getSessionHeader(),
    		'Content-Type': 'application/json',
        'accept': 'application/json'
    	}),
      body: JSON.stringify(body)
    });

    console.log('customersApiWithBody: ' + `contact/${arg}`);


      return fetch(request)
      .then(status);

  }

  export function invoiceApiWithBody(method, body) {
      var request = new Request(serverURL + `invoice`, {
    	method: method,
      mode: 'cors',
  //  	redirect: 'follow',
    	headers: new Headers({
        'Authorization' : getSessionHeader(),
    		'Content-Type': 'application/json',
        'accept': 'application/json'
    	}),
      body: JSON.stringify(body)

    });

    return fetch(request)
    .then(status)
    .then(response => response.json());


  }
  export function invoiceApi(arg, method) {
      var request = new Request(serverURL + `invoice/${arg}`, {
    	method: method,
  //    mode: 'cors',
  //  	redirect: 'follow',
    	headers: new Headers({
        'Authorization' : getSessionHeader(),
    		'Content-Type': 'application/json',
        'accept': 'application/json'
    	})
    });
    return fetch(request)
    .then(status)
    .then(response => response.json());
  }

  export function uploadCSV(arg, file) {
    const formData = new FormData();
    formData.append('file',file);
    console.log(file);

      var request = new Request(serverURL + `inventory/csvstatement/${arg}`, {
      method: 'post',
      headers: new Headers({
        'Authorization' : getSessionHeader()
      }),
      body: formData
    });
    return fetch(request)
    .then(status)
    .then(response => response.json());
  }

  export function uploadStockCountCSV(arg1, arg2, file) {
    const formData = new FormData();
    formData.append('file',file);
    console.log(file);

      var request = new Request(serverURL + `inventory/csvstockcount/${arg1}/${arg2}`, {
      method: 'post',
      headers: new Headers({
        'Authorization' : getSessionHeader()
      }),
      body: formData
    });
    return fetch(request)
    .then(status)
    .then(response => response.json());
  }
  
  export function uploadCurrentStockCSV(arg1, arg2, file) {
    const formData = new FormData();
    formData.append('file',file);
    console.log(file);

      var request = new Request(serverURL + `inventory/csvcurrentstock/${arg1}/${arg2}`, {
      method: 'post',
      headers: new Headers({
        'Authorization' : getSessionHeader()
      }),
      body: formData
    });
    return fetch(request)
    .then(status)
    .then(response => response.json());
  }
  
  export function getBillTransactionApi(arg,method) {
    var request = new Request(serverURL + `bill/${arg}`, {
    method: method,
//    mode: 'cors',
//  	redirect: 'follow',
    headers: new Headers({
      'Authorization' : getSessionHeader(),
      'Content-Type': 'application/json'//,
//      'accept': 'application/json'
    })
  });

  if( method === 'POST' || method === 'PUT'){
    return fetch(request)
    .then(status);
  }else{
    return fetch(request)
    .then(status)
    .then(response => response.json());
  }

}

  export function getActivityApi(arg,method) {
      var request = new Request(serverURL + `activity/${arg}`, {
      method: method,
  //    mode: 'cors',
  //  	redirect: 'follow',
      headers: new Headers({
        'Authorization' : getSessionHeader(),
        'Content-Type': 'application/json'//,
  //      'accept': 'application/json'
      })
    });

    if( method === 'POST' || method === 'PUT'){
      return fetch(request)
      .then(status);
    }else{
      return fetch(request)
      .then(status)
      .then(response => response.json());
    }

  }
  export function inventoryApi(arg, method) {
      var request = new Request(serverURL + `inventory/${arg}`, {
      method: method,
  //    mode: 'cors',
  //  	redirect: 'follow',
      headers: new Headers({
        'Authorization' : getSessionHeader(),
        'Content-Type': 'application/json'//,
  //      'accept': 'application/json'
      })
    });

    if( method === 'POST' || method === 'PUT'){
      return fetch(request)
      .then(status);
    }else{
      return fetch(request)
      .then(status)
      .then(response => response.json());
    }

  }
export function getInventoryApi(arg) {
  return inventoryApi(arg, 'GET');
}

export function syncBillApi(arg) {
    var request =null;
    if( arg === ''){
      console.log('syncBillApi no arg');

      request = new Request(serverURL + 'inventory/billupdate', {

      method: 'PUT',
  //	mode: 'no-cors',
  //  	redirect: 'follow',
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
        'Authorization' : getSessionHeader(),
        'Content-Type': 'application/json'
      })
    });
    }else{
      console.log('syncBillApi:' + arg);
      request = new Request(serverURL + `inventory/billupdate/${arg}`, {

      method: 'PUT',
  //	mode: 'no-cors',
  //  	redirect: 'follow',
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
        'Authorization' : getSessionHeader(),
        'Content-Type': 'application/json'
      })
    });
  }
  console.log('fetch request :' + request);

  return fetch(request)
  .then(status)
  .then(response => response.json());
}

export function syncInventoryApi(arg) {
  var request =null;
    if( arg === ''){
        request = new Request(serverURL + 'inventory/update', {

        method: 'PUT',
    //	mode: 'no-cors',
    //  	redirect: 'follow',
        headers: new Headers({
          'Access-Control-Allow-Origin': '*',
          'Authorization' : getSessionHeader(),
          'Content-Type': 'application/json'
        })
      });
    }else{
      request = new Request(serverURL + `inventory/update/${arg}`, {

      method: 'PUT',
  //	mode: 'no-cors',
  //  	redirect: 'follow',
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
        'Authorization' : getSessionHeader(),
        'Content-Type': 'application/json'
        })
    });
    }
    return fetch(request)
    .then(status)
    .then(response => response.json());
  }

export function getPricelistApi(arg) {
  var request = new Request(serverURL + `pricelists/${arg}`, {
  	method: 'GET',
//	mode: 'no-cors',
//  	redirect: 'follow',
  	headers: new Headers({
      'Authorization' : getSessionHeader(),
  		'Content-Type': 'application/json'
  	})
  });

    return fetch(request)
    .then(status)
    .then(response => response.json() );
}

// mockGetWarehouses
export function mockTransferWarehouse(stockTransfer) {
  console.log('transfer data : ' + JSON.stringify(stockTransfer));
  return new Promise( resolve => {
    setTimeout(() => resolve('OK'), 5000)
  })
}

export function mockLogin(creds) {
  if( creds.name !== undefined && creds.name !== 'hakon'){
    return Promise.reject(new Error("mock auth failed"));
  }else{
    console.log('mock login sucessful');
    return  Promise.resolve({token_id: creds.name + ':' + creds.password,token_expiry: moment(Date.now()).add(20,"minutes") });
  };
}

export function mockGetPriceLists() {
  return new Promise( resolve => {
    setTimeout(() => resolve(_pricelists), 100)
  })
}
export function mockGetProducts() {
  return new Promise( resolve => {
    setTimeout(() => resolve(_products), 100)
  })
}


export function mockGetWarehouses() {
  return new Promise( resolve => {
    setTimeout(() => resolve(_warehouses), 100)
  })
}

// api services
