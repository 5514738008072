import { useState, useEffect } from "react";
import { 
    getSessionHeader
    } from './api';
export const useFetch = (url) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(url, {
        method: "GET",
            headers: new Headers({
            'Authorization' : getSessionHeader(),
                'Content-Type': 'application/json',
            'accept': 'application/json'
            })
    })
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [url]);

  return [data];
};

export default useFetch;
