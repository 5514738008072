import React from 'react';
import Logout from './Logout';
import CustomerSelect from './CustomerSelect';
import SelectDropDown from './SelectDropDown';
import { Panel} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

class Settings extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      endDate: new Date()
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.setState({
      endDate: date
    });
  }

  fetchInvoices = () => {
    this.props.syncWarehouses(DateFnsFormat(this.state.endDate,'yyyy-MM-dd'));
  };
  fetchBills = () => {
    this.props.syncBills(DateFnsFormat(this.state.endDate,'yyyy-MM-dd'));
  };

  syncwarehouse = () => {
    this.props.syncRemoteWarehouses();
  };

  render() {
    return (
      <Panel className="dashboard" collapsible defaultExpanded header="Settings">
        <div>
        <Logout onLogoutClick={this.props.requestLogout} />
        <h4>Check database for missing transactions</h4>
        Select from  <DatePicker
                          selected={
                            this.state.endDate
                          }
                          onChange={this.handleChange}
                          dateFormat="dd/MM/yyyy"
                        />
                        <div>

                        <br/>
      <button onClick={() => this.fetchInvoices()} className="btn btn-primary">
        Fetch invoices
      </button>
      </div>
      
      </div>
      <br/>
      <div>

      <button onClick={() => this.fetchBills()} className="btn btn-primary">
        Fetch Bills
      </button>
      <br/>
      <br/>
        </div>
   <div>
        <button onClick={this.syncwarehouse} className="btn btn-primary">
        Update Warehouses from Remote
        </button>
        <br/>
        </div>

      </Panel>
    )
  }
}

export default Settings;
