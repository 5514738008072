import React from "react";

import { useFetch } from '../services/useFetch';
import { Panel} from 'react-bootstrap';
import {PackingGraphs} from "./PackingGraphs";
import {PackingGraphs2} from "./PackingGraphs";
import { serverURL } from "../services/api";
import { getGraphSeries} from "../services/util"; 

export default function PackingCostsVsUnitsShippedMoM(id) {
  const [pckunits1] = useFetch(serverURL + "bill/packvsunits/mom/2022-01-01/2022-12-31");
  const [pckunits2] = useFetch(serverURL + "bill/packvsunits/mom/2023-01-01/2023-12-31");
  const [pckunits3] = useFetch(serverURL + "bill/packvsunits/mom/2024-01-01/2024-12-31");
  const [pckunits4] = useFetch(serverURL + "bill/packvsunits/mom/FDC/2023-01-01/2023-12-31");
  const [pckunits5] = useFetch(serverURL + "bill/packvsunits/mom/FDC/2024-01-01/2024-12-31");

  if( !pckunits1  || !pckunits2 || !pckunits3|| !pckunits4 || !pckunits5){
    return (
      <Panel className="foulger" collapsible defaultExpanded header="Packing Cost per Unit Shipped">
       <div className="loader">Loading...</div>
      </Panel>
    )
  }
  else{

    const costSeries3 = getGraphSeries(pckunits1.monthList); 
    const costSeries4 = getGraphSeries(pckunits2.monthList); 
    const costSeries5 = getGraphSeries(pckunits3.monthList); 
    const costSeries6 = getGraphSeries(pckunits4.monthList); 
    const costSeries7 = getGraphSeries(pckunits5.monthList); 


  return (
    <Panel className="foulger" collapsible defaultExpandedheader="Packing Cost per Unit Shipped">
        <div><b>FDC</b></div>
    <div>{PackingGraphs2('FDC' , costSeries6, costSeries7)}</div>
    <div>FDC Packing Cost per case 2023: {pckunits4.totalRatio} </div>
    <div>FDC Packing Cost per case 2024: {pckunits5.totalRatio} </div>
    <div><br/><br/><b>Foulger</b></div>

    <div>{PackingGraphs(costSeries3, costSeries4, costSeries5)}</div>
    <div>Foulger Packing Cost per case 2022: {pckunits1.totalRatio} </div>
    <div>Foulger Packing Cost per case 2023: {pckunits2.totalRatio} </div>
    <div>Foulger Packing Cost per case 2024: {pckunits3.totalRatio} </div>
  


    </Panel>

  );
}
}