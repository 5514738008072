import React from 'react';
import WareHouse from './WareHouse';
import InvoiceSync from './InvoiceSync';
import ErrorBoundary from './ErrorBoundary';
import { Panel} from 'react-bootstrap';

class WareHouseGrid extends React.Component {
  constructor(props) {
    super(props)
    console.log('wgrid: ' + props)
  }


  componentDidMount() {
    if(  ! this.props.stockcount.isSynching && ! this.props.warehouses.isFetching && this.props.warehouses.units.length === 0 ){
      this.props.fetchWarehouses();
    }
  }

  render() {
    if( !this.props.warehouses.isFetching && ! this.props.stockcount.isSynching ){
      return (
        <Panel className="dashboard" collapsible defaultExpanded header="Warehouses">
           <InvoiceSync lastSynchronized={this.props.warehouses.lastSynchronized}  isSynching={this.props.warehouses.isSynching } syncWarehouses={this.props.syncWarehouses}/>
           <ErrorBoundary>
          {this.props.warehouses.units.map((warehousedata, i) => <WareHouse  {...this.props} single={false} key={i} i={i} warehouseId={warehousedata.wareHouseName} />)}
        </ErrorBoundary>
        </Panel>
      )
    }else{
      return ( <div className="loader">Loading...</div>)
    }

  }
}

export default WareHouseGrid;
