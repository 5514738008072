import {
  SYNC_BILLS ,
  SYNC_BILLS_COMPLETE,
  FAILED_SYNC_BILLS 
} from '../actions/invoiceActions';

function bills(state = [], action) {
  switch(action.type){
    case SYNC_BILLS:
      console.log('syncing bills')
      return { ...state, isFetching: true }
    case SYNC_BILLS_COMPLETE:
      console.log('fetched bills')

      return { ...state,
        isFetching: false,
        lists: action.bills,
        error: '',
        lastUpdated: action.receivedAt
      }
    case FAILED_SYNC_BILLS:
              console.log('failed  bill action ' + action.type)

        return { ...state,
          isFetching: false,
          error: action.error,
          lastUpdated: action.receivedAt
        }
    default:
      return state
    }
}

export default bills;
