import React from 'react';
import Dropzone from 'react-dropzone'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsFormat from 'date-fns/format';
import { uploadCurrentStockCSV
} from '../services/api'

class CurrentStockUploader extends React.Component {
 

  constructor (props) {
    super(props)
    this.state = {
      selectDate: '',
      files: [],
      name: '',
      warehouseId: '',
    };

    this.handleToChange = this.handleToChange.bind(this);
  }

  handleToChange(date) {
    this.setState({
      selectDate: date
    });
  }

  componentDidMount() {
    const { warehouseId } = this.props;
    this.setState({
      warehouseId: warehouseId
    });
  }

  onDrop = (acceptedFiles) => {
    this.setState({
      files: acceptedFiles
    });
    this.setState({
      name: acceptedFiles[0].name
    });
    let date = uploadCurrentStockCSV(this.state.warehouseId,DateFnsFormat(this.state.selectDate,'yyyy-MM-dd'), acceptedFiles[0]);
    date.then(function(value) {
        console.log('promise log: ' + value);
  // expected output: "foo"
  });
    console.log('last statement date' + JSON.stringify(date));
  };

  onOpenClick = () => {
    this.dropzone.open();
  };

  render() {
      return (
          <div>
              <DatePicker
                              selected={this.state.selectDate}
                              onChange={this.handleToChange}
                              dateFormat="dd-MM-yyyy"
                              />
              <Dropzone ref={(node) => { this.dropzone = node; }} multiple={false} accept={'text/csv'} onDrop={this.onDrop}>
                  <div>Drag StockCount csv statement here, or click to select files to upload.</div>
              </Dropzone>
              {this.state.files.length > 0 ? <div>
              <div>{this.state.files.map((file) => <p key={file.name}>uploaded {file.name} </p> )}</div>
              </div> : null}
          </div>
      );
  }
}

export default CurrentStockUploader;
