import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

class CustomerSelect extends React.Component {
  static displayName = 'CustomerField';

  static propTypes = {
      label: PropTypes.string,
      searchable: PropTypes.bool,
  };

  static defaultProps = {
      label: 'Customers:',
      searchable: true,
  };

  state = {
      disabled: false,
      searchable: this.props.searchable,
      selectValue: '',
      clearable: true,
showModal: false
  };

  navigateToPage = () => {
    window.location = '/newinvoice/new/' + this.state.selectValue;
  };

  componentDidMount() {
    this.props.fetchCustomers();
  }

  disable = () => {
    if( this.state.selectValue === ''){
      return true;
    }else{
      return false;
    }
  };

  updateValue = (newValue) => {
      console.log('State changed to ' + newValue);
      this.setState({
          selectValue: newValue
      });
  };

  focusStateSelect = () => {
      this.refs.customerSelect.focus();
  };

  renderValue = (i, name) => {
//    return ( '{value:' + i + ', label: ' + name+ ' }' );
      return ( { value:i, label:name } );
  };

  close = () => {
    this.setState({ showModal: false });
    this.setState({selectValue: ''})
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render() {
    var options = this.props.customers.lists.map((customer, i) => this.renderValue(customer.contactId,customer.name));
    return (
      <div>
        <Button
          bsSize="small"
          onClick={this.open}
        >Create Invoice</Button>

          <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Header>
              <Modal.Title>Select Customer</Modal.Title>
            </Modal.Header>
            <Form  onSubmit={this.handleOnSubmit}>
            <Modal.Body>
            <Select className="cust-select" ref="customerSelect" autofocus options={options} simpleValue
              clearable={this.state.clearable} name="selected-state" disabled={this.state.disabled} value={this.state.selectValue}
              onChange={this.updateValue} searchable={this.state.searchable} />
            </Modal.Body>
            <Modal.Footer>
              <Button bsSize="small" bsStyle="primary"
              onClick={this.navigateToPage} disabled={this.disable()}
              >Apply</Button>
              <Button  onClick={this.close}>Cancel</Button>
            </Modal.Footer>
            </Form>
          </Modal>
          </div>
        )
  }
}

export default CustomerSelect;
