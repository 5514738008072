import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import warehouses from './warehouses';
import products from './products';
import activity from './activity';
import pricelists from './pricelists';
import invoices from './invoices';
import bills from './bills'; 
import costs from './costs'; 
import auth from './auth';
import customers from './customers';
import editablePricelist from './editablePricelist';
import editableInvoice from './editableInvoice';
import stockcount from './stockcount';
import transactions from './transactions';
import statements from './statements';
import customersalesdata from './customersalesdata';
import reconcilliation from './reconcilliation';
import warehousedashboard from './warehousedashboard'
import billtransaction from './billtransaction'


const rootReducer = combineReducers({warehouses, products, auth, pricelists,editablePricelist, customers,invoices,bills, editableInvoice,stockcount,customersalesdata,transactions,statements,costs, reconcilliation,warehousedashboard, activity, billtransaction,  routing: routerReducer });

export default rootReducer;
