import React from 'react';
import { useFetch } from '../services/useFetch';
import { serverURL } from "../services/api";
import { FormGroup } from 'react-bootstrap';
import { FormControl} from 'react-bootstrap';
import { ControlLabel} from 'react-bootstrap';
import { Form} from 'react-bootstrap';
var moment = require('moment');


function handleOnSubmit (fetchStockCount , warehouse , e){
  console.log("fetch stockcount for: " + e.target.value); 
  fetchStockCount(warehouse, e.target.value); 

}

function renderOption(data){
    return ( <option key={data.stockTakeDate} value={data.stockTakeDate} >{data.stockTakeDate}</option>) ;
};

export default function StockCountIds(props) {

  const [stock] = useFetch(serverURL + "inventory/stockcounts/" + props.warehouse); 

  console.log(JSON.stringify(stock));
  if (!stock) {
    return (
        <div className="loader">Loading...</div>
    )
  }
  else {
    return (
      <Form >
      <FormGroup controlId="stockTakeDate" onChange={(e) => handleOnSubmit(props.fetchStockCount,props.warehouse , e)}>
      <ControlLabel>Select New Stockcount Date</ControlLabel>
    <FormControl componentClass="select" placeholder="select" >
       <option key="select" value="select">{'select'}</option>
       {stock.map(renderOption)}
    </FormControl>
    </FormGroup>
    </Form>
    )
  }
}

