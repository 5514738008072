import {
  FETCHING_PRODUCTS,
  FETCHED_PRODUCTS,
  FAILED_FETCHED_PRODUCTS
} from '../actions/actionCreators';

function products(state = [], action) {
  switch(action.type){
    case FETCHING_PRODUCTS:
      console.log('fetching product')
      return { ...state, isFetching: true }
    case FETCHED_PRODUCTS:
      console.log('fetched product')

      return { ...state,
        isFetching: false,
        items: action.products,
        error: '',
        lastUpdated: action.receivedAt
      }
      case FAILED_FETCHED_PRODUCTS:
        console.log('failed fetched product')

        return { ...state,
          isFetching: false,
          error: action.error,
          lastUpdated: action.receivedAt
        }
    default:
      return state
    }
}

export default products;
