import {
  FETCHING_ACTVITY,
  FAILED_FETCHED_ACTVITY,
  FETCHED_ACTVITY
} from '../actions/stockcountActions';

function activity(state = [], action) {
  switch(action.type){
    case   FETCHING_ACTVITY:
      console.log(' FETCHING_ACTVITY')
      return { ...state,
        isFetching: true,
        toDate: action.toDate,
        fromDate: action.fromDate
      }
    case FETCHED_ACTVITY:
      console.log('FETCHED_ACTVITY')
      return { ...state,
        isFetching: false,
        lists: action.activity,
        error: '',
        lastUpdated: action.receivedAt
      }
    case FAILED_FETCHED_ACTVITY:
        console.log('FAILED_FETCHED_ACTVITY')
        return { ...state,
          isFetching: false,
          error: action.error,
          lastUpdated: action.receivedAt,
          fromDate: 'na',
          toDate: 'na'
        }
    default:
      return state
    }
}


export default activity;
