import React from 'react';
import { Panel} from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';

import StockCountDetail from './StockCountDetail';
import StockCountIds from './StockCountIds';
import StockCountAnalytics from './StockCountAnalytics'; 
import DatabaseStockCountSummary from './DatabaseStockCountSummary';
var moment = require('moment');

class DatabaseStockCount extends React.Component {

 

  constructor (props) {
    super(props);
    this.state = { warehouseId: props.match.params.warehouseId };
    console.log('stockcount: ' + JSON.stringify(this.props))
  }


  componentDidMount() {
    if( ! this.props.stockcount.isSynching  ){
      // this.props.getStockById(warehouseId, this.props.warehouses);
       // this.props.getStockById(warehouseId, this.props.warehouses);
     this.props.fetchStockCount(this.state.warehouseId, null); 
    }
    console.log('stockcount: ' + JSON.stringify(this.props))

  }



  render() {

     if( this.props.stockcount.isSynching === true){
       return(
         <Panel className="warehouse" header={'Stock Count for ' + this.state.warehouseId}>
        <div className="loader">Loading...</div>
         </Panel>
    )
     }else{

    return (
      <Panel className="warehouse" header={'Stock Count for ' + this.state.warehouseId + ' on ' + this.props.stockcount.stockTakeDate}>
        <DatabaseStockCountSummary stockcount={this.props.stockcount} warehouse={this.state.warehouseId} stockTakeDate={this.props.stockcount.stockTakeDate} />
       <StockCountIds fetchStockCount={this.props.fetchStockCount} warehouse={this.state.warehouseId} />
       
       <StockCountAnalytics warehouse={this.state.warehouseId} stockTakeDate={this.props.stockcount.stockTakeDate} />

        <StockCountDetail warehouse={this.state.warehouseId} stockTakeDate={this.props.stockcount.stockTakeDate} />
      </Panel>
    )
  }
  }
}

export default DatabaseStockCount;
