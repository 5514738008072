import React from 'react';
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap';
import { Button,DropdownButton, MenuItem} from 'react-bootstrap';
import { Panel} from 'react-bootstrap';
import TransferWindow from './TransferWindow';
import StockAdjustmentWindow from './StockAdjustmentWindow';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

class WareHouse extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      endDate: null
    };
    this.handleChange = this.handleChange.bind(this);
}

componentDidMount() {


  if(  ! this.props.stockcount.isSynching && ! this.props.warehouses.isFetching && this.props.warehouses.units.length === 0 ){
    this.props.fetchWarehouses();
  }
//  this.props.fetchWarehouseTransactionsFromDate(warehouseId, now.subtract(3, 'months').format("YYYY-MM-DD"), 'all');
//  this.props.fetchWarehouseStatementsFromDate(warehouseId, now2.subtract(3, 'months').format("YYYY-MM-DD"));
//  this.props.fetchReconcilliationItem(warehouseId, 1);

}



  handleChange(date) {
    this.setState({
      endDate: date
    });
  }

  doit = () => {
    this.props.fetchWarehousesToDate(DateFnsFormat(this.state.endDate,'yyyy-MM-dd'));
  };

  handleStockcount = (warehouse) => {
    this.props.getStock(warehouse.wareHouseName, warehouse.productsSummary);
  };

  renderNoProduct = (units) => {
    if( units.length === 0){
      return (
          <tr key="nop"><td colSpan="3">No products in this warehouse</td></tr>
      )
    }else{
      return;
    }

  };

  render() {
    const {warehouseId, panelClass} = this.props;
    let i = this.props.i;
    if( this.props.single){
      i = this.props.warehouses.units.findIndex((wh) => wh.wareHouseName === warehouseId);
    }
    const warehouse = this.props.warehouses.units[i];
    if( this.props.warehouses.isFetching || warehouse === undefined ){
      const  warehouseId  = this.props.match.params.warehouseId;


      return(
      <Panel className={panelClass} header={warehouseId + ' Stock'}>
         <div className="loader">Loading...</div>
     </Panel>
    )

    }else{
      let sortedProducts = warehouse.productsSummary.sort((p1, p2) => (p1.prodCode > p2.prodCode) ? 1 : (p1.prodCode < p2.prodCode) ? -1 : 0);


    return (


      <Panel className={panelClass} header={<Link to={`/view/${warehouse.wareHouseName}`}>{warehouse.wareHouseName} Stock</Link>}>
          <Table    >
            <thead>
              <tr>
          <th className="fixed-small">Product</th>
          <th>Description</th>
          <th className="fixed-small">Batch</th>
          <th className="fixed-small">Quantity</th>
          <th className="fixed-small">Report Date</th>
        </tr>
      </thead>
      <tbody>
          {    sortedProducts.map(function(product){

            if( this.props.single){

            return (
                  <tr key={product.prodCode+product.batch}>
                    <td className="fixed-small">{product.prodCode}</td>
                    <td>{product.descr}</td>
                    <td className="fixed-small">{product.batch}</td>
                    <td className="fixed-small">{product.quantity}</td>
                    <td className="fixed-small">{DateFnsFormat(parseISO(product.submitDate), 'dd-MM-yyyy')}</td>
                    <td className="actions">
                    <DropdownButton bsSize="xsmall" title="Actions" id="dropdown-size-small">
                      <MenuItem eventKey="1"><TransferWindow {...this.props} i={i} warehouseId={warehouse.wareHouseName} transferProduct={product.prodCode}  transferQuantity={0} isReconcile='false' reference='' batch={product.batch}/></MenuItem>
                      <MenuItem eventKey="2"><StockAdjustmentWindow {...this.props} i={i} warehouseId={warehouse.wareHouseName} adjustmentProduct={product.prodCode} batch={product.batch} /></MenuItem>
                    </DropdownButton>
                    </td>
                    </tr>
            );
          }else{
            return ( 
              <tr key={product.prodCode+product.batch}>
              <td className="fixed-small">{product.prodCode}</td>
                  <td>{product.descr}</td>
                  <td className="fixed-small">{product.batch}</td>
                  <td className="fixed-small">{product.quantity}</td>
                  <td className="fixed-small">{DateFnsFormat(parseISO(product.submitDate), 'dd-MM-yyyy')}</td>
                  </tr>);
          }}, this)
        }
        { this.renderNoProduct(warehouse.productsSummary)}
        </tbody>
      </Table>
        <div className={this.props.single ? '' : 'hidden'}>
            <Link to={`/editwh/${warehouse.wareHouseName}`}>
            <Button bsSize="small" bsStyle="primary"
            onClick={this.handleStockcount.bind(null, warehouse)}
            >StockCount</Button></Link>
            <p>Select Stock for a specific date:</p>
            <DatePicker
                    selected={this.state.endDate}
                    onChange={this.handleChange}
                    dateFormat='dd-MM-yyyy'
                  />
          <Button bsSize="small" className="btn btn-primary" onClick={() => this.doit()} >
            Do It
          </Button>
        </div>
        </Panel>
    )
  }
  }
};

export default WareHouse;
