import React from 'react';
import { Table } from 'react-bootstrap';
import { Panel } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
var moment = require('moment');

function renderNoProduct(units) {
  if (units.length === 0) {
    return (
      <tr key="nop"><td colSpan="3">No products in this warehouse</td></tr>
    )
  } else {
    return ''
  }
};

function renderProduct(product, i) {
  return (<tr key={i}>
    <td className="fixed-small">{product.prodCode}</td>
    <td>{product.descr}</td>
    <td className="fixed-small">{product.quantity - product.allocated}</td>
    <td className="fixed-small">{product.allocated}</td>
    <td className="fixed-small">{product.quantity}</td>
    </tr>
  )
};

export default function DatabaseStockCountSummary(props) {
  let stockTakeDate = props.stockTakeDate;
  const stockCount = props.stockcount;
  if (stockTakeDate == null) {
    if (stockCount.dbStockItems.length > 0) {
      stockTakeDate = stockCount.dbStockItems[0].submitDate
    }
  }
  return (
      <Table striped hover>
        <thead>
          <tr>
            <th className="fixed-small">Product</th>
            <th>Description</th>
            <th className="fixed-small">Non allocated</th>
            <th className="fixed-small">Allocated</th>
            <th className="fixed-small">Total</th>
          </tr>
        </thead>
        <tbody>
          {
            stockCount.dbStockItems.map(renderProduct)
          }
        </tbody>
      </Table>
  )

}