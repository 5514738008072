import React from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  HorizontalBarSeries, 
  DiscreteColorLegend
} from 'react-vis';


export  function HorisontalGraphs(series){

  console.log(series);
  const legends = [
    {title: '  2019'},
      {title: '  2020'},
      {title: '  2021'},
      {title: '  2022'}
  ]
  
  const BarSeries = HorizontalBarSeries;
  return (
    <div>
      <XYPlot xType="ordinal" width={800} height={1200} >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        <BarSeries  data={series}/>
    </XYPlot>
    </div>
  );

}


export  function PackingGraphs4(series, series2, series3 , series4, series5){

  console.log(series);
  console.log(series2);
  const legends = [
      {title: '  2020'},
      {title: '  2021'},
      {title: '  2022'},
      {title: '  2023'},
      {title: '  2024'}

  ]
  
  const BarSeries = VerticalBarSeries;
  return (
    <div>
      <DiscreteColorLegend  orientation="horizontal" width={400} items={legends}/>
      <XYPlot  xType="ordinal" width={900} height={400} xDistance={35}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis left={13} />
        <BarSeries className="vertical-bar-series-example" data={series} color="#12939A"/>
        <BarSeries  data={series2} color="#79C7E3"/>
        <BarSeries  data={series3} color="blue"/>
        <BarSeries  data={series4} color="orange"/>
        <BarSeries  data={series5} color="red"/>

</XYPlot>
    </div>
  );

}

export function PackingGraphs(series, series2, series3){

    const legends = [
        {title: '  2022'},
        {title: '  2023'},
        {title: '  2024'}
    ]
    
    const BarSeries = VerticalBarSeries;
    return (
      <div>
        <DiscreteColorLegend  orientation="horizontal" width={200} items={legends}/>
        <XYPlot  xType="ordinal" width={900} height={400} xDistance={35}>
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis />
          <YAxis />
          <BarSeries className="vertical-bar-series-example" data={series} color="#12939A"/>
          <BarSeries  data={series2} color="#79C7E3"/>
          <BarSeries  data={series3}  color="blue"/>

  </XYPlot>
      </div>
    );
  
}

export function PackingGraphs2(name, series, series2){

  const legends = [
      {title: '  2023'},
      {title: '  2024'}
  ]
  
  const BarSeries = VerticalBarSeries;
  return (
    <div>
      <DiscreteColorLegend  orientation="horizontal" width={200} items={legends}/>
      <XYPlot  xType="ordinal" width={900} height={400} xDistance={35}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        <BarSeries className="vertical-bar-series-example" data={series} color="#12939A"/>
        <BarSeries  data={series2} color="#79C7E3"/>
</XYPlot>
    </div>
  );

}

export function PackingGraphsFDC(series){

  const legends = [
      {title: '  2023'}
  ]
  
  const BarSeries = VerticalBarSeries;
  return (
    <div>
      <DiscreteColorLegend  orientation="horizontal" width={200} items={legends}/>
      <XYPlot  xType="ordinal" width={900} height={400} xDistance={35}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        <BarSeries className="vertical-bar-series-example" data={series} color="#12939A"/>

</XYPlot>
    </div>
  );

}