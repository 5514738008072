import {
  GET_STOCK,
  ADD_STOCK,
  FETCH_STOCKCOUNT,
  FETCH_STOCKCOUNT_OK,
  FETCH_STOCKCOUNT_FAILED,
  SUBMIT_STOCKCOUNT_OK,
  SUBMIT_STOCKCOUNT,
  SUBMIT_STOCKCOUNT_FAILED,
  STOCK_QUANTITY_UPDATE,
  ADJUST_WAREHOUSE,
  ADJUST_WAREHOUSE_FAILED,
  ADJUST_WAREHOUSE_OK
} from '../actions/stockcountActions';


// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
function stockcount(state = {}, action) {
  switch (action.type) {
    case GET_STOCK:
      console.log('getstock request warehouse' + action.warehouse);
      console.log(action.stockItems);

      return { ...state,
        isSynching: false,
        warehouse: action.warehouse,
        stockItems: action.stockItems
      }
    case FETCH_STOCKCOUNT_OK:
        console.log(action.stock);
          return { ...state,
          isSynching: false,
          warehouse: action.stock.wareHouseName,
          dbStockItems: action.stock.productsSummary,
          stockTakeDate: action.stock.stockTakeDate
        }
    case SUBMIT_STOCKCOUNT_OK:
    case ADJUST_WAREHOUSE_OK:
      console.log('WAREHOUSE_UPDATE_OK');
      return { ...state,
        isSynching: false,
        warehouse: '',
        stockItems: []
      }
      case SUBMIT_STOCKCOUNT_FAILED:
      case ADJUST_WAREHOUSE_FAILED:
      case FETCH_STOCKCOUNT_FAILED:

        console.log('WAREHOUSE_UPDATE_FAILED');
        return {...state,
          isSynching: false
          }

      case SUBMIT_STOCKCOUNT:
      case ADJUST_WAREHOUSE:
      case FETCH_STOCKCOUNT: 
        console.log('WAREHOUSE_UPDATE');
        return {...state,
          isSynching: true
        }
      case STOCK_QUANTITY_UPDATE:
      case ADD_STOCK:
            return {
              ...state,
                isSynching: state.isSynching,
                warehouse: state.warehouse,
                stockItems: updateStock(state.stockItems, action)
              }
    default:
      return state
  }
}

function updateStock(state = {}, action) {
  switch (action.type) {

    case ADD_STOCK:
      return [...state,{
        prodCode: action.prodCode,
        quantity: action.quantity,
        warehouse: action.warehouse,
        descr: action.descr,
        batch: action.batch
      }];
    case STOCK_QUANTITY_UPDATE:
    return [
      ...state.slice(0,action.i),
      state[action.i] = {
        prodCode: state[action.i].prodCode,
        quantity: action.quantity,
        warehouse: state[action.i].warehouse,
        descr: state[action.i].descr,
        batch: state[action.i].batch
      },
      ...state.slice(action.i + 1)
    ]
    default:
      return state
  }

}



export default stockcount;
