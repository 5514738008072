import React, { Component} from 'react'
import PropTypes from 'prop-types';
import { Well , Button} from 'react-bootstrap';

var moment = require('moment');

class InvoiceSync extends React.Component {
  constructor (props) {
    super(props)
    this.sync = this.sync.bind(this);
  }
  
  sync(){
      this.props.syncWarehouses('');
  };

  render() {
    const { lastSynchronized, isSynching } = this.props;

    var lastUpdated = 'Never Go to Settings Page to initiate initial Sync'
    if( lastSynchronized !== '' && lastSynchronized !== null){
      lastUpdated = moment(lastSynchronized, moment.ISO_8601).format('DD-MM-YYYY');
    }
    if( isSynching ){
      return(
        <Well><b>Last Synchronized:</b> updating .. <div className="loader">Loading...</div></Well>
    )

    }else{
      return (
        <Well><b>Last Synchronized:</b> {lastUpdated} <Button
            bsSize="xsmall"
            bsStyle="primary"
            onClick={this.sync}>Update
          </Button></Well>

      )
    }
  }
}

InvoiceSync.propTypes = {
  lastSynchronized: PropTypes.string
}


export default InvoiceSync;
