import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavItem } from 'react-bootstrap';

class NavBar extends React.Component {
  state = {
    activeHref: ''
  };

  componentDidMount() {
    this.setState.activeHref
  }

  handleSelect = (key) => {
      location.href = key;
  };

  render() {
    return (
      <Nav bsStyle="tabs" activeHref={location.pathname} onSelect={this.handleSelect}>
        <NavItem eventKey={"/"} href="/">Dashboard</NavItem>
        <NavItem eventKey={"/customerinsights"} href="/customerinsights">Customer Insights</NavItem>
        <NavItem eventKey={"/billtransaction"} href="/billtransaction">Cost Insights</NavItem>
        <NavItem eventKey={"/view/FDC"} href="/view/FDC">FDC</NavItem>
        <NavItem eventKey={"/view/Foulger"} href="/view/Foulger">Foulger</NavItem>
        <NavItem eventKey={"/view/Yearsleys"} href="/view/Yearsleys">Yearsley</NavItem>
        <NavItem eventKey={"/view/Garage"} href="/view/Garage">Garage</NavItem>
        <NavItem eventKey={"/view/Maynard"} href="/view/Manard">Maynard</NavItem>
        <NavItem eventKey={"/view/Lynda"} href="/view/Lynda">Lynda</NavItem>
        <NavItem eventKey={"/activity"} href="/activity">Activity</NavItem>
        <NavItem eventKey={"/settings"} href="/settings">Settings</NavItem>
      </Nav>
    );
  }
}

export default NavBar;
