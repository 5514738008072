export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS'
export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS'

export const ADD_PRICELIST_TO_CUSTOMER = 'ADD_PRICELIST_TO_CUSTOMER'
export const PRICELIST_TO_CUSTOMER_SET = 'PRICELIST_TO_CUSTOMER_SET'
export const FETCH_CUSTOMER_SALES = 'FETCH_CUSTOMER_SALES'
export const RECEIVE_CUSTOMER_SALES = 'RECEIVE_CUSTOMER_SALES'
export const FAILED_RECEIVE_CUSTOMER_SALES = 'FAILED_RECEIVE_CUSTOMER_SALES'

export const FAILED_CUSTOMER_ACTION = 'FAILED_CUSTOMER_ACTION'

export function fetchCustomers() {
  console.log('fetching customer action');
  // if id = all we fetach all customers
  return {
    type: FETCH_CUSTOMERS,
  }
}

export function receiveCustomers(customers) {
  console.log('receiveCustomers');
  // if id = all we fetach all customers
  return {
    type: RECEIVE_CUSTOMERS,
    customers: customers,
    receivedAt: Date.now()
  }
}

export function fetchSalesData(customer, from) {
  console.log('receiveSalesData');
  // if id = all we fetach all customers
  return {
    type: FETCH_CUSTOMER_SALES,
    customer: customer,
    from: from
  }
}
export function receiveSalesData(salesData) {
  console.log('receiveSalesData');
  // if id = all we fetach all customers
  return {
    type: RECEIVE_CUSTOMER_SALES,
    salesData: salesData,
    receivedAt: Date.now()
  }
}

export function failedCustomerAction( error) {
  console.log('failedCustomerAction');
  return {
    type: FAILED_CUSTOMER_ACTION,
    error: error,
    receivedAt: Date.now()
  }
}
export function failedCustomerSalesReceive( error) {
  console.log('failedCustomerSalesReceive');
  return {
    type: FAILED_RECEIVE_CUSTOMER_SALES,
    error: error,
    receivedAt: Date.now()
  }
}


export function addPricelistToCustomer(customerName, pricelistName) {
  console.log('addPricelistToCustomer action: ' + customerName + ' ' + pricelistName);
  // if id = all we fetach all customers
  return {
    type: ADD_PRICELIST_TO_CUSTOMER,
    customerName: customerName,
    pricelistName: pricelistName
  }
}

export function pricelistToCustomerSetOk() {
  console.log('pricelistToCustomerSetOk action ');
  // if id = all we fetach all customers
  return {
    type: PRICELIST_TO_CUSTOMER_SET
  }
}
