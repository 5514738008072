import React, { useState } from 'react';
import { Panel} from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import addDays from 'date-fns/addDays';
import { useFetch } from '../services/useFetch';
import { serverURL } from "../services/api";



function InvoiceSummaryEntry ( props ) {
  console.log( 't: ' + JSON.stringify(props.transaction)); 
  return(
  <tr key={props.i}>
        <td className="tran-head-med">{props.transaction.bolContact.name}</td>
        <td className="inv-head-med">{props.transaction.invoiceNumber}</td>
        <td className="inv-head-med">{DateFnsFormat(new Date(props.transaction.dateTimeOfSubmission), 'dd-MM-yyyy')}</td>
        <td className="tran-head-med">{props.transaction.totalNetSum}</td>
    </tr>
  )
}

function  createDate (date) {
  console.log('date: ' + date  )
   if( date == 'na'){
     return null;
  }else{
    return new Date(parseISO(date));
  }
}

export default function InvoiceSummaryHistory(props) {


    const [toDate, setToDate] = useState(addDays(new Date(), 14));
    const [fromDate, setFromDate] = useState(addDays(new Date(), -14));

    const [history] = useFetch( serverURL + "bill/invoicesummary/warehouse/" + props.warehouse + "/" + DateFnsFormat(fromDate,'yyyy-MM-dd') + "/" + DateFnsFormat(toDate,'yyyy-MM-dd'));
    

   

    if(!history  ){
      return (
        <Panel className="dashboard" collapsible defaultExpanded header={ props.warehouse + ' Invoices'}>
         <div className="loader">Loading...</div>
        </Panel>
      )
    }
    else{
      let sortedHistory = history.sort((p1, p2) => (p1.dateTimeOfSubmission > p2.dateTimeOfSubmission) ? -1 : (p1.dateTimeOfSubmission < p2.dateTimeOfSubmission) ? 1 : 0);

      return(
        <Panel className="dashboard" collapsible defaultExpanded header={ props.warehouse + ' Invoices'}>
        <div className="inv-top inv-date"><p>Transaction range: </p></div>
        <div className="inv-top inv-date"><p>from:</p>
        <DatePicker
                          selected={fromDate}
                          onChange={date => setFromDate(date)}
                          dateFormat="dd-MM-yyyy"
                        />
        </div>
        <div className="inv-top inv-due"><p>to:</p>
        <DatePicker
                          selected={toDate}
                          onChange={date => setToDate(date)}
                          dateFormat="dd-MM-yyyy"
                        />
        </div>
        <Table className="inv-table" striped bordered condensed hover>
          <thead>
            <tr>
            <th className="tran-head-med">Customer</th>
            <th className="inv-head-med">Inv Number</th>
            <th className="inv-head-med">Date</th>
            <th className="tran-head-med">Net Value</th>

            </tr>
          </thead>
          <tbody>
          {
            sortedHistory.map((transaction, i) => <InvoiceSummaryEntry
                                                        key={i} i={i} transaction={transaction} />)
          }
          </tbody>
        </Table>
        </Panel>
      )
    }
}

