import React from 'react';
import { Panel} from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import addDays from 'date-fns/addDays';


class ActivityEntry extends React.Component {


  render() {
  const {transaction, i} = this.props;

  return(
  <tr key={i}>
        <td className="tran-head-med">{DateFnsFormat(parseISO(transaction.activityTime),'dd MMM, H:m:s')}</td>
        <td className="tran-head-med">{transaction.userName}</td>
        <td className="tran-head-med">{transaction.activityMessage}</td>
    </tr>
  )
  }
}

class ActivityHistory extends React.Component {
    constructor (props) {
      super(props)
      this.handleToChange = this.handleToChange.bind(this);
      this.handleFromChange = this.handleFromChange.bind(this);

    }
    componentDidMount() {
      this.props.fetchActivityFromDate(DateFnsFormat(addDays(new Date(), -3),'yyyy-MM-dd'));

    }

    createDate = (date) =>{
      console.log('date: ' + date  )
       if( date == 'na'){
         return null;
      }else{
        return new Date(parseISO(date));
      }
    }

    handleFromChange(date) {
      this.props.fetchActivityBetweenDates( DateFnsFormat(date, 'yyyy-MM-dd'), this.props.activity.toDate);

    }

    handleToChange(date) {
      this.props.fetchActivityBetweenDates(this.props.activity.fromDate, DateFnsFormat(date, 'yyyy-MM-dd'))
    }

  

  render() {

    if( this.props.activity.isFetching){
      return (
        <Panel className="dashboard" collapsible defaultExpanded header="Activity Transactions">
         <div className="loader">Loading...</div>
        </Panel>
      )
    }
    else{
      return(
        <Panel className="dashboard" collapsible defaultExpanded header="Activity Transactions">
        <div className="inv-top inv-date"><p>Transaction range: </p></div>
        <div className="inv-top inv-date"><p>from:</p>
        <DatePicker
                          selected={this.createDate(this.props.activity.fromDate)}
                          onChange={this.handleFromChange}
                          dateFormat="dd-MM-yyyy"
                        />
        </div>
        <div className="inv-top inv-due"><p>to:</p>
        <DatePicker
                          selected={this.createDate(this.props.activity.toDate)}
                          onChange={this.handleToChange}
                          dateFormat="dd-MM-yyyy"
                        />
        </div>
        <Table className="inv-table" striped bordered condensed hover>
          <thead>
            <tr>
            <th className="inv-head-med">Date</th>
            <th className="tran-head-med">User</th>
            <th className="tran-head-med">Message</th>
            </tr>
          </thead>
          <tbody>
          {
            this.props.activity.lists.map((transaction, i) => <ActivityEntry
                                                        key={i} i={i} transaction={transaction} />)
          }
          </tbody>
        </Table>
        </Panel>
      )
    }

  }
}

export default ActivityHistory;
