import {
  FETCH_CUSTOMERS,
  RECEIVE_CUSTOMERS,
  FAILED_CUSTOMER_ACTION,
  ADD_PRICELIST_TO_CUSTOMER,
  PRICELIST_TO_CUSTOMER_SET,
  RECEIVE_CUSTOMER_SALES
} from '../actions/customerActions';

function customers(state = [], action) {
  switch(action.type){
    case FETCH_CUSTOMERS:
      console.log('fetching customers')
      return { ...state, isFetching: true }
    case RECEIVE_CUSTOMERS:
      console.log('fetched customers')

      return { ...state,
        isFetching: false,
        lists: action.customers,
        error: '',
        lastUpdated: action.receivedAt
      }
    case FAILED_CUSTOMER_ACTION:
        console.log('failed customer  actions')

        return { ...state,
          isFetching: false,
          error: action.error,
          lastUpdated: action.receivedAt
        }
    case PRICELIST_TO_CUSTOMER_SET:
        return { ...state,
          error: ''
        }
    default:
      return state
    }
}

export default customers;
