import { getMonth } from "date-fns";

export function getPriceListFromId(pricelists, id){
  const index = pricelists.lists.findIndex((pricelist) => pricelist.id === id);
  if( index < 0  ){
    return null;
  }
  return pricelists.lists[index];
}

export function getGraphSeries(data) {
  console.log(data);

  let series = [];
  for (let i = 0; i < data.length; i++) {
    series[i] =  { x: getMonthName(data[i].month) , y: + data[i].ratio };
  }
  return series;
}

function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return date.toLocaleString('en-GB', { month: 'short' });
}
export function getTax(x, taxCode){
  const TAX_INDEX = 0.2;
  var num = x * TAX_INDEX;
  //return Math.round((x * TAX_INDEX *100))/100;
  //return num.toFixed(2);
  return( x * TAX_INDEX);
}

export function round2money(x){
  if( ! isWhitespaceEmptyString(x)) {
    if( Number.isNaN(x) === false ){
      return parseFloat(x).toFixed(2);
    }
  }

  return x;
}

export function calculateNet(invoice){
  var net = 0;
  invoice.invoiceLineItems.forEach(function(item){
    net += item.lineAmount;
  });
  //  return round2money(net);
  return net;
}

export function calculateTax(invoice){
  var tax = 0;
  invoice.invoiceLineItems.forEach(function(item){
    tax += getTax (item.lineAmount, item.taxCode)
  });
  //return round2money(tax);
  return tax;
}

export function getInvoiceFromId(invoices, id){
  const index = invoices.lists.findIndex((invoice) => invoice.invoiceNumber === id);
  if( index < 0  ){
    return null;
  }
  return invoices.lists[index];
}

export function getPriceListFromName(pricelists, name){
  const index = pricelists.lists.findIndex((pricelist) => pricelist.name === name);
  console.log('getPriceListFromName index: ' + index);
  if( index < 0  ){
    return null;
  }
  return pricelists.lists[index];
}

export function getCustomerFromName(customers, name){
  const index = customers.lists.findIndex((customer) => customer.name === name);
  if( index < 0  ){
    return null;
  }
  return customers.lists[index];
}

export function getTotal(product){
  return product.quantity * product.salesPrice;
}

export function isWhitespaceEmptyString(str)
{
    //RETURN:
    //      = 'true' if 'str' is empty string, null, undefined, or consists of white-spaces only
    return str ? !(/\S/.test(str)) : (str === "" || str === null || str === undefined);
}

export function isNumeric(value) {
    return /^\d+$/.test(value);
}
