import React from 'react';
import { Panel } from 'react-bootstrap';
import SalesCustomer from './SalesCustomer';
import ProfitFunction from './ProfitFunction';


class CustomerInsightsDetail extends React.Component {
  constructor(props) {
    super(props)
  }


  render() {
    return (
      <Panel className="CustomerInsights" collapsible defaultExpanded header="Customer Insights">
        <div><SalesCustomer contactName={this.props.match.params.warehouseId} /></div>
        <div><ProfitFunction contactName={this.props.match.params.warehouseId} months='3' /></div>
      </Panel>
    )
  }

}

export default CustomerInsightsDetail;
