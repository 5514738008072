import {
    FETCHING_BILL_TRANSACTIONS,
    FAILED_FETCHED_BILL_TRANSACTIONS,
    FETCHED_BILL_TRANSACTIONS
} from '../actions/actionCreators';

function billtransaction(state = [], action) {
  switch(action.type){
    case   FETCHING_BILL_TRANSACTIONS:
      console.log(' FETCHING_BILL_TRANSACTIONS')
      return { ...state,
        isFetching: true,
        toDate: action.toDate,
        fromDate: action.fromDate
      }
    case FETCHED_BILL_TRANSACTIONS:
      console.log('FETCHED_BILL_TRANSACTIONS')
      return { ...state,
        isFetching: false,
        lists: action.billtransactions,
        error: '',
        lastUpdated: action.receivedAt
      }
    case FAILED_FETCHED_BILL_TRANSACTIONS:
        console.log('FAILED_FETCHED_BILL_TRANSACTIONS')
        return { ...state,
          isFetching: false,
          error: action.error,
          lastUpdated: action.receivedAt,
          fromDate: 'na',
          toDate: 'na'
        }
    default:
      return state
    }
}


export default billtransaction;
