import React from 'react';
import { Panel} from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import addDays from 'date-fns/addDays';
import PackingCostsMoM from './PackingCostsFoulger';
import PackingCostsVsUnitsShippedMoM from './PackingVsUnitsShippedFoulger'; 
import PackingCostsVsSalesMoM from './PackingVsSalesFoulger'; 
import ShippingCostsVsSalesMoM from './ShippingVsSales'; 
import ShippingCostsVsUnitsShippedMoM from './ShippingVsUnitsShippedFoulger'; 
import ShippingCostsVsUnitsShippedMoMFDC from './ShippingVsUnitsShippedFDC'; 

import HandlingVsUnitsShippedBrakesMoM from './HandlingVsUnitsShippedBrakes';


class BillTransactionEntry extends React.Component {


  render() {
  const {transaction, i} = this.props;

  return(
  <tr key={i}>
        <td className="tran-head-med">{DateFnsFormat(parseISO(transaction.submitDate),'yyyy-MM-dd')}</td>
        <td className="tran-head-med">{transaction.contactName}</td>
        <td className="tran-head-med">{transaction.accountCode}</td> 
        <td className="tran-head-med">{transaction.descr}</td> 
        <td className="tran-head-med">{transaction.quantity}</td> 
        <td className="tran-head-med">{transaction.lineAmount}</td>
        <td className="tran-head-med">{transaction.taxAmount}</td>
        <td className="tran-head-med">{transaction.netAmount}</td>
    </tr>
  )
  }
}

class BillTransactionHistory extends React.Component {
    constructor (props) {
      super(props)
      this.handleToChange = this.handleToChange.bind(this);
      this.handleFromChange = this.handleFromChange.bind(this);

    }
    componentDidMount() {
      this.props.fetchBillTransactionFromDate(DateFnsFormat(addDays(new Date(), -14),'yyyy-MM-dd'));

    }

    createDate = (date) =>{
      console.log('date: ' + date  )
       if( date == 'na'){
         return null;
      }else{
        return new Date(parseISO(date));
      }
    }

    handleFromChange(date) {
      this.props.fetchBillTransactionBetweenDates( DateFnsFormat(date, 'yyyy-MM-dd'), this.props.billtransaction.toDate);

    }

    handleToChange(date) {
      this.props.fetchBillTransactionBetweenDates(this.props.billtransaction.fromDate, DateFnsFormat(date, 'yyyy-MM-dd'))
    }

  

  render() {

    if( this.props.billtransaction.isFetching){
      return (
        <Panel className="dashboard" collapsible defaultExpanded header="Cost Insights">
         <div className="loader">Loading...</div>
        </Panel>
      )
    }
    else{
      return(
        <Panel className="dashboard" collapsible defaultExpanded header="Cost Insights">
          <PackingCostsMoM id="1" />
          <ShippingCostsVsSalesMoM id='6' />
          <PackingCostsVsUnitsShippedMoM id='4' />
          <ShippingCostsVsUnitsShippedMoM id='5' />
          <ShippingCostsVsUnitsShippedMoMFDC id='7' />
          <HandlingVsUnitsShippedBrakesMoM id='6' />


        <div className="inv-top inv-date"><p>Transaction range: </p></div>
        <div className="inv-top inv-date"><p>from:</p>
        <DatePicker
                          selected={this.createDate(this.props.billtransaction.fromDate)}
                          onChange={this.handleFromChange}
                          dateFormat="dd-MM-yyyy"
                        />
        </div>
        <div className="inv-top inv-due"><p>to:</p>
        <DatePicker
                          selected={this.createDate(this.props.billtransaction.toDate)}
                          onChange={this.handleToChange}
                          dateFormat="dd-MM-yyyy"
                        />
        </div>
        <Table className="inv-table" striped bordered condensed hover>
          <thead>
            <tr>
            <th className="inv-head-med">Date</th>
            <th className="tran-head-med">Contact</th>
            <th className="tran-head-med">Account Code</th>
            <th className="tran-head-med">Descr</th>
            <th className="tran-head-med">Quantity</th>
            <th className="tran-head-med">Line Amount</th>
            <th className="tran-head-med">Tax Amount</th>
            <th className="tran-head-med">Net Amount</th>


            </tr>
          </thead>
          <tbody>
          {
            this.props.billtransaction.lists.map((transaction, i) => <BillTransactionEntry
                                                        key={i} i={i} transaction={transaction} />)
          }
          </tbody>
        </Table>
        </Panel>
      )
    }

  }
}

export default BillTransactionHistory;
