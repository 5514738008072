import {
  FETCH_WAREHOUSE_DASHBOARD,
  FETCHED_WAREHOUSE_DASHBOARD,
  UPDATE_WAREHOUSE_DASHBOARD,
  FAILED_FETCHED_WAREHOUSE_DASHBOARD
} from '../actions/stockcountActions';

function warehousedashboard(state = [], action) {
  switch(action.type){
    case FETCH_WAREHOUSE_DASHBOARD:
      console.log('fetching dashboard')
      return { ...state, isFetching: true }
    case FETCHED_WAREHOUSE_DASHBOARD:
      console.log('fetched product')

      return { ...state,
        isFetching: false,
        dashboard: action.dashboard,
        error: '',
        lastUpdated: action.receivedAt
      }
      case FAILED_FETCHED_WAREHOUSE_DASHBOARD:
        console.log('FAILED_FETCHED_WAREHOUSE_DASHBOARD')

        return { ...state,
          isFetching: false,
          error: action.error,
          lastUpdated: action.receivedAt
        }
      case UPDATE_WAREHOUSE_DASHBOARD:
          console.log('update dashboard')
          return { ...state,
            isFetching: false,
            dashboard : updateWarehouse(state.dashboard,  action),
            error: '',
            lastUpdated: action.receivedAt
          }
    default:
      return state
    }
}

function updateWarehouse(dashboard, action) {
  if ( dashboard == ''){
    return dashboard
  }
  return { ...dashboard,
    lastSynchronized: action.updateSyncData
  }
}

export default warehousedashboard;
