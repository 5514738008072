import {
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS, LOGOUT_REQUEST
}from '../actions/actionCreators';
var moment = require('moment');

// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
function auth(state = {}, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      console.log('login request');
      return { ...state,
        isFetching: true,
        isAuthenticated: false,
        errorMessage: ''
      }
    case LOGIN_SUCCESS:
      console.log('login success token expiry: ' + action.token_expiry);
      localStorage.setItem('auth-token_id',action.token_id);
      localStorage.setItem('auth-token_expiry', JSON.stringify(action.token_expiry));
      return { ...state,
        isFetching: false,
        isAuthenticated: true,
        errorMessage: ''
      }
    case LOGIN_FAILURE:
      console.log('login failure');
      localStorage.clear();

      return { ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message
      }
    case LOGOUT_REQUEST:
    case LOGOUT_SUCCESS:
      console.log('logout');
      localStorage.clear();

      return { ...state,
        isFetching: false,
        isAuthenticated: false,
        errorMessage: ''
      }
    default:
      return state
  }
}

export default auth;
