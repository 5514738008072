import React from 'react';
import WareHouseSales from './WareHouseSales';
import InvoiceSummaryHistory from './InvoiceSummaryHistory';
import { ListGroup } from 'react-bootstrap';
import { ListGroupItem } from 'react-bootstrap';
import WareHouse from './WareHouse';
import StockCountUploader from './StockCountUploader';
import { Panel} from 'react-bootstrap';

class SingleWarehouse extends React.Component {
  

  render() {
      let { warehouseId } = this.props.match.params;
      // for dashboard to work
      if( !warehouseId){
        warehouseId = 'Foulger';
      }
      return (
          <Panel header={warehouseId}>
              <WareHouse {...this.props} warehouseId={warehouseId} single={true} />

              <InvoiceSummaryHistory warehouse={warehouseId} />
              <WareHouseSales title={warehouseId} contactName={warehouseId} months='3' />
                      <ListGroup fill>
                  <ListGroupItem  href={'/view/' + warehouseId + '/tran'}>View WarehouseTransactions</ListGroupItem>
                  
                  <ListGroupItem href={'/view/' + warehouseId + '/StockCount'}>View Warehouse Latest Stockcount</ListGroupItem>

                  <ListGroupItem><div>Upload Stock Count</div><br />
            <StockCountUploader warehouseId={warehouseId} /></ListGroupItem>
                 <ListGroupItem  href={'/viewold/' + warehouseId }>View Old Style Warehouse Page</ListGroupItem>
             
        </ListGroup>
          </Panel>
      )
    }
}

export default SingleWarehouse;
