import React from 'react';
import { Panel} from 'react-bootstrap';
import { FormGroup} from 'react-bootstrap';
import { Checkbox} from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Link } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DateFnsFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { DropdownButton , MenuItem} from 'react-bootstrap';
var moment = require('moment');
import { format } from "date-fns";


class TransactionEntry extends React.Component {

  
  printBoolean = (transaction) => {
    if( transaction.reconciled ){
      return "yes";
    }
    else{
      return "no";
    }
  };

  render() {
  const {transaction, i, handleDelete,handleUnreconcile , handleReconcile} = this.props;
  let hidden=true;
  let hiddenReconcile=true;
  let hiddenUnReconcile=true;

  if(transaction.type === 'STOCKCOUNT' ){
    // stockcount is delete only not to be reconciled
    hidden = false;
  }else{
      // we can delete transfers if not reconciled 
      if( !transaction.reconciled  ) {
        hidden = false;
      }
      if( transaction.reconciled ){
          hiddenUnReconcile = false;
      }else{
          hiddenReconcile = false;
      }
  }


  return(
  <tr key={i}>
        <td className="tran-head-med">{transaction.warehouse}</td>
        <td className="tran-head-med">{transaction.type}</td>
        <td className="tran-head-med">{transaction.contactName}</td>
        <td className="tran-head-med">{DateFnsFormat(parseISO(transaction.submitDate), 'dd-MM-yyyy')}</td>
        <td className="tran-head-med">{transaction.prodCode}</td>
        <td className="tran-head-med"><a href={`/invoice/${transaction.invoiceNumber}`}>{transaction.invoiceNumber}</a></td>
        <td className="tran-head-med">{transaction.quantity}</td>
        <td className="tran-head-med">{transaction.salesPrice}</td>
        <td className="tran-head-med">{this.printBoolean(transaction)}</td>
        <td className="tran-head-med">{transaction.statementTransactionId}</td>
        <td hidden={hiddenReconcile} className="tran-head-del"><Button
            bsSize="xsmall"
            onClick={handleReconcile}
          >Reconcile</Button></td>
        <td hidden={hiddenUnReconcile} className="tran-head-del"><Button
            bsSize="xsmall"
            onClick={handleUnreconcile}
          >UnReconcile</Button></td>
        <td hidden={hidden} className="tran-head-del"><Button
            bsSize="xsmall"
            onClick={handleDelete}
          >Delete</Button></td>
    </tr>
  )
  }
}

class TransactionHistory extends React.Component {
  constructor (props) {
    super(props)
    this.handleToChange = this.handleToChange.bind(this);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);

  this.state = {
      type: (this.props.transactions.option == 'unrec' ? 'unrec':this.props.transactions.option == 'stockcount' ? 'stockcount' : 'all')
    };
  }

  createDate = (date) =>{
    console.log('date: ' + date  )
     if( date == 'na'){
       return null;
    }else{
      return new Date(parseISO(date));
    }
  }

  addDays(date, days) {
    var date = new Date(date);
    date.setDate(date.getDate() + days);
    return date;
}


  handleTypeChange(type){
      console.log(type + ' wpw');
      console.log(this.props.transactions.fromDate);
      console.log(this.props.transactions.toDate);

      this.setState({type: type});
      let from = this.createDate(this.props.transactions.fromDate);
      let to = this.createDate(this.props.transactions.toDate);
      if( to == null){
        to = this.addDays(new Date(), 1); 
      }
      console.log('from: ' + from);
      console.log('to : ' + to);

       this.props.fetchTransactionsBetweenDates(this.props.id, DateFnsFormat(from, 'yyyy-MM-dd'), DateFnsFormat(to, 'yyyy-MM-dd'),type);

  }

  handleFromChange(date) {
    this.props.fetchTransactionsBetweenDates(this.props.id, DateFnsFormat(date, 'yyyy-MM-dd'), this.props.transactions.toDate,this.state.type);
  }

  handleToChange(date) {
    this.props.fetchTransactionsBetweenDates(this.props.id, this.props.transactions.fromDate, DateFnsFormat(date, 'yyyy-MM-dd'), this.state.type)
  }

  handleDelete = (id, e) => {
    console.log( 'handleDelete' + id );
    this.props.deleteTransaction(id);
  };

  handleUnreconcile = (id, index, e) => {
    console.log( 'handleUnreconcile' + id + ' index' + index);
    this.props.unreconcileTransaction('transaction', id, index);
  };

  handleReconcile = (id, index, e) => {
    console.log( 'handleReconcile transaction ' + id + ' index' + index);
    this.props.reconcileSingle('transaction', id, index);
  };

  render() {
    const { hidden, transactions, id , fetchTransactionsBetweenDates , deleteTransaction, reconcileSingle, unreconcileTransaction} = this.props;
    const checked = (this.state.type === 'unrec' ? 'checked':'');
    if( hidden ){
      return (<div/>
      )
    }else{
    if( transactions.isFetching){
      return (
        <Panel className="dashboard" collapsible defaultExpanded header="Inventory Transactions">
         <div className="loader">Loading...</div>
        </Panel>
      )
    }else{
      return(
        <Panel className="dashboard" collapsible defaultExpanded header="Inventory Transactions">
        <div className="inv-top inv-date"><p>Transaction range: </p></div>
        <div className="inv-top inv-date"><p>from:</p>
        <DatePicker
                          selected={this.createDate( transactions.fromDate)}
                          onChange={this.handleFromChange}
                          dateFormat="dd-MM-yyyy"
                        />
        </div>
        <div className="inv-top inv-due"><p>to:</p>
        <DatePicker
                          selected={this.createDate(transactions.toDate)}
                          onChange={this.handleToChange}
                          dateFormat="dd-MM-yyyy"
                        />
        </div>
        <div ><b>show type : {this.state.type} </b>
          <DropdownButton bsStyle="default" title="Change Type" id="dropdown-size-extra-small">
        <MenuItem eventKey="1" onClick={this.handleTypeChange.bind(this, 'all' )}>All</MenuItem>
      <MenuItem eventKey="2" onClick={this.handleTypeChange.bind(this, 'unrec' )}>Unreconciled Only </MenuItem>
    <MenuItem eventKey="3" onClick={this.handleTypeChange.bind(this, 'stockcount' )}>Stock Count Only </MenuItem>
      </DropdownButton>
          </div>
        <Table className="inv-table" striped bordered condensed hover>
          <thead>
            <tr>
            <th className="tran-head-med">Warehouse</th>
            <th className="tran-head-med">Type</th>
            <th className="tran-head-med">Contact</th>
            <th className="tran-head-med">Date</th>
            <th className="tran-head-med">Product</th>
            <th className="tran-head-med">Reference</th>
            <th className="tran-head-med">Num</th>
            <th className="tran-head-med">Price</th>
            <th className="tran-head-med">Rec</th>
            <th className="tran-head-med">Rec Ref</th>
            </tr>
          </thead>
          <tbody>
          {
            transactions.lists.map((transaction, i) => <TransactionEntry handleDelete={this.handleDelete.bind(this, transaction.id)}
                                                        handleUnreconcile={this.handleUnreconcile.bind(this, transaction.id, i )}
                                                        handleReconcile={this.handleReconcile.bind(this, transaction.id, i )}
                                                        key={i} i={i} transaction={transaction} />)
          }
          </tbody>
        </Table>
        </Panel>
      )
    }

  }
}
}

export default TransactionHistory;
