import React from 'react';
import { Table } from 'react-bootstrap';
import { Panel } from 'react-bootstrap';
import { useFetch } from '../services/useFetch';
import { serverURL } from "../services/api";
var moment = require('moment');

function RenderStock(data, i) {

  return (<tr className="details-row" key={i}>
    <td className="inv-head-med">{data.prodCode}</td>
    <td className="inv-head-med">{data.descr}</td>
    <td className="inv-head-med">{data.batch}</td>
    <td className="inv-head-med">{data.quantity}</td>
    <td className="inv-head-med">{data.allocated}</td>
    <td className="inv-head-med">{data.expiryDate}</td>
    <td className="inv-head-med">{data.locationCode}</td>
    <td className="inv-head-med">{data.palletRef}</td>
  </tr>)
};

export default function StockCountDetail(props) {

  let url = "inventory/stockcountdetails/" + props.warehouse; 
  if( props.stockTakeDate != null) { 
    url = url + '/' + props.stockTakeDate; 
    
  }
  console.log('detailed url : ' + url )
  const [stock] = useFetch(serverURL + url ); 

  if (!stock) {
    return (
      <Panel className="foulger" collapsible defaultExpanded header={props.title}>
        <div className="loader">Loading...</div>
      </Panel>
    )
  }
  else {
    console.log(JSON.stringify(stock));

    return (
      <Panel header={"Stock Count Details"}>
         <div>{"Warehouse: " + stock.wareHouseName}</div>
        <div>{"Stock Take Date: " + moment(stock.stockTakeDate, moment.ISO_8601).format('DD-MM-YYYY')}</div>

        <Table className="inv-table profit-table" bordered condensed hover>
          <thead>
            <tr className="profit-head">
              <th className="inv-head-med">ProdCode</th>
              <th className="inv-head-med">Description</th>
              <th className="inv-head-med">Batch</th>
              <th className="inv-head-med">Quantity</th>
              <th className="inv-head-med">Allocated</th>
              <th className="inv-head-med">Expiry</th>
              <th className="inv-head-med">Location</th>
              <th className="inv-head-med">PalletRef</th>

            </tr>
          </thead>
          <tbody>
            {stock.stockItems.map(RenderStock)}
          </tbody>
        </Table>
      </Panel>

    )
  }
}

