import React from 'react';
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap';
import { ButtonToolbar} from 'react-bootstrap';
import { Button} from 'react-bootstrap';
import { MenuItem } from 'react-bootstrap';
import { SplitButton } from 'react-bootstrap';
import { Grid } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Panel} from 'react-bootstrap';
import EmailInvoice from './EmailInvoice';

import * as utilFunctions from '../services/util';
var moment = require('moment');

class Invoice extends React.Component {
  isError = () => {
    if( this.props.editableInvoice.error != '' ){
      return true;
    }
    return false;
  };

  showSpinner = (invoiceId) => {
      if( this.props.editableInvoice.isFetching ){
        return true;
      }else if( this.isError()){
        return false;
      }
      else if( this.props.editableInvoice.invoice === '' || this.props.editableInvoice.invoice === undefined){
        return true;
      }else if ( invoiceId === 'new' &&  ! utilFunctions.isWhitespaceEmptyString(this.props.editableInvoice.invoice.invoiceNumber)){
        return true;
      }
      return false;
  };

  componentDidMount() {
    //const { invoiceId } = this.props.params;
    const  invoiceId  = this.props.match.params.warehouseId;

    //this.props.fetchInvoice(invoiceId);
    if( this.props.editableInvoice.isFetching ) {
      return;
    }
    if( this.props.editableInvoice.invoice === '' || this.props.editableInvoice.invoice === undefined) {
      if( invoiceId === 'new'){

          const { customerName } = this.props.params;
          console.log('fetching new invoice template for ' + customerName);

          this.props.generateInvoiceTemplate(customerName);
      }else{
        console.log('fetching editable inv - stale exsisting invoice');

        this.props.fetchInvoice(invoiceId);
      }
    }else if ( invoiceId === 'new' &&  ! utilFunctions.isWhitespaceEmptyString(this.props.editableInvoice.invoice.invoiceNumber)){
      console.log('fetching editable inv - stale new invoice');
      const { customerName } = this.props.params;    

      this.props.generateInvoiceTemplate(customerName);
    }else if ( invoiceId !== this.props.editableInvoice.invoice.invoiceNumber){
      console.log('mismatch fetching .., invoiceId: ' + invoiceId + '  invoice num: ' +  this.props.editableInvoice.invoice.invoiceNumber);
      this.props.fetchInvoice(invoiceId);

    }else{
      console.log('not fetching editable inv it is cached, invoiceId: ' + invoiceId + '  invoice num: ' +  this.props.editableInvoice.invoice.invoiceNumber);
    }
  }

  handleInvoiceApprove = (invoice) => {
    this.props.updateInvoiceStatus(invoice.invoiceNumber, "AUTHORISED");
  };

  handleWareHouseChange = (warehouse) => {
    this.props.updateWarehouse(warehouse, this.props.editableInvoice.invoice.invoiceNumber);
  };

  renderProduct = (product, i) => {
      var salesPrice = utilFunctions.round2money(product.salesPrice);
      var lineAmount = utilFunctions.round2money(product.lineAmount);

      return (<tr key={i}>
            <td className="inv-head-med">{product.prodId}</td>
            <td className="inv-head-descr">{product.descr}</td>
            <td className="inv-head-med">{product.quantity}</td>
            <td className="inv-head-med">{salesPrice}</td>
            <td className="inv-head-med">{product.discount}</td>
            <td className="inv-head-med">{product.accountCode}</td>
            <td className="inv-head-med">{product.taxCode}</td>
            <td className="inv-head-sum">{lineAmount}</td>
                </tr>)
    };

    renderMenuItem = (item, i) => {
      return (<MenuItem onSelect={this.handleWareHouseChange} key={i} eventKey={item}>{item}</MenuItem> )
    };

    renderWareHouseDropDownButton = (warehouses, defaultWarehouse) => {
        if( defaultWarehouse == null){
          defaultWarehouse = 'Not set';
        }
        return (
          <SplitButton
             bsSize="xsmall"
             title={defaultWarehouse}
             key="z"
             id="y"
           >
             {warehouses.map(this.renderMenuItem)}
           </SplitButton>
         )
    }

  renderApproveButton = (invoice) => {
    if( invoice.status === "DRAFT" || invoice.status === "SUBMITTED" ){
      return (
        <Link  to={`/invoice/` + invoice.invoiceNumber}>
          <Button bsSize="small"
          onClick={this.handleInvoiceApprove.bind(null, invoice)}
          >Approve</Button>
          </Link>
      )
    }else{
      return;
    }
  };

  render() {
    const  invoiceId  = this.props.warehouseId;

    if( this.isError( )){
      return(
        <Panel className="dashboard" collapsible defaultExpanded header="Invoice">
         <div>Error accessing Invoice</div>
        </Panel>
      );
    }
    else if( this.showSpinner(invoiceId )){
       return(
         <Panel className="dashboard" collapsible defaultExpanded header="Invoice">
          <div className="loader">Loading...</div>
         </Panel>
       );
     }else{
       const invoice =  this.props.editableInvoice.invoice;
       var net = utilFunctions.calculateNet(invoice);
       var tax = utilFunctions.calculateTax(invoice);
       var sum = net + tax;

       let sentString = 'Invoice not sent'
       if( invoice.isSentToCustomer){
         sentString = 'Invoice sent'
       }
    return (
      <Panel className="dashboard" collapsible defaultExpanded header="Invoice">
        <h3>{invoice.invoiceNumber}</h3>
        <div className="invoice">
        <div className="inv-top to-customer"><p>to:</p>{invoice.bolContact.name}</div>
        <div className="inv-top inv-date"><p>date:</p>{moment(invoice.dateTimeOfSubmission, moment.ISO_8601).format('DD-MM-YYYY')}</div>
        <div className="inv-top inv-due"><p>due:</p>{moment(invoice.dueDate, moment.ISO_8601).format('DD-MM-YYYY')}</div>
        <div className="inv-top inv-status"><p>Status:</p>{invoice.status}</div>
        <div className="inv-top inv-ref"><p>Reference:</p>{invoice.reference}</div>
        <div className="inv-top inv-warehouse"><p>Warehouse:</p>{this.renderWareHouseDropDownButton(this.props.editableInvoice.warehouses, this.props.editableInvoice.invoice.wareHouse)}
</div>


          <Table className="inv-table" striped bordered condensed hover>
            <thead>
              <tr>
              <th className="inv-head-med">Product</th>
              <th className="inv-head-descr">Description</th>
              <th className="inv-head-med">Num</th>
              <th className="inv-head-med">Price</th>
              <th className="inv-head-med">Discount</th>
              <th className="inv-head-med">Account Code</th>
              <th className="inv-head-med">Vat</th>
              <th className="inv-head-sum">Total</th>
              </tr>
            </thead>
            <tbody>
            {
                invoice.invoiceLineItems.map(this.renderProduct)
            }
            </tbody>
          </Table>
      <div className="totals"><label>Net</label><em>{utilFunctions.round2money(net)}</em></div>
      <div className="totals"><label>Tax</label><em>{utilFunctions.round2money(tax)}</em></div>
      <div className="totals"><label>Total</label><em>{utilFunctions.round2money(sum)}</em></div>

      <ButtonToolbar className="button-bar">
            {this.renderApproveButton(invoice)}
            <EmailInvoice sendInvoice={this.props.sendInvoice} buttonText="Email Customer"  title={'Breckland Orchard Invoice ' +  invoice.invoiceNumber} invoiceNumber={invoice.invoiceNumber} currentTemplate={this.props.editableinvoice.bolContactTemplate} emailTo="hakon.martinsen@gmail.com" />
            <EmailInvoice isCourier={true} sendInvoice={this.props.sendInvoice} buttonText="Email Courier" title={'Breckland Orchard Delivery ' +  invoice.invoiceNumber} invoiceNumber={invoice.invoiceNumber} currentTemplate={this.props.editableInvoice.courierTemplate} emailTo="hakon@brecklandorchard.co.uk" />
        </ButtonToolbar>
  </div>
  <div>
  <h3>Notes</h3>
  {
    this.props.editableInvoice.notes.map(function(note, i ){
      return (<div key={i} className="note">{note}</div>)
    })
  }
</div>
</Panel>
    )
  }
  }
}

export default Invoice;
