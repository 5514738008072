import {
    FETCHING_COSTS,
    FAILED_FETCHED_COSTS,
    FETCHED_COSTS
} from '../actions/stockcountActions';

function costs(state = [], action) {
    switch(action.type){
    case   FETCHING_COSTS:
        console.log('fetching costs')
        return { ...state,
            isFetching: true,
            toDate: action.toDate,
            fromDate: action.fromDate,
            option: action.option
        }
    case FETCHED_COSTS:
        console.log('fetched cpsts')
        return { ...state,
            isFetching: false,
            lists: action.costs,
            error: '',
            lastUpdated: action.receivedAt
        }
    case FAILED_FETCHED_COSTS:
        console.log('failed fetched costs')
        return { ...state,
            isFetching: false,
            error: action.error,
            lastUpdated: action.receivedAt,
            fromDate: 'na',
            toDate: 'na',
            option: 'all' 
        }

    default:
        return state;
    }
}




export default costs;
