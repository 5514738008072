import { takeEvery, call, put , take, all,delay} from 'redux-saga/effects';
import * as actions from '../actions/actionCreators';
import * as customerActions from '../actions/customerActions';
import * as invoiceActions from '../actions/invoiceActions';
import * as stockcountActions from '../actions/stockcountActions';


import 'isomorphic-fetch';
import {postInventory,
    getInventoryApi,
    mockGetPriceLists,
    postPriceList,
    getPricelistApi,
    createPriceList,
    getCustomersApi,
    invoiceApi,
    invoiceApiWithBody,
    syncBillApi,
    syncInventoryApi,
    inventoryApi,
    customersApiWithBody,
    loginFetch,
    getSession,
    getActivityApi,
    getBillTransactionApi
} from '../services/api';

var moment = require('moment');





export function* loadPricelists() {
    console.log('loadPricelists');
    try{
        //const response = yield call(mockGetPriceLists);
        const response = yield call(getPricelistApi,'');

        if( response  ){
            console.log(response)
            yield put(actions.receivePricelists( response ))
        }else{
            console.error('no response : ' + error);
            yield put(actions.failedFetchPricelists( error ))
        }
    }catch ( error){
        console.error(error);
        yield put(actions.failedFetchPricelists( error ))
    }

}

export function* getInvoices() {
    while( true) {
        const { status } = yield take(invoiceActions.FETCHING_INVOICES)
        console.log('getInvoices for: ' + status);
        try{
            //const response = yield call(mockGetPriceLists);
            const response = yield call(invoiceApi,status, 'GET');

            if( response  ){
                console.log(response)
                yield put(invoiceActions.receiveInvoices( response ))
            }else{
                console.error('no response : ' + error);
                yield put(invoiceActions.failedFetchInvoices( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(invoiceActions.failedFetchInvoices( error ))
        }
    }
}


export function* updateInvoiceStatus() {
    while( true) {
        const { invoiceId, status} =  yield take(invoiceActions.UPDATE_INVOICE_STATUS)
        console.log('updateInvoiceStatus');
        try{
            //const response = yield call(mockGetPriceLists);
            const response = yield call(invoiceApi, invoiceId + '/' + status, 'PUT');

            if( response  ){
                console.log('completed status update for : ' + invoiceId);
                yield put(invoiceActions.invoiceUpdated(response));
            }else{
                console.error('no response : ' + error);
                yield put(invoiceActions.failedInvoiceAction( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(invoiceActions.failedInvoiceAction( error ))
        }
    }
}

export function* updateInvoiceWarehouse() {
    while( true) {
        const { invoiceId, warehouse} =  yield take(invoiceActions.UPDATE_WAREHOUSE)
        console.log('updateInvoiceWarehouse');
        try{
            //const response = yield call(mockGetPriceLists);
            const response = yield call(invoiceApi, 'warehouse/' + invoiceId + '/' + warehouse, 'PUT');

            if( response  ){
                console.log('completed warehouse update for : ' + invoiceId);
                yield put(invoiceActions.invoiceUpdated(response));
            }else{
                console.error('no response : ' + error);
                yield put(invoiceActions.failedInvoiceAction( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(invoiceActions.failedInvoiceAction( error ))
        }
    }
}

export function* sendInvoice() {
    while( true) {
        const { invoiceId, email, isCourier} =  yield take(invoiceActions.SEND_INVOICE)
        console.log('sendInvoice');
        try{
            //const response = yield call(mockGetPriceLists);
            var a = 'emailcustomer';
            if( isCourier === true){
                a = 'emailshipper';
            }
            const response = yield call(customersApiWithBody,  a + '/' + invoiceId, 'POST', email);

            if( response  ){
                console.log('completed email sending for : ' + invoiceId);
                yield put(invoiceActions.fetchInvoice(invoiceId));
            }else{
                console.error('no response sendInvoice: ' + error);
                yield put(invoiceActions.failedInvoiceAction( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(invoiceActions.failedInvoiceAction( error ))
        }
    }
}

export function* loadProducts() {
    console.log('loadProducts');
    try{
        const response = yield call(getInventoryApi, 'products')
        //const response = yield call ( mockGetProducts);


        if( response  ){
            console.log(response)
            yield put(actions.receiveProducts( response ))
        }else{
            console.error('no response : ' + error);
            yield put(actions.failedFetchProducts( error ))
        }
    }catch ( error){
        console.error(error);
        yield put(actions.failedFetchProducts( error ))
    }

}

export function* getCustomer() {
    while( true) {
        yield take(customerActions.FETCH_CUSTOMERS)
        console.log('getCustomers');
        try{
            const response = yield call(getCustomersApi, 'all' , 'GET');
            //const response = yield call ( mockGetProducts);


            if( response  ){
                console.log(response)
                yield put(customerActions.receiveCustomers( response ))
            }else{
                console.error('no response : ' + error);
                yield put(customerActions.failedCustomerAction( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(customerActions.failedCustomerAction( error ))
        }


    }

}

export function* setPricelistToCustomer() {
    while( true) {
        const { customerName, pricelistName} = yield take(customerActions.ADD_PRICELIST_TO_CUSTOMER);
        console.log('setPricelistToCustomer');
        try{
            const response = yield call(getCustomersApi, 'addplist/' + customerName + '/' + pricelistName, 'POST');
            //const response = yield call ( mockGetProducts);


            if( response  ){
                console.log(response)
                yield put(customerActions.fetchCustomers())
            }else{
                console.error('no response : ' + error);
                yield put(customerActions.failedCustomerAction( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(customerActions.failedCustomerAction( error ))
        }


    }

}

export function* watchGetWarehouseWithDate() {
    /*
    takeEvery will fork a new `checkout` task on each FETCHING_PRODUCTS actions
    i.e. concurrent FETCHING_PRODUCTS actions are allowed
  */
    while( true) {

        const {toDate} = yield take(actions.FETCHING_WAREHOUSES_WITH_DATE);

        console.log('fetching warehouse status');
        try{
            const response = yield call(getInventoryApi, moment(toDate, moment.ISO_8601).format("YYYY-MM-DD"))
            //const response = yield call ( mockGetWarehouses);

            if( response  ){
                console.log(response)
                yield put(actions.receiveWarehouses( response ))
            }else{
                console.error('no response : ' + error);
                yield put(actions.failedFetchWarehouses( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(actions.failedFetchWarehouses( error ))
        }
    }

}

export function* watchGetWarehouse() {
    /*
    takeEvery will fork a new `checkout` task on each FETCHING_PRODUCTS actions
    i.e. concurrent FETCHING_PRODUCTS actions are allowed
  */
    while( true) {

        yield take(actions.FETCHING_WAREHOUSES);

        console.log('fetching warehouse status');
        try{
            const response = yield call(getInventoryApi, '')
            //const response = yield call ( mockGetWarehouses);

            if( response  ){
                console.log(response)
                yield put(actions.receiveWarehouses( response ))
            }else{
                console.error('no response : ' + error);
                yield put(actions.failedFetchWarehouses( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(actions.failedFetchWarehouses( error ))
        }
    }
}


export function* addProductToPricelist() {
    while(true) {
        const { prodCode, price, priceListId } = yield take(actions.ADD_PRODUCT_TO_PRICELIST);

        console.log('addProductToPricelist saga');
        try{

            const response = yield call(postPriceList,'addproduct', priceListId, prodCode, price)
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                yield put(actions.fetchPricelists( ))

            }else{
                console.error('no response : ' + error);
                yield put(actions.failedPricelistUpdate( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(actions.failedPricelistUpdate( error ))
        }
    }
}


export function* getInvoiceWithTemplate() {
    while( true) {
        const { invoiceId } =  yield take(invoiceActions.FETCH_INVOICE)
        console.log('saga getInvoice: ' + invoiceId);
        try{
            //const response = yield call(mockGetPriceLists);
            const response = yield call(invoiceApi,'edit/' + invoiceId, 'GET');

            if( response  ){
                console.log(response)
                yield put(invoiceActions.receivedInvoice(response));

            }else{
                console.error('get invoice no response : ' + error);
                yield put(invoiceActions.failedInvoiceAction( error ))
            }
        }catch ( error){
            // console.error(error);
            yield put(invoiceActions.failedInvoiceAction( error ))
        }
    }
}

export function* getNewInvoiceWithTemplate() {
    while( true) {
        const { customerName } =  yield take(invoiceActions.GENERATE_INVOICE_TEMPLATE)
        console.log('saga getNewInvoiceWithTemplate: ' + customerName);
        try{
            //const response = yield call(mockGetPriceLists);
            const response = yield call(invoiceApi,'template/' + customerName, 'GET');

            if( response  ){
                console.log(response)
                yield put(invoiceActions.receivedInvoice(response));

            }else{
                console.error('get invoice no response : ' + error);
                yield put(invoiceActions.failedInvoiceAction( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(invoiceActions.failedInvoiceAction( error ))
        }
    }
}

export function* submitInvoice() {
    while(true) {
        const {  invoice } = yield take(invoiceActions.SUMBIT_INVOICE);

        console.log('submit invoice saga');
        try{

            const response = yield call(invoiceApiWithBody,'post', invoice)
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                yield put(invoiceActions.invoiceUpdated(response));


            }else{
                console.error('create invoice no response : ' + error);
                yield put(invoiceActions.failedInvoiceAction( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(invoiceActions.failedInvoiceAction( error ))
        }
    }
}

export function* createInvoice() {
    while(true) {
        const {  invoice } = yield take(invoiceActions.CREATE_INVOICE);

        console.log('create invoice saga');
        try{

            const response = yield call(invoiceApiWithBody,'post', invoice)
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                yield put(invoiceActions.invoiceUpdated(response));
                yield put(invoiceActions.fetchInvoices( ))

            }else{
                console.error('create invoice no response : ' + error);
                yield put(invoiceActions.failedInvoiceAction( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(invoiceActions.failedInvoiceAction( error ))
        }
    }
}

export function* deleteInvoice() {
    while(true) {
        const {  invoiceId } = yield take(invoiceActions.DELETE_INVOICE);

        console.log('deleteInvoice saga');
        try{

            const response = yield call(invoiceApi,'delete', invoiceId, '', '')
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                yield put(invoiceActions.fetchInvoices( ))

            }else{
                console.error('create invoice no response : ' + error);
                yield put(invoiceActions.failedInvoiceAction( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(invoiceActions.failedInvoiceAction( error ))
        }
    }
}

export function* createPricelist() {
    while(true) {
        const {  priceList } = yield take(actions.CREATE_PRICELIST);

        console.log('create pricelist saga');
        try{

            const response = yield call(createPriceList, priceList)
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                yield put(actions.fetchPricelists( ))

            }else{
                console.error('addPricelist no response : ' + error);
                yield put(actions.failedPricelistUpdate( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(actions.failedPricelistUpdate( error ))
        }
    }
}

export function* deletePricelist() {
    while(true) {
        const {  priceListId } = yield take(actions.DELETE_PRICELIST);

        console.log('deletePricelist saga');
        try{

            const response = yield call(postPriceList,'delete', priceListId, '', '')
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                yield put(actions.fetchPricelists( ))

            }else{
                console.error('deletePricelist no response : ' + error);
                yield put(actions.failedPricelistUpdate( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(actions.failedPricelistUpdate( error ))
        }
    }
}

export function* removeProductFromPricelist() {
    while(true) {
        const { prodCode, priceListId } = yield take(actions.REMOVE_PRODUCT_FROM_PRICELIST);

        console.log('removeProductFromPricelist saga');
        try{

            const response = yield call(postPriceList,'removeproduct', priceListId, prodCode, '')
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                yield put(actions.fetchPricelists( ))

            }else{
                console.error('removeProductFromPricelist no response : ' + error);
                yield put(actions.failedPricelistUpdate( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(actions.failedPricelistUpdate( error ))
        }
    }
}

export function* transferWarehouse() {
    while(true) {
        const { stockTransfer , fetchReconcilliationItemPage, warehouse, reconcileId} = yield take(actions.TRANSFER_PRODUCT);

        console.log('transferWarehouse saga, reconcileId: ' + reconcileId + ' stockTransfer: ' + JSON.stringify(stockTransfer) );
        let response =  null;
        try{
            if( reconcileId === 'undefined' || reconcileId == null || reconcileId <= 0 ){
                response = yield call(postInventory,'transfer', stockTransfer)
            }
            else if ( reconcileId > 0 ){
                response = yield call(postInventory,'transferrec/' + reconcileId, stockTransfer)
            }
            else{
                console.error("Failed to execute transer, unexpected reconcileId: " + reconcileId);
            }
            //const response = yield call ( mockTransferWarehouse, stockTransfer);


            if( response  ){
                console.log(response)
                if( fetchReconcilliationItemPage > 0 ){
                    yield put(stockcountActions.fetchReconcilliationItem(stockTransfer.fromWarehouse,fetchReconcilliationItemPage ))

                }else{
                    yield put(actions.fetchWarehouses())
                }
                yield put(actions.completedTransferWarehouses( response ))

            }else{
                console.error('no response : ' + error);
                yield put(actions.failedTransferWarehouses( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(actions.failedTransferWarehouses( error ))
        }
    }
}
export function* deleteTransaction() {
    while(true) {
        const { transactionId } = yield take(stockcountActions.DELETE_STOCK_TRANSACTION);

        console.log('deleteTransaction saga');
        try{

            const response = yield call(inventoryApi,'delete/' + transactionId , 'PUT')
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                yield put(stockcountActions.completedDeleteTransaction(''))

            }else{
                console.error('no response : ' + error);
                yield put(stockcountActions.failedDeleteTransaction(error))

            }
        }catch ( error){
            console.error(error);
            yield put(stockcountActions.failedDeleteTransaction(error))

        }
    }
}

export function* watchGetPricelists() {
    /*
    takeEvery will fork a new `checkout` task on each FETCHING_PRODUCTS actions
    i.e. concurrent FETCHING_PRODUCTS actions are allowed
  */
    yield takeEvery(actions.FETCHING_PRICELISTS, loadPricelists)

}
export function* watchGetProducts() {
    /*
    takeEvery will fork a new `checkout` task on each FETCHING_PRODUCTS actions
    i.e. concurrent FETCHING_PRODUCTS actions are allowed
  */
    yield takeEvery(actions.FETCHING_PRODUCTS, loadProducts)

}

export function* watchAddProductToPriceList() {
    addProductToPricelist();
}

export function* watchRemoveProductFromPricelist() {
    removeProductFromPricelist();
}


function* authLogin(name, password) {
    try{
        console.log('authLogin');
        const response = yield call(loginFetch, {name, password});

        if( response  && response.status == 200){
            console.log( 'login success: ')
            yield put( actions.loginSuccess({token_id: name + ':' + password,token_expiry: moment().add(20,"minutes").toArray()}) );
        }else{
            console.error('login no response : ' + error);
            yield put( actions.loginError(error) )
        }
    }catch ( error){
        console.error(error);
        yield put( actions.loginError(error) )
    }

}

function* authCheckExpiry() {
    while(true) {
        yield delay(120000);
        const token_id = localStorage.getItem('auth-token_id');

        if( token_id ){
            if( getSession() ){
                console.log( ' token check ok ')

            }else{
                console.log('token expired!');
                yield put( actions.loginError("token expired") )
            }
        }

    }
}

function* watchAuth() {
    while(true) {
        try {
            const {name, password} = yield take(actions.LOGIN_REQUEST);
            yield call(authLogin, name, password);
            //      yield race([
            //        take(actions.LOGOUT_REQUEST),
            //        call(authAndRefreshTokenOnExpiry, name, password)
            //      ])
            //  yield take(actions.LOGOUT_REQUEST);

            // user logged out, next while iteration will wait for the
            // next LOGIN_REQUEST action

        } catch(error) {
            console.log('watchout : ' + error);
            yield put( actions.loginError(error) )
        }
    }
}



function* reconcileWarehouseUpto() {
    while(true) {
        const {toDate, warehouseId} = yield take(stockcountActions.RECONCILE_WAREHOUSE_UPTO);

        console.log('ReconcileWarehouse saga');
        try{

            const response = yield call(inventoryApi, 'reconciletranupto/' + warehouseId + '/' + toDate, 'PUT')
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                var now = moment();
                //getTransactions(warehouseId,   now.subtract(3, 'months').format("YYYY-MM-DD"), 'na');
                console.log('get transactions for: ' + warehouseId);

                var url = 'whtransaction/' + warehouseId + '/' + now.subtract(3, 'months').format("YYYY-MM-DD") + '/' + 'unrec';

                try{
                    const response = yield call(getInventoryApi,url, 'GET');

                    if( response  ){
                        console.log(response)
                        yield put(stockcountActions.receiveTransactions( response ))
                    }else{
                        console.error('no response : ' + error);
                        yield put(stockcountActions.failedFetchTransactions( error ))
                    }
                }catch ( error){
                    console.error(error);
                    yield put(stockcountActions.failedFetchTransactions( error ))
                }

                yield put(stockcountActions.reconcileWarehouseUptoComplete(toDate))

            }else{
                console.error('no response : ' + error);
                yield put(stockcountActions.failedReconcileWarehouseUpto( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(stockcountActions.failedReconcileWarehouseUpto( error ))
        }
    }
}

function* warehouseSync() {
    while(true) {
        const {fromDate} = yield take(actions.SYNC_WAREHOUSES);

        console.log('warehouseSync saga');
        try{

            const response = yield call(syncInventoryApi, fromDate)
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                yield put(actions.syncWarehousesComplete())
                //  yield put(actions.receiveWarehouses( response ))
                yield put(stockcountActions.updateWarehouseDashboard( response ))




            }else{
                console.error('no response : ' + error);
                yield put(actions.failedSyncWarehouses( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(actions.failedSyncWarehouses( error ))
        }
    }
}

function* billsSync() {
    while(true) {
        const {fromDate} = yield take(invoiceActions.SYNC_BILLS);

        console.log('billsSync saga');
        try{

            const response = yield call(syncBillApi, fromDate)
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                yield put(invoiceActions.syncBillsComplete())
                //  yield put(actions.receiveWarehouses( response ))
                //yield put(stockcountActions.updateWarehouseDashboard( response ))

            }else{
                console.error('no response : ' + error);
                yield put(invoiceActions.failedSyncBills( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(invoiceActions.failedSyncBills( error ))
        }
    }
}

function* adjustWarehouse() {
    while(true) {
        const {date, stockItem, reconcileId, fetchReconcilliationItemPage} = yield take(stockcountActions.ADJUST_WAREHOUSE);

        console.log('adjustWarehouse');
        let response; 
        try{

            if( reconcileId === 'undefined' || reconcileId == null || reconcileId <= 0 ){
                response = yield call(postInventory, 'adj/'+ date, stockItem);
            }
            else if ( reconcileId > 0 ){
                response = yield call(postInventory,'adjrec/' + date + '/' + reconcileId, stockItem)
            }
            else{
                console.error("Failed to execute adjustment, unexpected reconcileId: " + reconcileId);
            }
            //const response = yield call ( mockTransferWarehouse, stockTransfer);


            if( response  ){
                console.log(response)
                if( fetchReconcilliationItemPage > 0 ){
                    yield put(stockcountActions.fetchReconcilliationItem(stockItem.warehouse,fetchReconcilliationItemPage ))

                }else{
                    yield put(actions.fetchWarehouses())
                }
                yield put(stockcountActions.adjustWarehouseOK())
            }else{
                console.error('no response : ' + error);
                yield put(stockcountActions.adjustWarehouseFailed( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(stockcountActions.adjustWarehouseFailed( error ))
        }
    }
}

export function* getWarehouseStatementTransactions() {
    while( true) {
        const { warehouse, fromDate, toDate, option } = yield take(stockcountActions.FETCHING_WAREHOUSE_STATEMENTS)
        console.log('get statements for: ' + warehouse);

        var url = 'whstatement/' + warehouse + '/' + fromDate;
        if( toDate !== 'na'){
            url = url + '/' + toDate;
        }
        url = url + '/' + option;
        try{
            const response = yield call(getInventoryApi,url, 'GET');

            if( response  ){
                console.log('statement response ' + response)
                yield put(stockcountActions.receiveStatements( response ))
            }else{
                console.error('no response : ' + error);
                yield put(stockcountActions.failedFetchStatements( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(stockcountActions.failedFetchStatements( error ))
        }
    }
}

export function* getActivity() {
    while( true) {
        const {  fromDate, toDate, option } = yield take(stockcountActions.FETCHING_ACTVITY)
        console.log('get activity ');

        var url = 'organisation/' + fromDate;
        if( toDate !== 'na'){
            url = url + '/' + toDate;
        }
        //url = url + '/' + option;
        try{
            const response = yield call(getActivityApi,url, 'GET');

            if( response  ){
                console.log('activity response ' + response)
                yield put(stockcountActions.receiveActivity( response ))
            }else{
                console.error('no response : ' + error);
                yield put(stockcountActions.failedFetchActivity( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(stockcountActions.failedFetchActivity( error ))
        }
    }
}

function* getTransactions(warehouse, fromDate, toDate){
    console.log('get transactions for: ' + warehouse);

    var url = 'whtransaction/' + warehouse + '/' + fromDate;
    if( toDate !== 'na'){
        url = url + '/' + toDate;
    }
    try{
        const response = yield call(getInventoryApi,url, 'GET');

        if( response  ){
            console.log(response)
            yield put(stockcountActions.receiveTransactions( response ))
        }else{
            console.error('no response : ' + error);
            yield put(stockcountActions.failedFetchTransactions( error ))
        }
    }catch ( error){
        console.error(error);
        yield put(stockcountActions.failedFetchTransactions( error ))
    }
}

export function* getWarehouseTransactions() {
    while( true) {
        const { warehouse, fromDate, toDate, option } = yield take(stockcountActions.FETCHING_WAREHOUSE_TRANSACTIONS)
        var now = moment();
        console.log('get transactions for: ' + warehouse);

        var url = 'whtransaction/' + warehouse + '/' + fromDate;
        if( toDate !== 'na'){
            url = url + '/' + toDate;
        }

        console.log('getting  transactions with option: ' +  option );
        url = url + '/' + option;


        try{
            const response = yield call(getInventoryApi,url, 'GET');

            if( response  ){
                console.log(response)
                yield put(stockcountActions.receiveTransactions( response ))
            }else{
                console.error('no response : ' + error);
                yield put(stockcountActions.failedFetchTransactions( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(stockcountActions.failedFetchTransactions( error ))
        }
    }
}

export function* getWarehouseReconcilliationItems() {
    while( true) {
        const { warehouse, currentPage } = yield take(stockcountActions.FETCH_RECONCILLIATION)
        console.log('get reconcilliationItems for: ' + warehouse);

        var url = 'autoreconcile/' + warehouse + '/10/' + currentPage;

        try{
            const response = yield call(getInventoryApi,url, 'GET');

            if( response  ){
                console.log(response)
                yield put(stockcountActions.receiveReconcilliationItems( response ))
                if( response.reconcilliationItems.length > 0 ){
                    yield put(stockcountActions.fetchWarehouseTransactionsFromDate(response.reconcilliationItems[0].statementEntity.warehouse, moment(response.reconcilliationItems[0].statementEntity.transactionDate).subtract(20, 'days').format("YYYY-MM-DD"), 'unrec'));
                }

            }else{
                console.error('no response : ' + error);
                yield put(stockcountActions.failedFetchReconcilliationItems( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(stockcountActions.failedFetchReconcilliationItems( error ))
        }
    }
}

export function* getCustomerTransactions() {
    while( true) {
        const { customer, fromDate, toDate } = yield take(stockcountActions.FETCHING_CUSTOMER_TRANSACTIONS)
        console.log('get transactions for: ' + customer);

        var url = 'custtransaction/' + customer + '/' + fromDate;
        if( toDate !== 'na'){
            url = url + '/' + toDate;
        }
        try{
            const response = yield call(getInventoryApi,url, 'GET');

            if( response  ){
                console.log(response)
                yield put(stockcountActions.receiveTransactions( response ))
            }else{
                console.error('no response : ' + error);
                yield put(stockcountActions.failedFetchTransactions( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(stockcountActions.failedFetchTransactions( error ))
        }
    }
}

function* getSalesData() {
    while(true) {
        const {customer, from} = yield take(customerActions.FETCH_CUSTOMER_SALES);

        console.log('getSalesData');
        try{

            const response = yield call(inventoryApi, 'profit/' + customer + '/' + from  , 'GET');
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                yield put(customerActions.receiveSalesData(response));
            }else{
                console.error('no response : ' + error);
                yield put(customerActions.failedCustomerSalesReceive( error ));
            }
        }catch ( error){
            console.error(error);
            yield put(customerActions.failedCustomerSalesReceive( error ))
        }
    }
}

function* syncRemoteWarehouse() {
    while(true) {
        yield take(stockcountActions.SYNC_REMOTE_WAREHOUSE);

        console.log('syncRemoteWarehouse');
        try{

            const response = yield call(inventoryApi, 'whsync' , 'PUT');
            //const response = yield call ( mockTransferWarehouse, stockTransfer);
            if( response  ){
                console.log(response)
                yield put(actions.syncWarehousesComplete())

            }else{
                console.error('no response : ' + error);
                yield put(actions.failedSyncWarehouses( error ))
            }
            /*
      if( response  ){
        console.log(response)


      }else{
        console.error('no response : ' + error);
      }*/
        }catch ( error){
            console.error(error);
        }
    }
}

function* submitStockCount() {
    while(true) {
        const {stock, date, warehouse} = yield take(stockcountActions.SUBMIT_STOCKCOUNT)

        console.log('submitStockCount saga');
        try{
            const response = yield call(postInventory, 'stockcount/'+ warehouse + '/' + date, stock);
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                yield put(actions.fetchWarehouses())
                yield put(stockcountActions.submitStockCountOK())
            }else{
                console.error('no response : ' + error);
                yield put(stockcountActions.submitStockCountFailed( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(stockcountActions.submitStockCountFailed( error ))
        }
    }
}

function* fetchWarehouseDashboard() {
    while(true) {
        const {warehouse} = yield take(stockcountActions.FETCH_WAREHOUSE_DASHBOARD);

        console.log('fetchWarehouseDashboard saga for warehouse: ' + warehouse);
        try{



            const response = yield call(getInventoryApi, 'dashboard/'+ warehouse);
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                yield put(stockcountActions.fetchedWarehouseDashboard(response))
            }else{
                console.error('no response : ' + error);
                yield put(stockcountActions.failedFetchWarehouseDashboard( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(stockcountActions.failedFetchWarehouseDashboard( error ))
        }
    }
}

function* reconcileMatches() {
    while(true) {
        const {warehouse} = yield take(stockcountActions.RECONCILE_MATCHES);

        console.log('reconcileMatches saga');
        try{



            const response = yield call(getInventoryApi, 'forcereconcile/'+ warehouse);
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log('completed autoreconcile: ' + response)
            }else{
                console.error('reconcileMatches   ');
            }
        }catch ( error){
            console.error('reconcileMatches error: ' + error);
        }
    }
}

export function* unreconcile() {
    while(true) {
        const { transactionId, target, index } = yield take(stockcountActions.UNRECONCILE);

        console.log('unreconcile saga');
        try{
            let response = null;
            if( target === 'transaction'){
                response = yield call(inventoryApi,'trunreconcile/' + transactionId , 'PUT')
            }else{
                response = yield call(inventoryApi,'statunreconcile/' + transactionId , 'PUT')

            }
            //const response = yield call ( mockTransferWarehouse, stockTransfer);

            if( response  ){
                console.log(response)
                if( target === 'transaction'){
                    yield put(stockcountActions.completedReconcileTransaction(index, transactionId, false));
                }else{
                    yield put(stockcountActions.completedReconcileStatement(index, transactionId, false));
                }


            }else{
                console.error('completedReconcileAction no response : ' + error);
                if( target === 'transaction'){
                    yield put(stockcountActions.failedTransactionReconcilliationAction(error));
                }else{
                    yield put(stockcountActions.failedStatementReconcilliationAction(error));

                }
            }
        }catch ( error){
            console.error('completedReconcileAction ' + error);
            if( target === 'transaction'){
                yield put(stockcountActions.failedTransactionReconcilliationAction(error));
            }else{
                yield put(stockcountActions.failedStatementReconcilliationAction(error));

            }
        }
    }
}

export function* reconcileSingle() {
    while(true) {
        const { transactionId, target , index} = yield take(stockcountActions.RECONCILE_SINGLE);

        console.log('reconcileSingle saga');
        try{
            let response = null;
            if( target === 'transaction'){
                response = yield call(inventoryApi,'trreconcile/' + transactionId , 'PUT')
            }else{
                response = yield call(inventoryApi,'statreconcile/' + transactionId , 'PUT')

            }
            //const response = yield call ( mockTransferWarehouse, stockTransfer);


            if( response  ){
                console.log(response)
                if( target === 'transaction'){
                    yield put(stockcountActions.completedReconcileTransaction(index, transactionId, true));
                }else{
                    yield put(stockcountActions.completedReconcileStatement(index, transactionId, true));
                }

            }else{
                console.error('completedReconcileAction no response : ' + error);
                if( target === 'transaction'){
                    yield put(stockcountActions.failedTransactionReconcilliationAction(error));
                }else{
                    yield put(stockcountActions.failedStatementReconcilliationAction(error));
                }
            }
        }catch ( error){
            console.error('completedReconcileAction ' + error);
            if( target === 'transaction'){
                yield put(stockcountActions.failedTransactionReconcilliationAction(error));
            }else{
                yield put(stockcountActions.failedStatementReconcilliationAction(error));
            }
        }
    }
}

export function* reconcile() {
    while(true) {
        const { transactionId, statementId, index } = yield take(stockcountActions.RECONCILE_ITEM);

        console.log('reconcile saga');
        try{
            var response = null;
            if( transactionId != -1 ){
                response = yield call(inventoryApi,'reconcile/' + statementId + '/' + transactionId , 'PUT')
            }else{
                response = yield call(inventoryApi,'statreconcile/' + statementId , 'PUT')
            }

            if( response  ){
                console.log(response)
                yield put(stockcountActions.completedReconcileAction(index, transactionId))

            }else{
                console.error('completedReconcileAction no response : ' + error);
                yield put(stockcountActions.failedReconcileAction(error));

            }
        }catch ( error){
            console.error('completedReconcileAction ' + error);
            yield put(stockcountActions.failedReconcileAction(error));
        }
    }
}

export function* getBillTransactions() {
    while( true) {
        const {  fromDate, toDate, option } = yield take(actions.FETCHING_BILL_TRANSACTIONS)
        console.log('getBillTransactions ');

        var url = 'organisation/' + fromDate;
        if( toDate !== 'na'){
            url = url + '/' + toDate;
        }
        //url = url + '/' + option;
        try{
            const response = yield call(getBillTransactionApi,url, 'GET');

            if( response  ){
                console.log('activity response ' + response)
                yield put(actions.fetchedBillTranscations( response ))
            }else{
                console.error('no response : ' + error);
                yield put(actions.failedFetchBillTransactions( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(actions.failedFetchBillTransactions( error ))
        }
    }
}

export function* getWarehouseStockCount() {
    while( true) {
        const { warehouse, stockTakeDate } = yield take(stockcountActions.FETCH_STOCKCOUNT)
        console.log('get stockcount for: ' + warehouse);


        var url = 'lateststockcount/' + warehouse;
        if( stockTakeDate != null ){
            url = 'dbstockcount' + '/' + warehouse + '/' + stockTakeDate; 
        }
    
        try{
            const response = yield call(getInventoryApi,url, 'GET');

            if( response  ){
                console.log(response)
                yield put(stockcountActions.receiveStockCount( response ))
            }else{
                console.error('no response : ' + error);
                yield put(stockcountActions.failedFetchStockCount( error ))
            }
        }catch ( error){
            console.error(error);
            yield put(stockcountActions.failedFetchStockCount( error ))
        }
    }
}

export default function* rootSaga() {
    yield all([
        watchGetProducts(),
        watchGetPricelists(),
        //    removeProductFromPricelist(),
        //    addProductToPricelist(),
        createPricelist(),
        deletePricelist(),
        watchGetWarehouse(),
        watchGetWarehouseWithDate(),
        watchAuth(),
        transferWarehouse(),
        getCustomer(),
        setPricelistToCustomer(),
        getInvoices(),
        deleteInvoice(),
        createInvoice(),
        submitInvoice(),
        getInvoiceWithTemplate(),
        getNewInvoiceWithTemplate(),
        warehouseSync(),
        submitStockCount(),
        updateInvoiceStatus(),
        updateInvoiceWarehouse(),
        adjustWarehouse(),
        syncRemoteWarehouse(),
        sendInvoice(),
        authCheckExpiry(),
        getSalesData(),
        getWarehouseTransactions(),
        getCustomerTransactions(),
        deleteTransaction(),
        unreconcile(),
        reconcileSingle(),
        reconcile(),
        getWarehouseStatementTransactions(),
        getWarehouseReconcilliationItems(),
        reconcileWarehouseUpto(),
        fetchWarehouseDashboard(),
        reconcileMatches(),
        getActivity(),
        billsSync(),
        getBillTransactions(),
        getWarehouseStockCount()
    ]);
}
