import React, { useState, useEffect } from "react";

import { useFetch } from '../services/useFetch';
import { Panel} from 'react-bootstrap';
import { PackingGraphs } from "./PackingGraphs";
import { serverURL } from "../services/api";
import { getGraphSeries} from "../services/util"; 


export default function ShippingVsUnitsShippedFDCMoM(id) {
  const [shipunits1] = useFetch(serverURL + "bill/transportvsunits/mom/FDC/2022-01-01/2022-12-31");
  const [shipunits2] = useFetch(serverURL + "bill/transportvsunits/mom/FDC/2023-01-01/2023-12-31");
  const [shipunits3] = useFetch(serverURL + "bill/transportvsunits/mom/FDC/2024-01-01/2024-12-31");

  if( !shipunits1 || !shipunits2 || !shipunits3){
    return (
      <Panel className="foulger" collapsible defaultExpanded header="Packing Cost per Unit Shipped From FDC">
       <div className="loader">Loading...</div>
      </Panel>
    )
  }
  else{

    const costSeries3 = getGraphSeries(shipunits1.monthList); 
    const costSeries4 = getGraphSeries(shipunits2.monthList); 
    const costSeries5 = getGraphSeries(shipunits3.monthList); 

  return (
    <Panel className="foulger" collapsible defaultExpanded header="Packing Cost per Unit Shipped From FDC">
    <div>{PackingGraphs(costSeries3, costSeries4, costSeries5)}</div>
    <div>Shipping Cost per case 2022: {shipunits1.totalRatio} </div>
    <div>Shipping Cost per case 2023: {shipunits2.totalRatio} </div>
    <div>Shipping Cost per case 2024: {shipunits3.totalRatio} </div>

    </Panel>

  );
}
}