import {
  FETCHING_PRICELISTS,
  FETCHED_PRICELISTS,
  FAILED_FETCHED_PRICELISTS,
} from '../actions/actionCreators';

function pricelists(state = [], action) {
  switch(action.type){
    case FETCHING_PRICELISTS:
      console.log('fetching pricelists')
      return { ...state, isFetching: true }
    case FETCHED_PRICELISTS:
      console.log('fetched pricelists')

      return { ...state,
        isFetching: false,
        lists: action.pricelists,
        error: '',
        lastUpdated: action.receivedAt
      }
    case FAILED_FETCHED_PRICELISTS:
        console.log('failed fetched pricelists')

        return { ...state,
          isFetching: false,
          error: action.error,
          lastUpdated: action.receivedAt
        }
    default:
      return state
    }
}

export default pricelists;
