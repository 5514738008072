import {
    FETCHING_CUSTOMER_TRANSACTIONS,
    FETCHING_WAREHOUSE_TRANSACTIONS,
    FETCHED_TRANSACTIONS,
    FAILED_FETCHED_TRANSACTIONS,
    DELETE_STOCK_TRANSACTION,
    COMPLETED_DELETE_TRANSACTION,
    RECONCILE_SINGLE,
    UNRECONCILE,
    COMPLETED_RECONCILE_TRANSACTION,
    FAILED_RECONCILE_TRANSACTION,
    RECONCILE_WAREHOUSE_UPTO,
    RECONCILE_WAREHOUSE_UPTO_COMPLETE,
    FAILED_RECONCILE_WAREHOUSE_UPTO,
    REMOVE_LOCALLY_TRANSACTION,
    FAILED_DELETE_TRANSACTION
} from '../actions/stockcountActions';

function transactions(state = [], action) {
    switch(action.type){
    case   FETCHING_CUSTOMER_TRANSACTIONS:
        console.log('fetching transactions');
        return { ...state,
            isFetching: true,
            isCustomerTransactions : true,
            toDate: action.toDate,
            fromDate: action.fromDate,
            option: action.option
        };
    case   FETCHING_WAREHOUSE_TRANSACTIONS:
        console.log('fetching transactions');
        return { ...state,
            isFetching: true,
            isCustomerTransactions : false,
            toDate: action.toDate,
            fromDate: action.fromDate,
            option: action.option
        };
    case FETCHED_TRANSACTIONS:
        console.log('fetched transactions');
        return { ...state,
            isFetching: false,
            lists: action.transactions,
            error: '',
            lastUpdated: action.receivedAt
        };
    case FAILED_FETCHED_TRANSACTIONS:
        console.log('failed fetched transactions');

        return { ...state,
            isFetching: false,
            error: action.error,
            lastUpdated: action.receivedAt,
            fromDate: 'na',
            toDate: 'na'
        };
    case DELETE_STOCK_TRANSACTION:
        return { ...state,
            isFetching: false,
            lists: [],
            error: '',
            lastUpdated: action.receivedAt
        };
    case COMPLETED_DELETE_TRANSACTION:
        console.log('COMPLETED_DELETE_TRANSACTION reducer called');

        return { ...state,
            isFetching: false,
            lists: [],
            error: action.message,
            lastUpdated: action.receivedAt
        };
    case COMPLETED_RECONCILE_TRANSACTION:
        return {
            ...state,
            isFetching: false,
            lists: updateObjectInArray(state.lists, action)
        };

    case REMOVE_LOCALLY_TRANSACTION:
        console.log('REMOVE_LOCALLY_TRANSACTION reducer called');

        return {
            ...state,
            isFetching: false,
            lists: removeItem(state.lists, action)
        };

    case FAILED_RECONCILE_WAREHOUSE_UPTO:
    case FAILED_RECONCILE_TRANSACTION:
    case FAILED_DELETE_TRANSACTION:
        return {
            ...state,
            isFetching: false
        };
    case RECONCILE_WAREHOUSE_UPTO_COMPLETE:
        return {
            ...state,
            isFetching: false
        };
    case RECONCILE_SINGLE:
    case UNRECONCILE:
    case RECONCILE_WAREHOUSE_UPTO:
        return {
            ...state,
            isFetching: true,
        };
    default:
        return state;
    }
}

function updateObjectInArray(array, action) {
    return array.map( (item, index) => {
        if(index !== action.index) {
            // This isn't the item we care about - keep it as-is
            console.log('returned old item');
            return item;
        }

        // Otherwise, this is the one we want - return an updated value
        console.log('returned new item');

        return {
            ...item,
            reconciled: action.val
        };
    });
}

function removeItem(state = [], action) {
    return [
        ...state.slice(0,action.i),
        // after the deleted one, to the end
        ...state.slice(action.i + 1)
    ];
}



export default transactions;
