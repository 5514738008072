import React from 'react';
import { Table } from 'react-bootstrap';
import { Panel } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Uploader from './Uploader';

var moment = require('moment');

class StatementMessage extends React.Component {

  render() {
    const {entries} =this.props;
    if( entries > 25 ){
      return (
        <div className="red"><b>Come on work to be done</b></div>
      )
    }else if ( entries > 5 ){
      return( <div><b>Some reconcilliation to do</b></div> )
    }else{
        return( <div><b>:-)</b></div>)
    }
  }
}

class SyncMessage extends React.Component {
  constructor (props) {
    super(props)
    this.sync = this.sync.bind(this);
  }

  sync(){
      this.props.syncWarehouses('');
  };

  render() {
    const {date} =this.props;
     let syncDate = moment(date, moment.ISO_8601);
    if ( syncDate.isBefore(moment().startOf('day'))){
      return (  <Button
          bsSize="xsmall"
          bsStyle="primary"
          onClick={this.sync}>Update
        </Button>  )
    }else{
      return (<div className="green"><b>:-)</b></div>)
    }
  }
}
class UploadMessage extends React.Component {

  render() {
    const {date, warehouseId} =this.props;
     let uploadDate = moment(date, moment.ISO_8601);
    if ( uploadDate.isBefore(moment().startOf('day').subtract(1, 'd') )){
      return (  <div className="red"><b>:-(</b></div>  )
    }else{
      return (<div className="green"><b>:-)</b></div>)
    }
  }
}

class InvoiceMessage extends React.Component {

  render() {
    const {entries,autoreconcile , warehouseId} =this.props;
    if ( entries > 0 ){
      return ( <div><div><b>Some reconcilliation to do</b></div>
      <div><Button
          bsSize="xsmall"
          bsStyle="primary"
          onClick={autoreconcile}>Auto Reconcile
        </Button></div>
        <div><Button
            bsSize="xsmall"
            bsStyle="primary"
            href={'/view/' + warehouseId + '/reconcile'}
            >Manual Reconcile
          </Button></div>
      </div>
      )
    }else{
      return (<div className="green"><b>:-)</b></div>)
    }
  }
}

class NotSetInvoiceMessage extends React.Component {

  render() {
    const {entries} =this.props;
    if ( entries > 0 ){
      return ( <div className="red"><b>Need to add warehouse</b></div>
      )
    }else{
      return (<div className="green"><b>:-)</b></div>)
    }
  }
}

class Status extends React.Component {
  constructor (props) {
    super(props)
    this.autoreconcile = this.autoreconcile.bind(this);
  }

  autoreconcile(){
      this.props.reconcileMatches('Foulger');
  };


  render() {
    const { warehousedashboard } = this.props;
    if(  warehousedashboard.isFetching ){
      return (
        <Panel  header="Status">
            <div className="loader">Loading...</div>
          </Panel>)
    }else{
      return (

        <Panel  header="Status">
        <Table >
          <thead>
            <tr className="profit-head">
            <th className="inv-head-med">Last Sync</th>
            <th className="inv-head-med">Last Statement</th>
            <th className="inv-head-med">Statements to Do</th>
            <th className="inv-head-med">Invoices that should have shipped</th>
            <th className="inv-head-med">Invoices with no warehouse</th>
            </tr>
          </thead>
          <tbody>
          <tr className="summary-row">
            <td className="inv-head-med">{moment(warehousedashboard.dashboard.lastSynchronized, moment.ISO_8601).format('DD-MM-YYYY@HH:mm')}</td>
            <td className="inv-head-med">{moment(warehousedashboard.dashboard.lastStatementDate, moment.ISO_8601).format('DD-MM-YYYY')}</td>
            <td className="inv-head-med">{warehousedashboard.dashboard.unreconciledStatementEntries}</td>
            <td className="inv-head-med">{warehousedashboard.dashboard.unreconciledInvoices}</td>
            <td className="inv-head-med">{warehousedashboard.dashboard.missingWarehouseInvoices}</td>

            </tr>
            <tr><td><SyncMessage date={warehousedashboard.dashboard.lastSynchronized} syncWarehouses={this.props.syncWarehouses} /></td>
              <td><UploadMessage date={warehousedashboard.dashboard.lastStatementDate}  warehouseId={warehousedashboard.dashboard.warehouseName} /></td>
              <td><StatementMessage entries={warehousedashboard.dashboard.unreconciledStatementEntries} /></td>
              <td><InvoiceMessage warehouseId={warehousedashboard.dashboard.warehouseName} autoreconcile={this.autoreconcile} entries={warehousedashboard.dashboard.unreconciledStatementEntries} /></td>
              <td><NotSetInvoiceMessage entries={warehousedashboard.dashboard.missingWarehouseInvoices} /></td>
          </tr>
          </tbody>
        </Table>
      </Panel>
      )
    }
  }
}
export default Status;
