import { createStore, applyMiddleware } from 'redux';
import { syncHistoryWithStore} from 'react-router-redux';
import { createBrowserHistory } from 'history';
import {getSession } from './services/api'
// import the root reducer
import rootReducer from './reducers/index';

//import warehouses from './data/warehousedata';
//import products from './data/products';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/index';


// create an object for the default data
const defaultState = {
  warehouses : {
      isFetching: false,
      isSynching: false,
      units: [],
      lastSynchronized: ''
  },
  products : {
    isFetching: false,
    items: []
  },
  editableInvoice: {
    isFetching: false,
    error: '',
    invoice: '',
    nonPriceListItems: [],
    priceList:'',
    warehouses:[],
    defaultWarehouse:'',
    customerTemplate:'',
    courierTemplate:'',
    notes:''
  },
  editablePricelist: {
    id: '',
    name: '',
    priceListItems: [],
    maxCredit: -1,
    paymentTerms: ''
  },
  invoices : {
    isFetching: false,
    error: '',
    lists: []
  },
  transactions : {
      isFetching: false,
      error: '',
      lists: [],
      isCustomerTransactions: false,
      fromDate: 'na',
      toDate: 'na'
  },
  statements : {
      isFetching: false,
      error: '',
      lists: [],
      fromDate: 'na',
      toDate: 'na'
  },
  activity : {
      isFetching: false,
      error: '',
      lists: [],
      fromDate: 'na',
      toDate: 'na'
  },
  billtransaction : {
    isFetching: false,
    error: '',
    lists: [],
    fromDate: 'na',
    toDate: 'na'
},
  pricelists : {
    isFetching: false,
    error: '',
    lists: []
  },
  customers : {
      isFetching: false,
      error: '',
      lists: []
  },
  auth : {
    isFetching: false,
    isAuthenticated: getSession(),
    errorMessage: ''
  },
  stockcount : {
      isSynching: false,
      warehouse: '',
      stockItems: [],
      dbStockItems: [],
      stockTakeDate: ''
  },
  reconcilliation : {
      isFetching: false,
      warehouse: '',
      items: []
  },
  customersalesdata : {
        isFetching: false,
        salesData : '',
        error: ''
  },warehousedashboard: {
    isFetching: false,
    dashboard : '',
    invoiceList: [],
    error: ''
  }
};

const sagaMiddleware = createSagaMiddleware()
const store = createStore(
  rootReducer,
  defaultState,
  applyMiddleware(sagaMiddleware)
)
sagaMiddleware.run(rootSaga)

export const history = syncHistoryWithStore(createBrowserHistory(), store);

if(module.hot) {
  module.hot.accept('./reducers/',() => {
    const nextRootReducer = require('./reducers/index').default;
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
