import React from 'react';
import WareHouse from './WareHouse';
import StatementHistory from './StatementHistory';
import Uploader from './Uploader';

import { Panel} from 'react-bootstrap';
var moment = require('moment');

class Statements extends React.Component {
  componentDidMount() {
    const  warehouseId  = this.props.match.params.warehouseId;
    var now = moment();


    if(  ! this.props.stockcount.isSynching && ! this.props.warehouses.isFetching && this.props.warehouses.units.length === 0 ){
      this.props.fetchWarehouses(warehouseId);
    }
    this.props.fetchWarehouseStatementsFromDate(warehouseId, now.subtract(2, 'weeks').format("YYYY-MM-DD"), "all");

  }

  render() {
    const  warehouseId  = this.props.match.params.warehouseId;
    const i = this.props.warehouses.units.findIndex((wh) => wh.wareHouseName === warehouseId);
    const warehouse = this.props.warehouses.units[i];
    return (
        <Panel header={warehouseId}>
            <StatementHistory hidden={false} statements={this.props.statements}
            reconcileSingle={this.props.reconcileSingle}
            unreconcile={this.props.unreconcile}
            id={warehouseId}
            fetchStatementsBetweenDates={this.props.fetchWarehouseStatementsBetweenDates} />
          <Uploader warehouseId={warehouseId}/>
        </Panel>
    )
  }
}

export default Statements;
