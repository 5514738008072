import React from 'react';
import WareHouseSales from './WareHouseSales';
import TransactionHistory from './TransactionHistory';
import StatementHistory from './StatementHistory';
import Reconcile from './Reconcile';
import Uploader from './Uploader';
import CurrentStockUploader from './CurrentStockUploader';
import CurrentStock from './CurrentStock';

import InvoiceSync from './InvoiceSync';

import { ListGroup } from 'react-bootstrap';
import { ListGroupItem } from 'react-bootstrap';

import { Panel } from 'react-bootstrap';

class Single extends React.Component {


  render() {

    let { warehouseId } = this.props.match.params;
    // for dashboard to work
    if (!warehouseId) {
      warehouseId = 'Foulger';
    }

    return (
      <Panel header={warehouseId}>

         <CurrentStock warehouse={warehouseId} />

        <ListGroup fill>
          <ListGroupItem href={'/view/' + warehouseId + '/reconcile'}>Reconcile Warehouse Statements</ListGroupItem>
          <ListGroupItem href={'/view/' + warehouseId + '/tran'}>View WarehouseTransactions</ListGroupItem>
          <ListGroupItem href={'/view/' + warehouseId + '/stat'}>View Warehouse Statements</ListGroupItem>
          <ListGroupItem><div>Upload Statements</div><br />
            <Uploader warehouseId={warehouseId} /></ListGroupItem>
            <ListGroupItem><div>Upload Current Stock</div><br/><CurrentStockUploader warehouseId={warehouseId}/></ListGroupItem>
          </ListGroup>
        <div><WareHouseSales title={warehouseId} contactName={warehouseId} months='3' /></div>
        <div><WareHouseSales title={warehouseId} contactName={warehouseId} months='1' /></div>
      </Panel>
    )
  }

}

export default Single;
