import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';


class SelectDropDown extends React.Component {
    static displayName = 'CustomerField';

    static propTypes = {
		label: PropTypes.string,
		searchable: PropTypes.bool,
	};

    static defaultProps = {
        searchable: true,
    };

    state = {
        disabled: false,
        searchable: this.props.searchable,
        selectValue: '',
        clearable: true,
    };

    update = (newValue) => {
      console.log('State changed to ' + newValue);
      this.setState({
        selectValue: newValue
      });
      this.props.updateValue(newValue);
    };

    focusStateSelect = () => {
		this.refs.customerSelect.focus();
	};

    renderValue = (i, name) => {
  //    return ( '{value:' + i + ', label: ' + name+ ' }' );
        return ( { value:i, label:name } );
    };

    render() {
      var options = this.props.updateData;
      return (
              <div className="section">
                      <h3 className="section-heading">{this.props.label}</h3>
                      <Select className="cust-select" ref="customerSelect" autofocus options={this.props.updateData} simpleValue
                  clearable={this.state.clearable} name="selected-state" disabled={this.state.disabled} value={this.state.selectValue}
                  onChange={this.update} searchable={this.state.searchable} />
           </div>
      )
    }
}

export default SelectDropDown;
