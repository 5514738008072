import {
  ADD_PRODUCT_TO_PRICELIST,
  ADD_PRICELIST,
  REMOVE_PRODUCT_FROM_PRICELIST,
  UPDATE_PRODUCT_IN_PRICELIST,
  UPDATE_MAXCREDIT_IN_PRICELIST,
  UPDATE_PAYMENT_TERMS_IN_PRICELIST
} from '../actions/actionCreators';

function updatePricelist(state = [], action) {
  switch(action.type){

    case ADD_PRODUCT_TO_PRICELIST:
      console.log('action add product to price list:' + action.prodCode)

      // return the new state with the new pricelist
      return [...state,{
        prodCode: action.prodCode,
        price: action.price,
        accountCode: action.accountCode,
        description: action.description,
        currency : action.currency,
        taxCode : action.taxCode
      }];

    case UPDATE_PRODUCT_IN_PRICELIST:
        return [
          // from the start to the one we want to delete
          ...state.slice(0,action.i),
          // after the deleted one, to the end
          state[action.i] = {
            prodCode: action.prodCode,
            price: action.price,
            accountCode: action.accountCode,
            description: action.description,
            currency : action.currency,
            taxCode : action.taxCode
          },
          ...state.slice(action.i + 1)
        ]
      case REMOVE_PRODUCT_FROM_PRICELIST:
        console.log('action remove product:' + action.i)

        // we need to return the new state without the deleted comment
        return [
          // from the start to the one we want to delete
          ...state.slice(0,action.i),
          // after the deleted one, to the end
          ...state.slice(action.i + 1)
        ]
      default:
          return state
      }
}


function editablePricelist(state = [], action) {
  switch(action.type){
    case ADD_PRICELIST:
      console.log('add  pricelist: ' + action.priceList)
      return { ...state,
        id : action.priceList.id,
        name : action.priceList.name,
        priceListItems: action.priceList.priceListItems
      }
    case ADD_PRODUCT_TO_PRICELIST:
    case REMOVE_PRODUCT_FROM_PRICELIST:
      return { ...state,
        priceListItems : updatePricelist(state.priceListItems, action)
      }
    case UPDATE_MAXCREDIT_IN_PRICELIST:
      return { ...state,
        maxCredit : action.maxCredit
      }
    case UPDATE_PAYMENT_TERMS_IN_PRICELIST:
      return { ...state,
        paymentTerms : action.paymentTerms
      }
    default:
      return state
    }
}

export default editablePricelist;
