import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Login from './Login'
import Logout from './Logout'
import { requestLogin, requestLogout } from '../actions/actionCreators';


class LogBar extends React.Component {
  render() {
    const { isAuthenticated, errorMessage , requestLogin} = this.props;

    return (
      <div>

          {!isAuthenticated &&
            <Login  {...this.props}
              errorMessage={errorMessage}
              onLoginClick={requestLogin}
            />
          }
          {isAuthenticated &&
            <Logout onLogoutClick={this.props.requestLogout} />
          }
      </div>
    )
  }
}

LogBar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
}


export default LogBar;
