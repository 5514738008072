import {
  RECEIVE_CUSTOMER_SALES,
  FETCH_CUSTOMER_SALES,
  FAILED_RECEIVE_CUSTOMER_SALES
} from '../actions/customerActions';

function customersalesdata(state = [], action) {
  switch(action.type){
    case FETCH_CUSTOMER_SALES:
      console.log('FETCH_CUSTOMER_SALES')
      return { ...state, isFetching: true }
    case RECEIVE_CUSTOMER_SALES:
      console.log('RECEIVE_CUSTOMER_SALES')

      return { ...state,
        isFetching: false,
        salesData: action.salesData,
        error: '',
        lastUpdated: action.receivedAt
      }
    case FAILED_RECEIVE_CUSTOMER_SALES:
        console.log('failed customer  actions')

        return { ...state,
          isFetching: false,
          error: action.error,
          lastUpdated: action.receivedAt
        }
    default:
      return state
    }
}

export default customersalesdata;
