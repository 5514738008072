import React from 'react';
import { Table } from 'react-bootstrap';
import { Panel } from 'react-bootstrap';
import { useFetch } from '../services/useFetch';
import { serverURL } from "../services/api";
var moment = require('moment');

function RenderStock(data, i) {
  return (<tr key={i}>
    <td className="fixed-small">{data.prodCode}</td>
    <td >{data.descr}</td>
    <td className="fixed-small">{data.batch}</td>
    <td className="fixed-small">{data.quantity}</td>
    <td className="fixed-small">{data.expiryDate}</td>
  </tr>)
};

export default function CurrentStock(props) {

  let url = "inventory/currentstock/" + props.warehouse; 
  const [stock] = useFetch(serverURL + url ); 

  if (!stock) {
    return (
      <Panel className="foulger" collapsible defaultExpanded header={props.title}>
        <div className="loader">Loading...</div>
      </Panel>
    )
  }
  else {
    console.log(JSON.stringify(stock));

    return (
      <Panel header={"Current Stock " + stock.wareHouseName}>
        <Table >
          <thead>
            <tr >
              <th className="fixed-small">ProdCode</th>
              <th >Description</th>
              <th className="fixed-small">Batch</th>
              <th className="fixed-small">Quantity</th>
              <th className="fixed-small">Expiry</th>
            </tr>
          </thead>
          <tbody>
            { stock.productsSummary.map(RenderStock)} 
          </tbody>
        </Table>
      </Panel>

    )
  }
}

