import {
  FETCHING_INVOICES,
  FETCHED_INVOICES,
  FAILED_FETCHED_INVOICES
} from '../actions/invoiceActions';

function invoices(state = [], action) {
  switch(action.type){
    case FETCHING_INVOICES:
      console.log('fetching invoices')
      return { ...state, isFetching: true }
    case FETCHED_INVOICES:
      console.log('fetched invoices')

      return { ...state,
        isFetching: false,
        lists: action.invoices,
        error: '',
        lastUpdated: action.receivedAt
      }
    case FAILED_FETCHED_INVOICES:
              console.log('failed  invoice action ' + action.type)

        return { ...state,
          isFetching: false,
          error: action.error,
          lastUpdated: action.receivedAt
        }
    default:
      return state
    }
}

export default invoices;
