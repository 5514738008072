import React, { Component } from 'react'
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';
import { FormControl} from 'react-bootstrap';
import { ControlLabel} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Carousel,  Caption, Item, Panel} from 'react-bootstrap';


class Login extends React.Component {
  render() {
    const { errorMessage } = this.props;

    return (
      <div>

      <Form inline>
      <FormGroup controlId="user">
        <ControlLabel>Name</ControlLabel>
        {' '}
        <FormControl type='text' placeholder='Username'/>
      </FormGroup>
      {' '}
      <FormGroup controlId="password">
        <ControlLabel>password</ControlLabel>
        {' '}
        <FormControl type='password' placeholder='Password'/>
        </FormGroup>
      {' '}
        <Button onClick={(event) => this.handleClick(event)} className="btn btn-primary">
          Login
        </Button>
        {errorMessage &&
          <p>{errorMessage}</p>
        }
        </Form>
      </div>
    )
  }

  handleClick(event){
    const user =  document.getElementById("user").value.trim();
    const password =   document.getElementById("password").value.trim();
    console.log( 'user: ' +  user + ' pw: ' + password);
    console.log(this.props.requestLogin);
    this.props.requestLogin(user, password);

  };
}

Login.propTypes = {
  errorMessage: PropTypes.string
}

export default Login;
