import {
    FETCHING_WAREHOUSE_STATEMENTS,
    FAILED_FETCHED_STATEMENTS,
    FETCHED_STATEMENTS,
    UNRECONCILE,
    RECONCILE_SINGLE,
    COMPLETED_RECONCILE_STATEMENT,
    FAILED_RECONCILE_STATEMENT
} from '../actions/stockcountActions';

function statements(state = [], action) {
    switch(action.type){
    case   FETCHING_WAREHOUSE_STATEMENTS:
        console.log('fetching statements')
        return { ...state,
            isFetching: true,
            toDate: action.toDate,
            fromDate: action.fromDate,
            option: action.option
        }
    case FETCHED_STATEMENTS:
        console.log('fetched statements')
        return { ...state,
            isFetching: false,
            lists: action.statements,
            error: '',
            lastUpdated: action.receivedAt
        }
    case FAILED_FETCHED_STATEMENTS:
        console.log('failed fetched statements')
        return { ...state,
            isFetching: false,
            error: action.error,
            lastUpdated: action.receivedAt,
            fromDate: 'na',
            toDate: 'na',
            option: 'all' 
        }
    case COMPLETED_RECONCILE_STATEMENT:
        console.log('completed reconcile statement')

        return {
            ...state,
            isFetching: false,
            lists: updateObjectInArray(state.lists, action)
        }
    case FAILED_RECONCILE_STATEMENT:
        console.log('failed reconcile statement')

        return {
            ...state,
            isFetching: false,
        }
    case RECONCILE_SINGLE:
    case UNRECONCILE:
    default:
        return state;
    }
}
function updateObjectInArray(array, action) {
    return array.map( (item, index) => {
        if(index !== action.index) {
            // This isn't the item we care about - keep it as-is
            console.log('returned old item')
            return item;
        }

        // Otherwise, this is the one we want - return an updated value
        console.log('returned new item')

        return {
            ...item,
            reconciled: action.val
        };
    });
}



export default statements;
