// editableInvoice reducer
export const ADD_PRODUCT_TO_INVOICE = 'ADD_PRODUCT_TO_INVOICE'
export const REMOVE_PRODUCT_FROM_INVOICE = 'REMOVE_PRODUCT_FROM_INVOICE'
export const UPDATE_PRODUCT_IN_INVOICE = 'UPDATE_PRODUCT_IN_INVOICE'
export const FETCH_INVOICE_OK = 'FETCH_INVOICE_OK'
export const FAILED_INVOICE_ACTION = 'FAILED_INVOICE_ACTION'
export const FETCH_INVOICE = 'FETCH_INVOICE'
export const CREATE_INVOICE = 'CREATE_INVOICE'
export const DELETE_INVOICE = 'DELETE_INVOICE'
export const GENERATE_INVOICE_TEMPLATE = 'GENERATE_INVOICE_TEMPLATE'
export const UPDATE_DUE_DATE = 'UPDATE_DUE_DATE'
export const UPDATE_SUBMIT_DATE = 'UPDATE_SUBMIT_DATE'
export const SUMBIT_INVOICE = 'SUMBIT_INVOICE'
export const SUMBIT_INVOICE_SUBMITTED_OK = 'SUMBIT_INVOICE_SUBMITTED_OK'
export const UPDATE_INVOICE_STATUS = 'UPDATE_INVOICE_STATUS'
export const SEND_INVOICE_OK = 'SEND_INVOICE_OK'
export const SEND_INVOICE = 'SEND_INVOICE'
export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE'

export const SYNC_BILLS = 'SYNC_BILLS'
export const SYNC_BILLS_COMPLETE = 'SYNC_BILLS_COMPLETE'
export const FAILED_SYNC_BILLS = 'FAILED_SYNC_BILLS'

// invoices reducers
export const FETCHING_INVOICES = 'FETCHING_INVOICES';
export const FETCHED_INVOICES = 'FETCHED_INVOICES';
export const FAILED_FETCHED_INVOICES = 'FAILED_FETCHED_INVOICES';



export function fetchInvoices() {
    status = 'unauthorized';
    console.log('fetching  invoices action: ' + status );
    return {
        type: FETCHING_INVOICES,
        status: status
    }
}

export function receiveInvoices( invoices) {
    return {
        type: FETCHED_INVOICES,
        invoices: invoices,
        receivedAt: Date.now()
    }
}
export function failedFetchInvoices( error) {
    return {
        type: FAILED_FETCHED_INVOICES,
        error: error,
        receivedAt: Date.now()
    }
}

export function fetchInvoice(invoiceId) {
    console.log('fetch  invoice: ' + invoiceId);
    return {
        invoiceId: invoiceId,
        type: FETCH_INVOICE
    }
}

export function updateInvoiceStatus(invoiceId, status) {
    console.log('updateInvoiceStatus: ' + invoiceId + ' status: ' + status);
    return {
        invoiceId: invoiceId,
        status: status,
        type: UPDATE_INVOICE_STATUS
    }
}

export function sendInvoice(invoiceId, subject, to, cc, body, isCourier) {
    console.log('sendInvoice: ' + invoiceId + ' status: ' + status);
    var ccs = cc.split(',');
    var to = to.split(',');
    var email = { 'receivers': to,
        'ccs': ccs,
        'sender': 'no_reply@bol.com',
        'subject': subject,
        'text': body}
    return {
        invoiceId: invoiceId,
        email: email,
        isCourier: isCourier,
        type: SEND_INVOICE
    }
}

export function receivedInvoice( response) {
    return {
        type: FETCH_INVOICE_OK,
        invoice: response.invoice,
        priceList: response.priceList,
        nonPriceListItems: response.nonPriceListItems,
        defaultWarehouse: response.defaultWarehouse,
        warehouses:response.warehouses,
        customerTemplate: response.customerTemplate,
        courierTemplate: response.courierTemplate,
        notes: response.notes,
        receivedAt: Date.now()
    }
}

export function removeProductFromInvoice(invoice, i ) {
    console.log('removeProductFromInvoice:' + invoice + 'i ' + i );
    return {
        type: REMOVE_PRODUCT_FROM_INVOICE,
        i: i
    }
}

export function updateProductInInvoice(invoice, i,prodCode, quantity, price , accountCode, description, taxCode , lineAmount) {
    console.log('lineAmount: ' + lineAmount);

    return {
        type: UPDATE_PRODUCT_IN_INVOICE,
        i: i,
        prodId: prodCode,
        price: price,
        quantity: quantity,
        accountCode: accountCode,
        description: description,
        currency : 'GBP',
        taxCode : taxCode,
        lineAmount: lineAmount
    }
}

export function addProductToInvoice(invoice, prodCode, quantity, price ,discount, accountCode, description, taxCode ,lineAmount) {


    return {
        type: ADD_PRODUCT_TO_INVOICE,
        prodId: prodCode,
        price: price,
        quantity: quantity,
        discount: discount,
        accountCode: accountCode,
        description: description,
        currency : 'GBP',
        taxCode : taxCode,
        lineAmount: lineAmount
    }
}

export function createInvoice(invoice ) {
    return {
        type: CREATE_INVOICE,
        invoice: invoice
    }
}

export function invoiceUpdated(invoice ) {
    return {
        type: SUMBIT_INVOICE_SUBMITTED_OK,
        updatedinvoice: invoice,
        receivedAt: Date.now()
    }
}

export function updateSubmitDate(date ) {
    return {
        type: UPDATE_SUBMIT_DATE,
        date: date
    }
}

export function updateDueDate(date ) {
    return {
        type: UPDATE_DUE_DATE,
        date: date
    }
}

export function updateWarehouse(warehouse , invoiceId) {
    return {
        type: UPDATE_WAREHOUSE,
        warehouse: warehouse,
        invoiceId: invoiceId
    }
}

export function generateInvoiceTemplate(customerName ) {
    return {
        type: GENERATE_INVOICE_TEMPLATE,
        customerName: customerName
    }
}


export function submitInvoice( invoice ) {
    return {
        type: SUMBIT_INVOICE,
        invoice: invoice
    }
}

export function failedInvoiceAction( error) {
    return {
        type: FAILED_INVOICE_ACTION,
        error: error,
        receivedAt: Date.now()
    }
}

export function syncBills(fromDate) {
    console.log('syncBills action');
    return {
      type: SYNC_BILLS,
      fromDate: fromDate
    }
  }
  
  export function syncBillsComplete() {
    console.log('fetchBillsComplete ');
  
    return {
      type: SYNC_BILLS_COMPLETE,
      receivedAt: Date.now()
    }
  }
  export function failedSyncBills( error) {
    console.log('failedSyncBills ' + error) ;
  
    return {
      type: FAILED_SYNC_BILLS,
      error: error,
      receivedAt: Date.now()
    }
  }