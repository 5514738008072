import * as stockcountActions from '../actions/stockcountActions';


function reconcilliation(state = [], action) {
  switch(action.type){
    case stockcountActions.FETCH_RECONCILLIATION:
      console.log('starting reconcilliation item  fetch ')
      return { ...state,
        isFetching: true,
        currentPage: action.page,
        warehouse: action.warehouse
      }
    case stockcountActions.RECONCILE_ITEM:
      console.log('starting reconcile item ')
      return { ...state, isFetching: true }
    case stockcountActions.FETCHED_RECONCILLIATION:
    console.log('fetched reconciled items')

      return { ...state,
        isFetching: false,
        items: action.items,
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        error: '',
        lastUpdated: action.receivedAt
      }
      case stockcountActions.FAILED_FETCHED_RECONCILLIATION:
        console.log('failed fetched product')

        return { ...state,
          isFetching: false,
          items: '',
          error: action.error,
          lastUpdated: action.receivedAt
        }
     case stockcountActions.COMPLETED_RECONCILE_ITEM:
       return {
         ...state,
           isFetching: false,
           items: removeItem(state.items, action)
         }
      case stockcountActions.UPDATE_RECONCILE_ITEM:
             return {
               // from the start to the one we want to delete
               ...state,
               items: updateItem(state.items, action)
             }
    case stockcountActions.FAILED_RECONCILE_ITEM:
    return {
      ...state,
        isFetching: false,
        error: action.error,
        lastUpdated: action.receivedAt
      }
    default:
      return state
    }
}

function removeItem(state = [], action) {
  return [
    ...state.slice(0,action.index),
    // after the deleted one, to the end
    ...state.slice(action.index + 1),

  ]
}

function updateItem(items = [], action) {
  return [
    ...items.slice(0,action.i),
    // after the deleted one, to the end
    items[action.i] = {
      statementEntity: items[action.i].statementEntity,
      stockTransactionItem: action.transaction
    },
    ...items.slice(action.i + 1)

  ]
}


export default reconcilliation;
