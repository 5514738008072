import React from "react";

import { useFetch } from '../services/useFetch';
import { Panel } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { serverURL } from "../services/api";



function PrintContactSales(props) {
  return (
    <tr key={props.i}>
      <td className="tran-head-med">{props.summary.name}</td>
      <td className="tran-head-med">{props.summary.periodTotal}</td>
      <td className="tran-head-med">{props.summary.lastYearRatio}</td>
      <td className="tran-head-med">{props.summary.changeYoY} %</td>
      <td className="tran-head-med"><a href={'/customer/' + props.summary.name}>See details</a>
</td>

    </tr>
  )
}

export default function SalesTopX(props) {
  const [data] = useFetch(serverURL + props.url);


  if (!data) {
    return (
      <Panel className="foulger" collapsible defaultExpanded header={props.title}>
        <div className="loader">Loading...</div>
      </Panel>
    )
  }
  else {

    //  console.log(JSON.stringify(data))

    return (
      <Panel className="foulger" collapsible defaultExpanded header={props.title}>
        <Table className="inv-table" striped bordered condensed hover>
          <thead>
            <tr>
              <th className="tran-head-med">Contact</th>
              <th className="tran-head-med">Sales to today</th>
              <th className="tran-head-med">Same period Last Year</th>
              <th className="tran-head-med">Change YoY</th>
              <th className="tran-head-med">See MoM Breakdown</th>

            </tr>
          </thead>
          <tbody>
            {
              data.map((customer, i) => <PrintContactSales key={i} i={i} summary={customer} />)
            }
          </tbody>
        </Table>

      </Panel>

    );
  }
}