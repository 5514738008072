export const GET_STOCK = 'GET_STOCK';
export const COMPLETED_DELETE_TRANSACTION = 'COMPLETED_DELETE_TRANSACTION';
export const DELETE_STOCK_TRANSACTION = 'DELETE_STOCK_TRANSACTION';
export const FAILED_DELETE_TRANSACTION = 'FAILED_DELETE_TRANSACTION';
export const FETCH_STOCKCOUNT = 'FETCH_STOCKCOUNT';
export const FETCH_STOCKCOUNT_OK = 'FETCH_STOCKCOUNT_OK';
export const FETCH_STOCKCOUNT_FAILED = 'FETCH_STOCKCOUNT_FAILED';
export const SUBMIT_STOCKCOUNT = 'SUBMIT_STOCKCOUNT';
export const SUBMIT_STOCKCOUNT_OK = 'SUBMIT_STOCKCOUNT_OK';
export const SUBMIT_STOCKCOUNT_FAILED = 'SUBMIT_STOCKCOUNT_FAILED';
export const STOCK_QUANTITY_UPDATE = 'STOCK_QUANTITY_UPDATE';
export const ADD_STOCK = 'ADD_STOCK';
export const ADJUST_WAREHOUSE = 'ADJUST_WAREHOUSE';
export const ADJUST_WAREHOUSE_OK = 'ADJUST_WAREHOUSE_OK';
export const ADJUST_WAREHOUSE_FAILED = 'ADJUST_WAREHOUSE_FAILED';
export const SYNC_REMOTE_WAREHOUSE = 'SYNC_REMOTE_WAREHOUSE';
export const FETCHING_WAREHOUSE_TRANSACTIONS = 'FETCHING_WAREHOUSE_TRANSACTIONS';
export const FETCHING_CUSTOMER_TRANSACTIONS = 'FETCHING_CUSTOMER_TRANSACTIONS';
export const FETCHED_TRANSACTIONS = 'FETCHED_TRANSACTIONS';
export const FAILED_FETCHED_TRANSACTIONS = 'FAILED_FETCHED_TRANSACTIONS';
export const UNRECONCILE = 'UNRECONCILE';
export const RECONCILE_SINGLE = 'RECONCILE_SINGLE';
export const RECONCILE_ITEM = 'RECONCILE_ITEM';
export const COMPLETED_RECONCILE_ITEM = 'COMPLETED_RECONCILE_ITEM';
export const COMPLETED_RECONCILE_TRANSACTION = 'COMPLETED_RECONCILE_TRANSACTION';
export const COMPLETED_RECONCILE_STATEMENT = 'COMPLETED_RECONCILE_STATEMENT';

export const FAILED_RECONCILE_ITEM = 'FAILED_RECONCILE_ITEM';
export const FAILED_RECONCILE_TRANSACTION = 'FAILED_RECONCILE_TRANSACTION';
export const FAILED_RECONCILE_STATEMENT = 'FAILED_RECONCILE_STATEMENT';
export const FETCHING_WAREHOUSE_STATEMENTS = 'FETCHING_WAREHOUSE_STATEMENTS';
export const FAILED_FETCHED_STATEMENTS = 'FAILED_FETCHED_STATEMENTS';
export const FETCHED_STATEMENTS = 'FETCHED_STATEMENTS';
export const FETCHING_WAREHOUSE_RECONCILLIATION = 'FETCHING_WAREHOUSE_RECONCILLIATION';
export const FAILED_FETCHED_RECONCILLIATION  = 'FAILED_FETCHED_RECONCILLIATION ';
export const FETCHED_RECONCILLIATION  = 'FETCHED_RECONCILLIATION';
export const FETCH_RECONCILLIATION  = 'FETCH_RECONCILLIATION';
export const RECONCILE_WAREHOUSE_UPTO_COMPLETE = 'RECONCILE_WAREHOUSE_UPTO_COMPLETE';
export const RECONCILE_WAREHOUSE_UPTO = 'RECONCILE_WAREHOUSE_UPTO';
export const FAILED_RECONCILE_WAREHOUSE_UPTO = 'FAILED_RECONCILE_WAREHOUSE_UPTO';
export const UPDATE_RECONCILE_ITEM = 'UPDATE_RECONCILE_ITEM';
export const REMOVE_LOCALLY_TRANSACTION = 'REMOVE_LOCALLY_TRANSACTION';
export const FAILED_FETCHED_WAREHOUSE_DASHBOARD = 'FAILED_FETCHED_WAREHOUSE_DASHBOARD';
export const FETCHED_WAREHOUSE_DASHBOARD = 'FETCHED_WAREHOUSE_DASHBOARD';
export const FETCH_WAREHOUSE_DASHBOARD = 'FETCH_WAREHOUSE_DASHBOARD';
export const RECONCILE_MATCHES = 'RECONCILE_MATCHES';
export const UPDATE_WAREHOUSE_DASHBOARD = 'UPDATE_WAREHOUSE_DASHBOARD';
export const FETCHING_ACTVITY = 'FETCHING_ACTVITY';
export const FAILED_FETCHED_ACTVITY = 'FAILED_FETCHED_ACTVITY';
export const FETCHED_ACTVITY = 'FETCHED_ACTVITY';

export const FETCHING_COSTS = 'FETCHING_COSTS';
export const FAILED_FETCHED_COSTS = 'FAILED_FETCHED_COSTS';
export const FETCHED_COSTS = 'FETCHED_COSTS';




export function addStock(warehouse, prodCode, descr, quantity, batch) {
    console.log('addStock action: ' + warehouse + ' ' + prodCode + ' ' + descr + ' ' + quantity);

    return {
        type: ADD_STOCK,
        warehouse: warehouse,
        prodCode: prodCode,
        descr: descr,
        quantity: quantity,
        batch: batch
    };
}

export function getStockById(warehouseName , warehouses) {
    console.log('getStockById action');
    const wareHouseIndex = warehouses.units.findIndex((warehouses) => warehouses.wareHouse === warehouseId);
    var sortedStock = [];
    warehouses.units[wareHouseIndex].productsSummary.map(function(stockItem){
        if( stockItem.quantity != 0 ){
            sortedStock.push(stockItem);
        }
    });
    // if id = all we fetach all customers
    return {
        type: GET_STOCK,
        warehouse: warehouseName,
        stockItems: sortedStock
    };
}

export function getStock(warehouseName, stock) {
    console.log('getStock action');

    var sortedStock = [];
    stock.map(function(stockItem){
        if( stockItem.quantity != 0 ){
            sortedStock.push(stockItem);
        }
    });
    // if id = all we fetach all customers
    return {
        type: GET_STOCK,
        warehouse: warehouseName,
        stockItems: sortedStock
    };
}

export function submitStockCount(date, stock, warehouse) {
    console.log('submitStockCount');
    // if id = all we fetach all customers
    return {
        type: SUBMIT_STOCKCOUNT,
        date: date,
        stock: stock,
        warehouse: warehouse
    };
}

export function deleteTransaction(id) {
    console.log('deleteTransaction');
    return {
        type: DELETE_STOCK_TRANSACTION,
        transactionId: id
    };
}

export function failedDeleteTransaction(error) {
    console.log('faileddeleteTransaction');
    return {
        type: FAILED_DELETE_TRANSACTION,
        error: error,
        receivedAt: Date.now()
    };
}

export function unreconcile(target, id, index) {
    console.log('unreconcile');
    return {
        type: UNRECONCILE,
        target: target,
        transactionId: id,
        index: index
    };
}

export function reconcileSingle(target, id, index) {
    console.log('reconcile single');
    return {
        type: RECONCILE_SINGLE,
        target: target,
        transactionId: id,
        index: index
    };
}

export function reconcile(statementId, transactionId, index ) {
    console.log('reconcile');
    return {
        type: RECONCILE_ITEM,
        statementId: statementId,
        transactionId: transactionId,
        index: index

    };
}

export function completedReconcileAction(index , transactionId) {
    console.log('reconcile complete');
    return {
        type: COMPLETED_RECONCILE_ITEM,
        index: index,
        transactionId: transactionId
    };
}

export function completedReconcileStatement(index , statementId, state) {
    console.log('reconcile statement complete');
    return {
        type: COMPLETED_RECONCILE_STATEMENT,
        index: index,
        statementId: statementId,
        val: state

    };
}

export function completedReconcileTransaction(index , transactionId, state) {
    console.log('reconcile trabsaction complete');
    return {
        type: COMPLETED_RECONCILE_TRANSACTION,
        index: index,
        transactionId: transactionId,
        val: state

    };
}


export function submitStockCountFailed( error) {
    console.log('submitStockCountFailed');
    return {
        type: SUBMIT_STOCKCOUNT_FAILED,
        error: error,
        receivedAt: Date.now()
    };
}

export function submitStockCountOK() {
    console.log('submitStockCountOK');
    return {
        type: SUBMIT_STOCKCOUNT_OK,
        receivedAt: Date.now()
    };
}

export function updateStockQuantity(warehouse, prodCode, i , quantity, batch) {
    console.log('updateStockQuantity: ' + prodCode + ' index: ' + i + " quantity: " + quantity);
    return {
        type: STOCK_QUANTITY_UPDATE,
        receivedAt: Date.now(),
        i: i,
        prodCode: prodCode,
        quantity: quantity,
        batch: batch, 
        warehouse: warehouse
    };
}


export function adjustWarehouseReconcile(wareHouseName, prodCode, quantity, date, reason, page, reconcileId, batch) {
    console.log('adjustWarehousereconcile called');
    return {
        type: ADJUST_WAREHOUSE,
        date: date,
        fetchReconcilliationItemPage:page,
        reconcileId: reconcileId,
        stockItem : {
            prodCode: prodCode,
            quantity: parseInt(quantity),
            warehouse: wareHouseName,
            descr: reason,
            batch: batch
        }
    };
}

export function adjustWarehouse(wareHouseName, prodCode, quantity, reason, date, batch) {
    console.log('adjustWarehouse called');
    return {
        type: ADJUST_WAREHOUSE,
        date: date,
        stockItem : {
            prodCode: prodCode,
            quantity: parseInt(quantity),
            warehouse: wareHouseName,
            descr: reason,
            batch: batch
        }
    };
}


export function adjustWarehouseFailed( error) {
    console.log('adjustWarehouseFailed');
    return {
        type: ADJUST_WAREHOUSE_FAILED,
        error: error,
        receivedAt: Date.now()
    };
}

export function adjustWarehouseOK() {
    console.log('adjustWarehouseOK');
    return {
        type: ADJUST_WAREHOUSE_OK,
        receivedAt: Date.now()
    };
}

export function syncRemoteWarehouses() {
    console.log('syncRemoteWarehouses');
    return {
        type: SYNC_REMOTE_WAREHOUSE
    };
}

export function fetchWarehouseTransactionsFromDate(warehouse, fromDate, option) {
    console.log('fetchTransactionsFromDate action');
    return {
        type: FETCHING_WAREHOUSE_TRANSACTIONS,
        warehouse: warehouse,
        fromDate: fromDate,
        toDate: 'na',
        option: option
    };
}

export function fetchWarehouseTransactionsBetweenDates(warehouse, fromDate, toDate, option) {
    console.log('fetchWarehouseTransactionsBetweenDates action');
    return {
        type: FETCHING_WAREHOUSE_TRANSACTIONS,
        warehouse: warehouse,
        fromDate: fromDate,
        toDate: toDate,
        option: option
    };
}

export function fetchActivityFromDate(fromDate) {
    console.log('fetchActivityFromDate action');
    return {
        type: FETCHING_ACTVITY,
        fromDate: fromDate,
        toDate: 'na',
        option: 'all'
    };
}

export function fetchActivityBetweenDates(fromDate, toDate) {
    console.log('fetchActivityBetweenDates action');
    return {
        type: FETCHING_ACTVITY,
        fromDate: fromDate,
        toDate: toDate,
        option: 'all'
    };
}

export function fetchWarehouseStatementsFromDate(warehouse, fromDate, option) {
    console.log('fetchWarehouseStatementsFromDate action');
    return {
        type: FETCHING_WAREHOUSE_STATEMENTS,
        warehouse: warehouse,
        fromDate: fromDate,
        toDate: 'na',
        option: option
    };
}

export function fetchWarehouseStatementsBetweenDates(warehouse, fromDate, toDate, option ) {
    console.log('fetchWarehouseStatementsBetweenDates action');
    return {
        type: FETCHING_WAREHOUSE_STATEMENTS,
        warehouse: warehouse,
        fromDate: fromDate,
        toDate: toDate,
        option: option
    };
}

export function fetchCustomerTransactionsFromDate(customer, fromDate) {
    console.log('fetchTransactionsFromDate action');
    return {
        type: FETCHING_CUSTOMER_TRANSACTIONS,
        customer: customer,
        fromDate: fromDate,
        toDate: 'na',
        option: 'all'
    };
}

export function fetchCustomerTransactionsBetweenDates(customer, fromDate, toDate) {
    console.log('fetchCustomerTransactionsBetweenDates action');
    return {
        type: FETCHING_CUSTOMER_TRANSACTIONS,
        customer: customer,
        fromDate: fromDate,
        toDate: toDate,
        option: 'all'
    };
}

export function receiveTransactions( transactions) {
    return {
        type: FETCHED_TRANSACTIONS,
        transactions: transactions,
        receivedAt: Date.now()
    };
}

export function receiveActivity( activity) {
    console.log('receiveActivity action');
    return {
        type: FETCHED_ACTVITY,
        activity: activity,
        receivedAt: Date.now()
    };
}

export function receiveStockCount( stock) {
    console.log('receiveStockCount action');
    return {
        type: FETCH_STOCKCOUNT_OK,
        stock: stock,
        receivedAt: Date.now()
    };
}

export function fetchStockCount( warehouse , stockTakeDate) {
    console.log('fetchStockCount action');
    return {
        type: FETCH_STOCKCOUNT,
        warehouse: warehouse,
        stockTakeDate: stockTakeDate
    };
}


export function receiveStatements( statements) {
    console.log('receiveStatements action');
    return {
        type: FETCHED_STATEMENTS,
        statements: statements,
        receivedAt: Date.now()
    };
}


export function completedDeleteTransaction(message) {
    console.log('completedDeleteTransaction action');

    return {
        type: COMPLETED_DELETE_TRANSACTION,
        message: message,
        receivedAt: Date.now()
    };
}

export function failedFetchTransactions( error) {
    return {
        type: FAILED_FETCHED_TRANSACTIONS,
        error: error,
        receivedAt: Date.now()
    };
}

export function failedFetchWarehouseDashboard( error) {
    return {
        type: FAILED_FETCHED_WAREHOUSE_DASHBOARD,
        error: error,
        receivedAt: Date.now()
    };
}

export function failedFetchStatements( error) {
    return {
        type: FAILED_FETCHED_STATEMENTS,
        error: error,
        receivedAt: Date.now()
    };
}
export function failedFetchActivity( error) {
    return {
        type: FAILED_FETCHED_ACTVITY,
        error: error,
        receivedAt: Date.now()
    };
}

export function failedFetchStockCount( error) {
    return {
        type: FETCHED_STOCKCOUNT_FAILED,
        error: error,
        receivedAt: Date.now()
    };
}

export function fetchReconcilliationItem( warehouseId, page ) {
    console.log('reconcileReconcilliationItem action');

    return {
        type: FETCH_RECONCILLIATION,
        warehouse: warehouseId,
        currentPage: page,
        receivedAt: Date.now()
    };
}

export function reconcileReconcilliationItem( index ) {
    console.log('reconcileReconcilliationItem action');

    return {
        type: RECONCILE_ITEM,
        index: index,
        receivedAt: Date.now()
    };
}

export function receiveReconcilliationItems(input) {
    console.log('receiveReconcilliation action');

    return {
        type: FETCHED_RECONCILLIATION,
        items: input.reconcilliationItems,
        currentPage: input.currentPage,
        totalPages: input.totalPages,
        receivedAt: Date.now()
    };
}


export function failedFetchReconcilliationItems( error) {
    console.log('failedFetchReconcilliationItems action');

    return {
        type: FAILED_FETCHED_RECONCILLIATION,
        error: error,
        receivedAt: Date.now()
    };
}

export function failedStatementReconcilliationAction( error) {
    console.log('failedFetchReconcilliation action');

    return {
        type: FAILED_RECONCILE_STATEMENT,
        error: error,
        receivedAt: Date.now()
    };
}

export function failedTransactionReconcilliationAction( error) {
    console.log('failedFetchReconcilliation action');

    return {
        type: FAILED_RECONCILE_TRANSACTION,
        error: error,
        receivedAt: Date.now()
    };
}

export function failedReconcileAction( error) {
    console.log('failedReconcileAction action');

    return {
        type: FAILED_RECONCILE_ITEM,
        error: error,
        receivedAt: Date.now()
    };
}


export function reconcileWarehouseUpto(warehouseId, toDate) {
    console.log('reconcileWarehouseUptoComplete action');
    return {
        type: RECONCILE_WAREHOUSE_UPTO,
        toDate: toDate,
        warehouseId: warehouseId
    };
}

export function reconcileWarehouseUptoComplete() {
    console.log('reconcileWarehouseUptoComplete ');
    return {
        type: RECONCILE_WAREHOUSE_UPTO_COMPLETE,
        receivedAt: Date.now(),
        items: [],
        pageNum: 0,
        totalPages: 0
    };
}


export function failedReconcileWarehouseUpto( error) {
    console.log('failedReconcileWarehouse ' + error) ;

    return {
        type: FAILED_RECONCILE_WAREHOUSE_UPTO,
        error: error,
        receivedAt: Date.now()
    };
}

export function updateReconcileTransaction( transaction , i ) {
    console.log('updateReconcileTransaction') ;

    return {
        type: UPDATE_RECONCILE_ITEM,
        transaction: transaction,
        i: i
    };
}

export function removeTransactionLocally( i ) {
    console.log('removeTransactionLocally') ;

    return {
        type: REMOVE_LOCALLY_TRANSACTION,
        i: i
    };
}

export function fetchWarehouseDashboard(warehouse) {
    console.log('fetchWarehouseDashboard action');
    return {
        type: FETCH_WAREHOUSE_DASHBOARD,
        warehouse: warehouse
    };
}

export function fetchedWarehouseDashboard(dashboard) {
    console.log('fetchedWarehouseDashboard action');
    return {
        type: FETCHED_WAREHOUSE_DASHBOARD,
        dashboard: dashboard
    };
}

export function updateWarehouseDashboard(warehouseData) {
    return {
        type: UPDATE_WAREHOUSE_DASHBOARD,
        updateSyncData: warehouseData.lastSynchronized
    };
}

function updateWarehouse(dashboard, lastSynchronized) {
    if ( dashboard == ''){
        return dashboard;
    }
    return { ...dashboard,
        lastSynchronized: lastSynchronized
    };
}

export function reconcileMatches(warehouse) {
    console.log('reconcileMatches action');
    return {
        type: RECONCILE_MATCHES,
        warehouse: warehouse
    };
}
