import React from 'react';
import WareHouse from './WareHouse';
import { Panel} from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { Button, ButtonToolbar,DropdownButton, MenuItem, SplitButton, Pagination} from 'react-bootstrap';
import TransferWindow from './TransferWindow';
import StockAdjustmentWindow from './StockAdjustmentWindow';
import SelectTransaction from './SelectTransaction';


var moment = require('moment');
import * as utilFunctions from '../services/util';

class ReconcileEntry extends React.Component {
  render() {
    return(
      <tr>
        <td className="rec-statement">{this.printStatement(this.props.item.statementEntity)}</td>
        <td className="rec-action">{this.printAction(this.props.item, this.props.i)}</td>
        <td className="rec-transaction">{this.printTransaction(this.props.item.stockTransactionItem)}</td>
      </tr>
    )
  }

  handleReconcile = (id1, id2, i) => {
    this.props.reconcile( id1, id2, i );
  };

  printTransaction = (t) => {
    if( t == null ){
      return '';
    }
    else{
      return(
        <div>
          <table className="rec-table">
            <tbody>
            <tr><td className="rec-contact" ><div className="head">Contact</div><div>{t.contactName}</div></td>
            <td className="rec-ref"><div className="head">Ref</div><div>{t.invoiceNumber}</div></td><td className="rec-date"><div className="head">Date</div>
            <div>{moment(t.submitDate).format('DD-MM-YYYY')}</div></td></tr>
            <tr><td className="rec-prod" colSpan="2"><div className="head">Prod</div><div>{t.prodCode}</div></td>
            <td className="rec-unit"><div className="head">Units</div><div>{t.quantity}</div></td></tr>
          </tbody>
          </table>
        </div>
      )
    }
  };

  printStatement = (t) => {
    if( t == null ){
      return '';
    }
    else{
      return(
        <div>
            <table className="rec-table">
              <tbody>
                <tr><td className="rec-contact" ><div className="head">Contact</div><div>{t.customerReference}</div></td>
                <td className="rec-ref"><div className="head">Ref</div><div>{t.reference1}</div></td><td className="rec-date"><div className="head">Date</div>
                <div>{moment(t.transactionDate).format('DD-MM-YYYY')}</div></td></tr>
                <tr><td className="rec-prod"><div className="head">Prod</div><div>{t.prodCode}</div></td>
                <td className="rec-ref"><div className="head">Type</div><div>{t.type}</div></td>
                <td className="rec-unit"><div className="head">Units</div><div>{t.quantity}</div></td></tr>
              </tbody>
          </table>
        </div>
      )
    }
  };

  printAction = (item, i) => {
    if( item.stockTransactionItem){
      return (
      //  <Button bsSize="small"
      //    onClick={this.handleReconcile.bind(this, item.statementEntity.id,item.stockTransactionItem.id,  i )}
      //  >OK</Button>
        <SplitButton bsStyle="success" title="OK" key={i} id={`split-button-basic-${i}`}
          onClick={this.handleReconcile.bind(this, item.statementEntity.id,item.stockTransactionItem.id,  i )}>
          <MenuItem eventKey="1"><TransferWindow warehouseId={this.props.warehouseId} {...this.props} i={i} transferProduct={item.statementEntity.prodCode} transferQuantity={item.statementEntity.quantity} transferDate={item.statementEntity.transactionDate} isReconcile='true' reference={item.statementEntity.reference1} batch={item.statementEntity.batch}/></MenuItem>
          <MenuItem eventKey="2"><StockAdjustmentWindow {...this.props} warehouseId={this.props.warehouseId} i={i} adjustmentProduct={item.statementEntity.prodCode} transferQuantity={item.statementEntity.quantity} transferDate={item.statementEntity.transactionDate} isReconcile='true' reference={item.statementEntity.reference1} batch={item.statementEntity.batch}/></MenuItem>
          <MenuItem eventKey="3"><SelectTransaction {...this.props} warehouseId={this.props.warehouseId} i={i} sourceref={'Date: ' + moment(item.statementEntity.transactionDate).format('DD-MM-YYYY') + ' C: ' + item.statementEntity.customerReference + ' P: ' + item.statementEntity.prodCode + ' Q: ' + item.statementEntity.quantity + ' Ref: ' + item.statementEntity.reference1} /></MenuItem>
        </SplitButton>
      )
    }else{
      // searchable
      return(
<ButtonToolbar>
     { this.printTransferButton(item, i)}
      { this.printActionButton(item, i)}

    </ButtonToolbar>



    );
    }
  };

  handleAdjustmentSubmit = (currentWarehouse, item) => {
    const wareHouseIndex = this.props.warehouses.units.findIndex((warehouses) => warehouses.wareHouse === currentWarehouse);

    console.log('prod: ' + JSON.stringify(item));
    if( item.statementEntity.prodCode === '' ){
      this.setState( {prodCodeError: 'error'});
    }else{
      this.props.adjustWarehouseReconcile(currentWarehouse,item.statementEntity.prodCode, item.statementEntity.quantity, utilFunctions.isWhitespaceEmptyString(item.statementEntity.transactionDate) ? null : moment(item.statementEntity.transactionDate, 'YYYY-MM-DD').format('YYYY-MM-DD'), item.statementEntity.reference1 + '-' + item.statementEntity.id, this.props.reconcilliation.currentPage, item.statementEntity.id,item.statementEntity.batch);
    }
  };


handleTransferSubmit = (currentWarehouse, receiveWareHouse, item  ) => {
  const wareHouseIndex = this.props.warehouses.units.findIndex((warehouses) => warehouses.wareHouse === currentWarehouse);
  const wareHouseReceiveIndex = this.props.warehouses.units.findIndex((warehouses) => warehouses.wareHouse === receiveWareHouse);
  console.log('wareHouseIndex: ' + wareHouseIndex);
  console.log('wareHouseReceiveIndex: ' + wareHouseReceiveIndex);
  console.log('warehouse to transfer to ' + receiveWareHouse);
  console.log('currentWarehouse:  ' + currentWarehouse);

  console.log('item.statementEntity.prodCode: ' + item.statementEntity.prodCode);
  if( item.statementEntity.prodCode === '' ){
    this.setState( {prodCodeError: 'error'});
  }else{
      this.props.transferWarehouseReconcile(currentWarehouse,wareHouseIndex,item.statementEntity.prodCode, item.statementEntity.quantity, receiveWareHouse, wareHouseReceiveIndex, utilFunctions.isWhitespaceEmptyString(item.statementEntity.transactionDate) ? null : moment(item.statementEntity.transactionDate, 'YYYY-MM-DD').format('YYYY-MM-DD'), item.statementEntity.reference1, this.props.reconcilliation.currentPage, item.statementEntity.id, item.statementEntity.batch);
  }
};
printActionButton = (item, i) =>{
  if( item.statementEntity.type === 'GO' || item.statementEntity.type === 'RELEASE' ){

  return(

      <DropdownButton bsStyle="info" title="Other Actions" id="dropdown-size-small">
        <MenuItem eventKey="1"><TransferWindow {...this.props} warehouseId={this.props.warehouseId}  i={i} transferProduct={item.statementEntity.prodCode} transferQuantity={item.statementEntity.quantity} transferDate={item.statementEntity.transactionDate} isReconcile='true' reference={item.statementEntity.reference1} batch={item.statementEntity.batch}ß/></MenuItem>
        <MenuItem eventKey="2"><StockAdjustmentWindow {...this.props} warehouseId={this.props.warehouseId} i={i} adjustmentProduct={item.statementEntity.prodCode} transferQuantity={item.statementEntity.quantity} transferDate={item.statementEntity.transactionDate} isReconcile='true' reference={item.statementEntity.reference1} batch={item.statementEntity.batch}/></MenuItem>
        <MenuItem eventKey="3"><SelectTransaction {...this.props} warehouseId={this.props.warehouseId} i={i} sourceref={'Date: ' + moment(
              item.statementEntity.transactionDate).format('DD-MM-YYYY') + ' C: ' + item.statementEntity.customerReference + ' P: ' + item.statementEntity.prodCode + ' Q: ' + item.statementEntity.quantity + ' Ref: ' + item.statementEntity.reference1} /></MenuItem>
        <MenuItem eventKey="4" onClick={this.handleReconcile.bind(this, item.statementEntity.id,-1,  i )}>Ignore</MenuItem>
      </DropdownButton>

  )
}else{
  return(
      // not outgoing
      <DropdownButton bsStyle="info" title="Actions" id="dropdown-size-small">
        <MenuItem eventKey="2"><StockAdjustmentWindow {...this.props} i={i} adjustmentProduct={item.statementEntity.prodCode} transferQuantity={item.statementEntity.quantity} transferDate={item.statementEntity.transactionDate} isReconcile='true' reference={item.statementEntity.reference1} batch={item.statementEntity.batch}/></MenuItem>
        <MenuItem eventKey="4" onClick={this.handleReconcile.bind(this, item.statementEntity.id,-1,  i )}>Ignore</MenuItem>
      </DropdownButton>

  )
}

};

  printTransferButton = (item, i) =>{
      if( item.statementEntity.type === 'GO' || item.statementEntity.type === 'RELEASE'){
        return(  <Button bsStyle="success"  title="TRF Garage" key={i} id={`split-button-basic-${i}`}
          onClick={this.handleTransferSubmit.bind(this,this.props.match.params.warehouseId, 'Garage',item )}>TRF Garage</Button>)
      }else if( item.statementEntity.type === 'GI' || item.statementEntity.type === 'Receipt' )
      return(  <Button bsStyle="success"  title="Add Product" key={i} id={`split-button-basic-${i}`}
          onClick={this.handleAdjustmentSubmit.bind(this,this.props.match.params.warehouseId,item )}>Add Product</Button>)
      else{
        return("");
      }
  };
}

class Reconcile extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)

  }

  state = {
    activePage: 1
  };

  handleSelect = (eventKey) => {
    this.setState({
      activePage: eventKey
    });
    this.props.fetchReconcilliationItem(this.props.match.params.warehouseId, eventKey);
  };

  componentDidMount() {
    if(  ! this.props.stockcount.isSynching && ! this.props.warehouses.isFetching && this.props.warehouses.units.length === 0 ){
      this.props.fetchWarehouses();
    }
    this.props.fetchReconcilliationItem(this.props.match.params.warehouseId, 1);
  }

  jsUcfirst = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  render() {
    const {hidden} = this.props;
    const results  = this.props.reconcilliation;
    const name = this.jsUcfirst(this.props.match.params.warehouseId) + " Statement Reconcilliation";
    if( hidden ){
      return (<div/>)
    }else{
      if(  ! this.props.stockcount.isSynching && ! this.props.warehouses.isFetching && this.props.warehouses.units.length === 0 ){
       return ( 
       <Panel className="dashboard" collapsible defaultExpanded header={name}>
        <div className="loader">Loading...</div>
       </Panel> 
       )
      }else if( results.isFetching ){
        return (
          <Panel className="dashboard" collapsible defaultExpanded header={name}>
           <div className="loader">Loading...</div>
          </Panel>
        )
      }else if( results.items == null || results.items == ""){
        var msg = 'Hurrah, no items to reconcile';
        if( results.error != null && results.error != "" ){
            msg = results.error;
        }
        return (
          <Panel className="dashboard" collapsible defaultExpanded header={name}>
           <div>{ msg }</div>
          </Panel>
        )
      }
      else{
        return(
          <Panel className="dashboard" collapsible defaultExpanded header={name}>
            <div>
              <Table className="reconcile-table" striped bordered  hover>
                <thead>
                <tr>
                  <th className="rec-statement">Statement</th>
                  <th className="rec-action">Action</th>
                  <th className="rec-transaction">Transaction</th>
                </tr>
                </thead>
                <tbody>
                {
                  results.items.map((item, i) => <ReconcileEntry warehouseId={this.props.match.params.warehouseId} {...this.props} reconcile={this.props.reconcile} transactions={this.props.transactions}
                                                           key={i} i={i} item={item} />)
                }
              </tbody>
              </Table>
          </div>
          <div>
            <Pagination
              bsSize="medium"
              items={results.totalPages}
              activePage={this.state.activePage}
              onSelect={this.handleSelect} />
          </div>
          </Panel>
        )
      }
    }
  }
}

export default Reconcile;
