import { batch } from "react-redux"

export const ADD_PRODUCT = 'ADD_PRODUCT'
export const VIEW_WAREHOUSES = 'VIEW_WAREHOUSES'
export const TRANSFER_PRODUCT = 'TRANSFER_PRODUCT'
export const TRANSFER_PRODUCT_FAILED = 'TRANSFER_PRODUCT_FAILED'
export const TRANSFER_PRODUCT_COMPLETED = 'TRANSFER_PRODUCT_COMPLETED'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const FETCHING_PRICELISTS = 'FETCHING_PRICELISTS'
export const FETCHED_PRICELISTS = 'FETCHED_PRICELISTS'
export const FAILED_FETCHED_PRICELISTS = 'FAILED_FETCHED_PRICELISTS'
export const FETCHING_PRODUCTS = 'FETCHING_PRODUCTS'
export const FETCHED_PRODUCTS = 'FETCHED_PRODUCTS'
export const FAILED_FETCHED_PRODUCTS = 'FAILED_FETCHED_PRODUCTS'
export const FETCHING_WAREHOUSES = 'FETCHING_WAREHOUSES'
export const FETCHED_WAREHOUSES = 'FETCHED_WAREHOUSES'
export const FAILED_FETCHED_WAREHOUSES = 'FAILED_FETCHED_WAREHOUSES'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export const ADD_PRICELIST = 'ADD_PRICELIST'
export const CREATE_PRICELIST = 'CREATE_PRICELIST'
export const DELETE_PRICELIST = 'DELETE_PRICELIST'
export const ADD_PRODUCT_TO_PRICELIST = 'ADD_PRODUCT_TO_PRICELIST'
export const UPDATE_PRODUCT_IN_PRICELIST = 'UPDATE_PRODUCT_IN_PRICELIST'
export const UPDATE_MAXCREDIT_IN_PRICELIST = 'UPDATE_MAXCREDIT_IN_PRICELIST'
export const UPDATE_PAYMENT_TERMS_IN_PRICELIST = 'UPDATE_PAYMENT_TERMS_IN_PRICELIST'
export const FAILED_PRICELIST_UPDATE = 'FAILED_PRICELIST_UPDATE'
export const REMOVE_PRODUCT_FROM_PRICELIST = 'REMOVE_PRODUCT_FROM_PRICELIST'
export const SYNC_WAREHOUSES = 'SYNC_WAREHOUSES'
export const SYNC_WAREHOUSES_COMPLETE = 'SYNC_WAREHOUSES_COMPLETE'
export const FAILED_SYNC_WAREHOUSES = 'FAILED_SYNC_WAREHOUSES'

export const FETCHING_WAREHOUSES_WITH_DATE = 'FETCHING_WAREHOUSES_WITH_DATE'

export const FETCHING_BILL_TRANSACTIONS= 'FETCHING_BILL_TRANSACTIONS'
export const FAILED_FETCHED_BILL_TRANSACTIONS = 'FAILED_FETCHED_BILL_TRANSACTIONS'
export const FETCHED_BILL_TRANSACTIONS = 'FETCHED_BILL_TRANSACTIONS'

export function fetchBillTransactionFromDate(fromDate) {
  console.log('fetchBillTransactionFromDate action');
  return {
      type: FETCHING_BILL_TRANSACTIONS,
      fromDate: fromDate,
      toDate: 'na',
      option: 'all'
  };
}

export function fetchedBillTranscations( billtransactions) {
  console.log('fetchedBillTransactions action');
  return {
      type: FETCHED_BILL_TRANSACTIONS,
      billtransactions: billtransactions,
      receivedAt: Date.now()
  };
}

export function fetchBillTransactionBetweenDates(fromDate, toDate) {
  console.log('fetchBillTransactionBetweenDates action');
  return {
      type: FETCHING_BILL_TRANSACTIONS,
      fromDate: fromDate,
      toDate: toDate,
      option: 'all'
  };
}
export function failedFetchBillTransactions( error) {
  return {
    type: FAILED_FETCHED_PRICELISTS,
    error: error,
    receivedAt: Date.now()
  }
}

export function fetchPricelists() {
  console.log('fetching pricelist action');
  return {
    type: FETCHING_PRICELISTS
  }
}

export function receivePricelists( pricelists) {
  return {
    type: FETCHED_PRICELISTS,
    pricelists: pricelists,
    receivedAt: Date.now()
  }
}
export function failedFetchPricelists( error) {
  return {
    type: FAILED_FETCHED_PRICELISTS,
    error: error,
    receivedAt: Date.now()
  }
}


export function failedPricelistUpdate( error) {
  return {
    type: FAILED_PRICELIST_UPDATE,
    error: error,
    receivedAt: Date.now()
  }
}


export function addProductToPricelist(priceListId, prodCode, price , accountCode, description, taxCode ) {
  return {
    type: ADD_PRODUCT_TO_PRICELIST,
    prodCode: prodCode,
    price: price,
    priceListId: priceListId,
    accountCode: accountCode,
    description: description,
    currency : 'GBP',
    taxCode : taxCode
  }
}

export function addPricelist(priceList ) {
  return {
    type: ADD_PRICELIST,
    priceList: priceList
  }
}

export function createPricelist(priceList ) {
  return {
    type: CREATE_PRICELIST,
    priceList: priceList
  }
}

export function deletePricelist(priceListId) {
  return {
    type: DELETE_PRICELIST,
    priceListId: priceListId
  }
}

export function setMaxCreditInPricelist(maxCredit) {
  return {
    type: UPDATE_MAXCREDIT_IN_PRICELIST,
    maxCredit: maxCredit
  }
}

export function setPaymentTermsInPricelist(paymentTerms) {
  return {
    type: UPDATE_PAYMENT_TERMS_IN_PRICELIST,
    paymentTerms: paymentTerms
  }
}

export function removeProductFromPricelist(priceListId, i ) {
  return {
    type: REMOVE_PRODUCT_FROM_PRICELIST,
    i: i,
    priceListId: priceListId
  }
}

export function updateProductInPricelist(i, prodCode, price , accountCode, description, taxCode ) {
    return {
      type: UPDATE_PRODUCT_IN_PRICELIST,
      i : i,
      prodCode: prodCode,
      price: price,
      accountCode: accountCode,
      description: description,
      currency : 'GBP',
      taxCode : taxCode
    }
  }

export function addProduct(wareHouseName,wareHouseIndex, prodCode, quantity, descr, batch  ) {
  return {
    type: ADD_PRODUCT,
      prodCode: prodCode,
      quantity: quantity,
      descr: descr,
      batch: batch, 
      wareHouseName: wareHouseName,
      wareHouseIndex: wareHouseIndex
  }
}

export function viewWarehouses( ) {
  return {
    type: VIEW_WAREHOUSES
  }
}
export function transferWarehouseReconcile(wareHouseName,wareHouseIndex, prodCode, quantity, receiveWareHouse , receiveWareHouseIndex, submitDate, reference, page, reconcileId, batch) {
  console.log('transferWarehouseReconcile called');
  return {
    type: TRANSFER_PRODUCT,
    fetchReconcilliationItemPage:page,
    reconcileId: reconcileId,
    stockTransfer : {
      prodCode: prodCode,
      quantity: parseInt(quantity),
      batch: batch, 
      fromWarehouse: wareHouseName,
      toWarehouse: receiveWareHouse,
      reportDate: submitDate,
      reference: reference
    }
  }
  }

export function transferWarehouse(wareHouseName,wareHouseIndex, prodCode, quantity, receiveWareHouse , receiveWareHouseIndex, submitDate, reference, batch) {
  console.log('transferWarehouse called');
  return {
    type: TRANSFER_PRODUCT,
    stockTransfer : {
      prodCode: prodCode,
      batch: batch, 
      quantity: parseInt(quantity),
      fromWarehouse: wareHouseName,
      toWarehouse: receiveWareHouse,
      reportDate: submitDate,
      reference: reference
    },
    fetchReconcilliationItemPage:0

  }
}

export function updateProductQuantity(wareHouseName,wareHouseIndex, i,quantity) {
  return {
    type: UPDATE_PRODUCT,
    quantity: quantity,
    wareHouseName: wareHouseName,
    wareHouseIndex: wareHouseIndex,
    i
  }
}

export function fetchProducts() {
  console.log('fetching product action');
  return {
    type: FETCHING_PRODUCTS
  }
}

export function receiveProducts( products) {
  return {
    type: FETCHED_PRODUCTS,
    products: products,
    receivedAt: Date.now()
  }
}
export function failedFetchProducts( error) {
  return {
    type: FAILED_FETCHED_PRODUCTS,
    error: error,
    receivedAt: Date.now()
  }
}



export function syncWarehouses(fromDate) {
  console.log('synch warehouses action');
  return {
    type: SYNC_WAREHOUSES,
    fromDate: fromDate
  }
}

export function syncWarehousesComplete() {
  console.log('syncWarehousesComplete ');

  return {
    type: SYNC_WAREHOUSES_COMPLETE,
    receivedAt: Date.now()
  }
}
export function failedSyncWarehouses( error) {
  console.log('failedSyncWarehouses ' + error) ;

  return {
    type: FAILED_SYNC_WAREHOUSES,
    error: error,
    receivedAt: Date.now()
  }
}

export function fetchWarehouses() {
  console.log('fetching warehouses action');
  return {
    type: FETCHING_WAREHOUSES
  }
}

export function fetchWarehousesToDate(toDate) {
  console.log('fetching warehouses action: ' + toDate );
  return {
    type: FETCHING_WAREHOUSES_WITH_DATE,
    toDate: toDate
  }
}

export function stockCountWarehouses() {
  console.log('fetching warehouses action');
  return {
    type: FETCHING_WAREHOUSES
  }
}

export function receiveWarehouses( data) {
  console.log('receiveWarehousesaction: ' + data+ JSON.stringify(data) );
  return {
    type: FETCHED_WAREHOUSES,
    warehouses: data.warehouses,
    lastSynchronized: data.lastSynchronized,
    receivedAt: Date.now()
  }
}
export function failedFetchWarehouses( error) {
  return {
    type: FAILED_FETCHED_WAREHOUSES,
    error: error,
    receivedAt: Date.now()
  }
}

export function completedTransferWarehouses( ) {
  return {
    type: TRANSFER_PRODUCT_COMPLETED
  }
}
export function failedTransferWarehouses( error) {
  return {
    type: TRANSFER_PRODUCT_FAILED,
    error: error
  }
}


export function requestLogin(user , password) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    name: user,
    password: password,
    message: ''
  }
}

export function loginSuccess(token) {
  console.log( token);

  return {
    type: LOGIN_SUCCESS,
    token_id: token.token_id,
    token_expiry: token.token_expiry,
    message: ''
  }
}

export function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    message: JSON.stringify(message)
  }
}

// Three possible states for our logout process as well.
// Since we are using JWTs, we just need to remove the token
// from localStorage. These actions are more useful if we
// were calling the API to log the user out


export function requestLogout() {
  return {
    type: LOGOUT_REQUEST
  }
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS
  }
}
