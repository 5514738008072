import React, { useState, useEffect } from "react";

import { useFetch } from '../services/useFetch';
import { Panel} from 'react-bootstrap';
import {PackingGraphs} from "./PackingGraphs";
import { serverURL } from "../services/api";
import { getGraphSeries} from "../services/util"; 


export default function ShippingVsSalesMoM(id) {
  const [shipunits3] = useFetch( serverURL + "bill/transportvssales/mom/2022-01-01/2022-12-31");
  const [shipunits4] = useFetch( serverURL + "bill/transportvssales/mom/2023-01-01/2023-12-31");
  const [shipunits5] = useFetch( serverURL + "bill/transportvssales/mom/2024-01-01/2024-12-31");

  if( !shipunits3 || !shipunits4 || !shipunits5){
    return (
      <Panel className="foulger" collapsible defaultExpanded header="Transport Cost vs Sales Value from Foulger and FDC">
       <div className="loader">Loading...</div>
      </Panel>
    )
  }
  else{

    const costSeries3 = getGraphSeries(shipunits3.monthList); 
    const costSeries4 = getGraphSeries(shipunits4.monthList); 
    const costSeries5 = getGraphSeries(shipunits5.monthList); 

  return (
    <Panel className="foulger" collapsible defaultExpanded header={shipunits3.name}>
    <div>{PackingGraphs(costSeries3, costSeries4, costSeries5)}</div>
    <div>Shipping cost as % of sales 2022: {shipunits3.totalRatio} </div>
    <div>Shipping cost as % of sales 2023: {shipunits4.totalRatio} </div>
    <div>Shipping cost as % of sales 2024: {shipunits5.totalRatio} </div>

    </Panel>

  );
}
}