import React, { useState, useEffect } from "react";

import { useFetch } from '../services/useFetch';
import { Panel} from 'react-bootstrap';
import { serverURL } from "../services/api";
import { getGraphSeries} from "../services/util"; 
import {PackingGraphs2} from "./PackingGraphs";



export default function PackingCostsMoM(id) {
  const [data] = useFetch(serverURL + "bill/packing/mom/2023-01-01/2023-12-31");
  const [data2] = useFetch(serverURL + "bill/packing/mom/2024-01-01/2024-12-31");
  const [data3] = useFetch(serverURL + "bill/packing/mom/FDC/2023-01-01/2023-12-31");
  const [data4] = useFetch(serverURL + "bill/packing/mom/FDC/2024-01-01/2024-12-31");

  //const [data3] = useFetch( {serverURL} + "bill/packing/wow/2022-01-09/2022-09-30");
 
  if( !data || !data2 || !data3 || !data4){
    return (
      <Panel className="foulger" collapsible defaultExpanded header="Packing cost per order MoM">
       <div className="loader">Loading...</div>
      </Panel>
    )
  }
  else{
    const costSeries = getGraphSeries(data.monthList); 
    const costSeries2 = getGraphSeries(data2.monthList); 
    const costSeries3 = getGraphSeries(data3.monthList); 
    const costSeries4 = getGraphSeries(data4.monthList); 



  return (
    <Panel className="foulger" collapsible defaultExpanded header="Packing Cost per Order">
    <div><b>FDC</b></div>
    <div>{PackingGraphs2('FDC', costSeries3, costSeries4)}</div>
    <div>FDC Cost per order 2023: {data3.totalRatio} </div>
    <div>FDC Cost per order 2024: {data4.totalRatio} </div>
    <div><br/><br/><b>Foulger</b></div>
    <div>{PackingGraphs2('FOULGER', costSeries, costSeries2)}</div>
    <div>Foulger Cost per order 2023: {data.totalRatio} </div>
    <div>Foulger Cost per order 2024: {data2.totalRatio} </div>


   
    </Panel>


  );
}
}