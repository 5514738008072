import React from "react";

import { useFetch } from '../services/useFetch';
import { Panel} from 'react-bootstrap';
import {PackingGraphs} from "./PackingGraphs";
import { serverURL } from "../services/api";
import { getGraphSeries} from "../services/util"; 



export default function HandlingVsUnitsShippedBrakesMoM(id) {
  const [shipunits1] = useFetch(serverURL + "bill/brakesvsunits/mom/2022-01-01/2022-12-31");
  const [shipunits2] = useFetch(serverURL + "bill/brakesvsunits/mom/2023-01-01/2023-12-31");
  const [shipunits3] = useFetch(serverURL + "bill/brakesvsunits/mom/2024-01-01/2024-12-31");


  if( !shipunits1 || !shipunits2 || !shipunits3){
    return (
      <Panel className="foulger" collapsible defaultExpanded header="Handling Cost pr Unit From Brakes">
       <div className="loader">Loading...</div>
      </Panel>
    )
  }
  else{

    const costSeries3 = getGraphSeries(shipunits1.monthList); 
    const costSeries4 = getGraphSeries(shipunits2.monthList); 
    const costSeries5 = getGraphSeries(shipunits3.monthList); 

  return (
    <Panel className="foulger" collapsible defaultExpanded header={shipunits1.name}>
    <div>{PackingGraphs(costSeries3, costSeries4, costSeries5)}</div>
    <div>Handling Cost per case 2022: {shipunits1.totalRatio} </div>
    <div>Handling and shipping Cost per case 2023: {shipunits2.totalRatio} </div>
    <div>Handling and shipping Cost per case 2024: {shipunits3.totalRatio} </div>

    </Panel>

  );
}
}