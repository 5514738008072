import {Router} from 'react-router';
import {
  UPDATE_PRODUCT,
  ADD_PRODUCT,
  TRANSFER_PRODUCT,
  FETCHING_WAREHOUSES,
  FETCHED_WAREHOUSES,
  VIEW_WAREHOUSES,
  FAILED_FETCHED_WAREHOUSES,
  TRANSFER_PRODUCT_COMPLETED,
  TRANSFER_PRODUCT_FAILED,
  SYNC_WAREHOUSES,
  SYNC_WAREHOUSES_COMPLETE,
  FAILED_SYNC_WAREHOUSES,
  FETCHING_WAREHOUSES_WITH_DATE
} from '../actions/actionCreators';

function updateWareHouse(state = [], action) {
  return [
    ...state.slice(0,action.wareHouseIndex),
    state[action.wareHouseIndex] = {
      wareHouseName: action.wareHouseName,
      productsSummary : updateProduct(state[action.wareHouseIndex].productsSummary, action)
    },
    // after the deleted one, to the end
    ...state.slice(action.wareHouseIndex + 1),

  ]
}

function updateProduct(state = [], action) {
  switch(action.type){
    case ADD_PRODUCT:
      console.log('add product called');

      // return the new state with the new comment
      return [...state,{
        prodCode: action.prodCode,
        quantity: action.quantity,
        warehouse: action.wareHouseName,
        descr: action.descr
      }];
    case UPDATE_PRODUCT:
      console.log('update product called');
      // from the start to the one we want to delete
      return [
      ...state.slice(0,action.i),
      // update the entry
      state[action.i] = {
        prodCode: state[action.i].prodCode,
        quantity: action.quantity,
        warehouse: state[action.i].wareHouse,
        descr: state[action.i].descr
      },
      // after the deleted one, to the end
      ...state.slice(action.i + 1)
      ];

    default:
      return state;
  }
  return state;
}

function warehouses(state = [], action) {
  switch (action.type) {

    case VIEW_WAREHOUSES:
        console.log('view warehouses')
        return   {
            ...state,
            isFetching: true,
            error: state.error,
            lastUpdated: state.lastUpdated,
            units: state.units
          }
  case FETCHING_WAREHOUSES:
  case FETCHING_WAREHOUSES_WITH_DATE:
      console.log('fetching warehouses')
      return { ...state, isFetching: true }
  case FETCHED_WAREHOUSES:
      console.log('fetched warehouses')

      return { ...state,
        isFetching: false,
        units: action.warehouses,
        lastSynchronized: action.lastSynchronized,
        error: '',
        lastUpdated: action.receivedAt
      }
    case FAILED_FETCHED_WAREHOUSES:
          console.log('failed fetched warehouses')

          return { ...state,
            isFetching: false,
            error: action.error,
            lastUpdated: action.receivedAt
          }
    case SYNC_WAREHOUSES:
        console.log('sync warehouses')
        return { ...state, isSynching: true }
    case SYNC_WAREHOUSES_COMPLETE:
        console.log('sync warehouses complete')

        return { ...state,
          isSynching: false,
          error: '',
          lastUpdated: action.receivedAt
        }
    case FAILED_SYNC_WAREHOUSES:
          console.log('failed sync warehouses')

          return { ...state,
            isSynching: false,
            error: action.error,
            lastUpdated: action.receivedAt
          }
    case TRANSFER_PRODUCT_COMPLETED:
            console.log('transfer  warehouses completed')
            return state;
    case TRANSFER_PRODUCT_FAILED:
              console.log('failed transfer warehouses')

              return { ...state,
                isFetching: false,
                error: action.error,
                lastUpdated: action.receivedAt
              }
  case TRANSFER_PRODUCT:
      console.log('transfer product called');
      return state;
  case ADD_PRODUCT:
    if(typeof action.productToAdd.wareHouseName !== 'undefined' &&
    typeof action.productToAdd.wareHouseIndex !== 'undefined') {
      return {
        ...state,
          isFetching: state.isFetching,
          error: state.error,
          lastUpdated: state.lastUpdated,
          units: updateWareHouse(state.productToAdd.units, action)
        }
    }else{
      console.log('error unexpected path');
      console.log('action.wareHouseName: ' + action.wareHouseName);
      console.log('action.wareHouseIndex: ' + action.wareHouseIndex);

    }
  return state;

  case UPDATE_PRODUCT:

      if(typeof action.wareHouseName !== 'undefined' &&
          typeof action.wareHouseIndex !== 'undefined') {
            return {
              ...state,
                isFetching: state.isFetching,
                error: state.error,
                lastUpdated: state.lastUpdated,
                units: updateWareHouse(state.units, action)
              }
        }else{
            console.log('error unexpected path');
          console.log('action.wareHouseName: ' + action.wareHouseName);
          console.log('action.wareHouseIndex: ' + action.wareHouseIndex);

        }

        default:

          return state;
      }
}





export default warehouses;
