import {
    ADD_PRODUCT_TO_INVOICE,
    REMOVE_PRODUCT_FROM_INVOICE,
    UPDATE_PRODUCT_IN_INVOICE,
    FETCH_INVOICE_OK,
    FAILED_INVOICE_ACTION,
    FETCH_INVOICE,
    GENERATE_INVOICE_TEMPLATE,
    UPDATE_DUE_DATE,
    UPDATE_SUBMIT_DATE,
    SUMBIT_INVOICE_SUBMITTED_OK,
    UPDATE_WAREHOUSE
  } from '../actions/invoiceActions';

import * as utilFunctions from '../services/util';


function editableInvoice(state = [], action) {
    switch(action.type){
    case GENERATE_INVOICE_TEMPLATE:
        console.log('generate invoice template:' + action.customerName)
        return { ...state,
            invoice: '',
            nonPriceListItems: [],
            priceList:'',
            error:'',
            isFetching: true };
    case FETCH_INVOICE:
        console.log('fetching invoice:' + action.invoiceId)
        return { ...state,
            invoice: '',
            nonPriceListItems: [],
            priceList:'',
            defaultWarehouse: '',
            courierTemplate: '',
            customerTemplate: '',
            error:'',
            notes: [],
            isFetching: true }
    case FETCH_INVOICE_OK:
        console.log('fetched invoice ok')

        return { ...state,
            isFetching: false,
            invoice: action.invoice,
            nonPriceListItems: action.nonPriceListItems,
            priceList: action.priceList,
            warehouses: action.warehouses,
            defaultWarehouse: action.defaultWarehouse,
            courierTemplate: action.courierTemplate,
            customerTemplate: action.customerTemplate,
            notes: action.notes,
            error: '',
            lastUpdated: action.receivedAt
        }
    case FAILED_INVOICE_ACTION:
        console.log('failed fetch invoice')
        return { ...state,
            isFetching: false,
            error: action.error,
            defaultWarehouse: '',
            courierTemplate: '',
            customerTemplate: '',
            notes: [],
            lastUpdated: action.receivedAt
        }

    case UPDATE_DUE_DATE:
    case UPDATE_SUBMIT_DATE:
    case ADD_PRODUCT_TO_INVOICE:
    case UPDATE_PRODUCT_IN_INVOICE:
    case REMOVE_PRODUCT_FROM_INVOICE:
    case UPDATE_WAREHOUSE:
        return { ...state,
            invoice: updateInvoice(state.invoice, action)
        }
    case SUMBIT_INVOICE_SUBMITTED_OK:
        return { ...state,
            invoice: action.updatedinvoice,
            isFetching: false,
            lastUpdated: action.receivedAt
        }
    default:
        return state;
    }
}


function updateInvoice(state = [], action) {
    switch(action.type){
    case UPDATE_DUE_DATE:
        return{ ...state,
            dueDate: action.date
        }
    case UPDATE_SUBMIT_DATE:
        return{ ...state,
            dateTimeOfSubmission: action.date
        }
    case UPDATE_WAREHOUSE:
        return{ ...state,
            wareHouse: action.warehouse
        }
    case ADD_PRODUCT_TO_INVOICE:
    case UPDATE_PRODUCT_IN_INVOICE:
    case REMOVE_PRODUCT_FROM_INVOICE:
        return { ...state,
            invoiceLineItems: updateProducts(state.invoiceLineItems, action)
        }
    default:
        return state
    }
}

function updateProducts(state = [], action) {
    switch(action.type){
    case ADD_PRODUCT_TO_INVOICE:
        console.log('action add product to invoice:' + action.prodId)

        // return the new state with the new pricelist
        return [...state,{
            prodId: action.prodId,
            salesPrice: action.price,
            quantity: action.quantity,
            accountCode: action.accountCode,
            descr: action.description,
            currency : action.currency,
            discount: action.discount,
            taxCode : action.taxCode,
            lineAmount: action.lineAmount
        }];

    case UPDATE_PRODUCT_IN_INVOICE:
        return [
            // from the start to the one we want to delete
            ...state.slice(0,action.i),
            // after the deleted one, to the end
            state[action.i] = {
                prodId: action.prodId,
                salesPrice: action.price,
                quantity: action.quantity,
                accountCode: action.accountCode,
                descr: action.description,
                currency : action.currency,
                discount: action.discount, 
                taxCode : action.taxCode,
                lineAmount: action.lineAmount
            },
            ...state.slice(action.i + 1)
        ]
    case REMOVE_PRODUCT_FROM_INVOICE:
        console.log('action remove produc from invoice:' + action.i)

        // we need to return the new state without the deleted comment
        return [
            // from the start to the one we want to delete
            ...state.slice(0,action.i),
            // after the deleted one, to the end
            ...state.slice(action.i + 1)
        ];
    default:
        return state;
    }
}


export default editableInvoice;
