const ReactDataGrid = require('react-data-grid');
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

const React = require('react');
var moment = require('moment');

class SelectTransaction extends React.Component {
  constructor(props) {
    super(props);

    this._columns = [
      {
        key: 'date',
        name: 'Date',
        width: 100
      },
      {
        key: 'customer',
        name: 'Customer',
        width: 250
      },
      {
        key: 'pcode',
        name: 'Code',
        width: 60
      },
      {
        key: 'reference',
        name: 'Reference',
        width: 280
      },
      {
        key: 'quantity',
        name: 'Quantity'
      }
    ];
    this.state = {
      selectedIndexes: [],
      showModal: false,
      rows: this.createRows(this.props.transactions.lists)
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
  //  console.log('componentWillReceiveProps: '  + nextProps.transactions.lists.length );

    if (this.props.transactions !== nextProps.transactions) {
  //    console.log('componentWillReceiveProps updating rows: '  + nextProps.transactions.lists.length );

      this.setState({rows: this.createRows(nextProps.transactions.lists)})
    }
  }

  createRows = (list) => {
   let rows = [];
   for (let i = 0; i < list.length; i++) {
     rows.push({
       customer:list[i].contactName,
       date: moment(list[i].submitDate, moment.ISO_8601).format('DD-MM-YYYY'),
       pcode: list[i].prodCode,
       reference: list[i].invoiceNumber,
       quantity: list[i].quantity
     });
   }
   return rows;
 };

  rowGetter = (i) => {
    return this.state.rows[i];
  };

  onRowsSelected = (rows) => {
    this.setState({selectedIndexes: this.state.selectedIndexes.concat(rows.map(r => r.rowIdx))});
    console.log('selected index: ' + rows[0].rowIdx + ' i; ' + this.props.i);
    console.log(this.state.rows[rows[0].rowIdx]);
    this.props.updateReconcileTransaction(this.props.transactions.lists[rows[0].rowIdx], this.props.i)
    // close the window

    // remove the transaction
    this.props.removeTransactionLocally(rows[0].rowIdx);
    this.close();


  };

onRowsDeselected = (rows) => {
  let rowIndexes = rows.map(r => r.rowIdx);
  this.setState({selectedIndexes: this.state.selectedIndexes.filter(i => rowIndexes.indexOf(i) === -1 )});
};


 close = () => {
    this.setState({ showModal: false });
  }

 open = () => {
       this.setState({ showModal: true });
       this.setState({selectedIndexes: []})
  }

  render() {
    const { sourceref } = this.props;

    if ( this.state.rows.length === 0 ){
        return (
          <div>
            { <div onClick={this.open}>match</div>}
          <Modal show={this.state.showModal} onHide={this.close} bsSize="large">
            <Modal.Header>
              <Modal.Title>Match: {sourceref}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="loader">Loading...</div>
             </Modal.Body>
             <Modal.Footer>
               </Modal.Footer>
           </Modal>
           </div>
        )
    } else{
      const rowText = this.state.selectedIndexes.length === 1 ? 'row' : 'rows';

      return  (
        <div>
          { <div onClick={this.open}>match</div>}
        <Modal show={this.state.showModal} onHide={this.close} bsSize="large">
          <Modal.Header>
            <Modal.Title>Match: {sourceref}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

        <div>
          <ReactDataGrid
            rowKey="id"
            columns={this._columns}
            rowGetter={this.rowGetter}
            rowsCount={this.state.rows.length}
            minHeight={300}
            rowSelection={{
              showCheckbox: true,
              enableShiftSelect:false,
              onRowsSelected: this.onRowsSelected,
              onRowsDeselected: this.onRowsDeselected,
              selectBy: {
                indexes: this.state.selectedIndexes
              }
            }} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button  onClick={this.close}>Cancel</Button>
        </Modal.Footer>
    </Modal>
  </div>
    );
    }
  }
}



export default SelectTransaction;
