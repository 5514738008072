import React from 'react';
import { Table } from 'react-bootstrap';
import { Panel } from 'react-bootstrap';
import { useFetch } from '../services/useFetch';
import { serverURL } from "../services/api";
import {PackingGraphs4} from "./PackingGraphs";
import { getGraphSeries} from "../services/util";
var moment = require('moment');

import * as utilFunctions from '../services/util';

function RenderProduct(data, i) {

  return (<tr className="details-row" key={i}>
    <td className="inv-head-med">{data.product.prodCode}</td>
    <td className="inv-head-med">{data.soldValue}</td>
    <td className="inv-head-med">{data.productCost}</td>
    <td className="inv-head-med">{data.unitsSold}</td>
    <td className="inv-head-med">{utilFunctions.round2money(data.precentageOfSales * 100)}%</td>
    <td className="inv-head-med">{utilFunctions.round2money(data.productMargin * 100)}%</td>
  </tr>)
};

export default function TotalSales(props) {
  var now = moment();
  const months = props.months; 

  //const [profit] = useFetch(serverURL + "bill/whsales/" + props.contactName + "/" + now.subtract(months, 'months').format("YYYY-MM-DD"));

  const id = props.id;
  const [data2] = useFetch(serverURL + "bill/warehousesales/" + id + "/2020-01-01/2020-12-31");
  const [data3] = useFetch(serverURL + "bill/warehousesales/" + id + "/2021-01-01/2021-12-31");
  const [data4] = useFetch(serverURL + "bill/warehousesales/" + id + "/2022-01-01/2022-12-31");
  const [data5] = useFetch(serverURL + "bill/warehousesales/" + id + "/2023-01-01/2023-12-31");
  const [data6] = useFetch(serverURL + "bill/warehousesales/" + id+ "/2024-01-01/2024-12-31");

  if( !data4 || !data3 || !data2 || !data5 || !data6 ){
    return (
      <Panel className="foulger" collapsible defaultExpanded header={'Total Sales'}>
       <div className="loader">Loading...</div>
      </Panel>
    )
  }
  else{
    const costSeries5 = getGraphSeries(data6.monthList); 
    const costSeries4 = getGraphSeries(data5.monthList); 
    const costSeries1 = getGraphSeries(data2.monthList); 
    const costSeries2 = getGraphSeries(data3.monthList); 
    const costSeries3 = getGraphSeries(data4.monthList); 


  return (
    <Panel className="foulger" collapsible defaultExpanded header={data6.name}>
<div>{PackingGraphs4(costSeries1, costSeries2, costSeries3, costSeries4, costSeries5)}</div>

<div>Sales 2020: {data2.totalRatio} </div>
<div>Sales 2021: {data3.totalRatio} </div>
        <div>Sales 2022: {data4.totalRatio} </div>
        <div>Sales 2023: {data5.totalRatio} </div>
        <div>Sales 2024: {data6.totalRatio} </div>

    </Panel>
  )
  }
/*
  console.log(JSON.stringify(profit));
  if (!profit) {
    return (
      <Panel className="foulger" collapsible defaultExpanded header={props.title}>
        <div className="loader">Loading...</div>
      </Panel>
    )
  }
  else {
    let cost = 'not used'; 
    let salesValue ='not used'; 
    if( profit.productData != null && profit.productData.length > 0  ) {
      cost = profit.productData[0].unitProductCost;
      salesValue = profit.productData[0].unitProductSalesValue;
    }
    return (
      <Panel header={"Sales and Profit Last " + months + " Months"}>
         <div>{"Product Sales Value: " + salesValue}</div>
        <div>{"Product Cost: " + cost}</div>
        <div>{"Storage Cost " + profit.storageCostUnit}</div>
        <div>{"Shipping Cost " + profit.shippingCostUnit}</div>
        <Table className="inv-table profit-table" bordered condensed hover>
          <thead>
            <tr className="profit-head">
              <th className="inv-head-med">Product</th>
              <th className="inv-head-med">Sales</th>
              <th className="inv-head-med">Cost</th>
              <th className="inv-head-med">Quantity</th>
              <th className="inv-head-med">Precentage</th>
              <th className="inv-head-med">Margin</th>
            </tr>
          </thead>
          <tbody>
            <tr className="summary-row">
              <td className="inv-head-med">Totals</td>
              <td className="inv-head-med">{profit.totalSales}</td>
              <td className="inv-head-med">{profit.totalCost}</td>
              <td className="inv-head-med">{profit.totalUnits}</td>
              <td className="inv-head-med">{"N/A"}</td>
              <td className="inv-head-med">{utilFunctions.round2money(profit.productMargin * 100)}%</td>
            </tr>
            {profit.productData.map(RenderProduct)}
          </tbody>
        </Table>
      </Panel>
    )

  }
      */
}
