import React from 'react';
import ReactDOM from 'react-dom';

import { Form } from 'react-bootstrap';

import { Modal } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';
import { FormControl} from 'react-bootstrap';
import { ControlLabel} from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { DropdownButton } from 'react-bootstrap';
import { MenuItem } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';


class EmailInvoice extends React.Component {
  state = { showModal: false,
           to: '',
           cc: '',
           subject:'',
           body:''
  };

  errorCode = () => {
    let str = '';
    if( this.state.prodCodeError === 'error'){
      str = validationState="error";
    }
    return( str );
  };

  handleSend = (invoiceNumber, isCourier, e) => {
    console.log('isCourier: ' + isCourier);
   this.props.sendInvoice(invoiceNumber,this.state.subject, this.state.to, this.state.cc, this.state.body , isCourier);

   this.setState({showModal: false});

 };

  handleSubject = (e) => {
    this.setState({subject: e.target.value});
  };

  handleTo = (e) => {
    this.setState({to: e.target.value});
  };

  handleCC = (e) => {
    this.setState({cc: e.target.value});
  };

  handleBody = (e) => {
    this.setState({body: e.target.value});
  };

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  componentDidMount() {
    const { currentTemplate, title, emailTo} = this.props;
    this.setState({ to: emailTo });
    this.setState({ subject: title });
    this.setState({ body: currentTemplate });
  }

  render() {
    const { buttonText, invoiceNumber, isCourier} = this.props;

    return (
      <div>
        <Button
          bsSize="small"
          onClick={this.open}
        >{buttonText}</Button>

          <Modal show={this.state.showModal} onHide={this.close}>
            <Modal.Header>
              <Modal.Title>{buttonText + ' invoice: ' + invoiceNumber}</Modal.Title>
            </Modal.Header>
            <Form>
            <Modal.Body>
            <FormGroup controlId="title">
               <ControlLabel>subject</ControlLabel>
               <FormControl type="text" value={this.state.subject} onChange={this.handleSubject} />
             </FormGroup>
            <FormGroup controlId="to">
               <ControlLabel>to</ControlLabel>
               <FormControl     type="email"
                  value={this.state.to} onChange={this.handleTo}/>
             </FormGroup>
             <FormGroup controlId="cc">
                <ControlLabel>cc</ControlLabel>
                <FormControl type="email"  value={this.state.cc} onChange={this.handleCC}/>
              </FormGroup>
              <FormGroup controlId="body">
                 <ControlLabel>message</ControlLabel>
                 <FormControl componentClass="textarea" value={this.state.body} onChange={this.handleBody} />
               </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleSend.bind(this, invoiceNumber, isCourier)}>Send</Button>
              <Button  onClick={this.close}>Cancel</Button>
            </Modal.Footer>
            </Form>
          </Modal>
          </div>
        )
        }
}

export default EmailInvoice;
